import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInDays } from "date-fns";
import { useFileUpload } from "helpers/useFileUpload";
import React from "react";
import DatePicker from "react-datepicker";

import { Button } from "./button";
import FileUpload from "./FileUpload";
import { TextArea } from "./textarea";

interface PayrollInquireProps {
  selectedEmployee: Employee | undefined;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
  endDate: Date | null;
  setPayPeriodEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  tempAttachment: Upload | undefined;
  setTempAttachment: React.Dispatch<React.SetStateAction<Upload | undefined>>;
}

const PayrollInquire: React.FC<PayrollInquireProps> = ({
  selectedEmployee,
  body,
  setBody,
  endDate,
  setPayPeriodEndDate,
  tempAttachment,
  setTempAttachment,
}) => {
  const [toggleDatePickerIsOpen, setToggleDatePickerIsOpen] = React.useState(false);

  const { fileAsync, isUploading, uploadResult, uploadFileResult } = useFileUpload(selectedEmployee, setTempAttachment);

  const isEveryOtherSunday = (date: Date) => {
    const referenceSunday = new Date(2024, 0, 14); // A known Sunday to start the fortnightly cycle
    const daysDifference = differenceInDays(date, referenceSunday);
    return daysDifference % 14 === 0;
  };

  return (
    <>
      <div className="md:w-1/2 mb-3 md:mb-0 mr-3 mt-3">
        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
          Pay Period ending on:
        </label>
        <div className="flex">
          <DatePicker
            className="appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded-l py-3 px-4 mb-3 focus:outline-none"
            selected={endDate}
            onChange={(date) => setPayPeriodEndDate(date)}
            filterDate={isEveryOtherSunday}
            dateFormat="dd/MM/yyyy"
            open={toggleDatePickerIsOpen}
            onClickOutside={() => setToggleDatePickerIsOpen(false)}
            onSelect={() => setToggleDatePickerIsOpen(false)}
            readOnly
            disabledKeyboardNavigation
            placeholderText="dd/mm/yyyy"
          />
          <Button
            onClick={() => setToggleDatePickerIsOpen(!toggleDatePickerIsOpen)}
            colour="accent"
            className="mb-3 rounded-r rounded-l-none py-2 px-4"
          >
            <FontAwesomeIcon icon="calendar-alt" />
          </Button>
        </div>
      </div>

      <div className="w-full">
        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold my-2">Payslip Inquiry</label>
        <TextArea
          name="Body"
          placeholder="Please describe the details of your inquiry regarding your payslip."
          value={body}
          onChange={(e) => setBody(e.target.value)}
          maxLength={500}
          autoFocus
        />
      </div>

      <div className="md:flex mt-3">
        <div className="md:w-full">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Attachments</label>
          <FileUpload
            uploadFile={fileAsync}
            attachment={tempAttachment}
            removeFile={() => setTempAttachment(undefined)}
          />
          {isUploading && "Uploading..."}
          {uploadResult.error && "description" in uploadResult.error && (
            <div className="text-danger">{uploadResult.error.description}</div>
          )}
          {uploadFileResult.error && "description" in uploadFileResult.error && (
            <div className="text-danger">{uploadFileResult.error.description}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default PayrollInquire;
