import classNames from "classnames";
import React from "react";

import { RPCaret } from "./RPCaret";

type Props = {
  showLabel?: React.ReactNode;
  children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<"select">;

export function SelectDropDown(props: Props) {
  let { children, showLabel, className, ...selectProps } = props;

  return (
    <div>
      {showLabel && (
        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">{showLabel}</label>
      )}
      <div className="relative">
        <select
          {...selectProps}
          className={classNames(
            className,
            "block appearance-none w-full bg-white border border-primary-light hover:border-primary px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:ring",
          )}
        >
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary">
          <RPCaret />
        </div>
      </div>
    </div>
  );
}
