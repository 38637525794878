import { format } from "date-fns";
import React from "react";

import { ShiftOrTransient } from "./shift_row";

export type BreakPopoverProps = ShiftOrTransient;

export function breakPopoverDisabled(workedShift: WorkedShift) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    workedShift &&
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (!workedShift.taken_breaks || workedShift.taken_breaks.length === 0)
  );
}
export function BreakPopover(props: BreakPopoverProps) {
  const { workedShift } = props;
  if (!workedShift) {
    return null;
  }
  if (breakPopoverDisabled(workedShift)) {
    return null;
  }
  return (
    <div>
      {workedShift.break_duration != null && <div>These breaks have been overridden.</div>}
      {workedShift.taken_breaks.map((b, i) => {
        let start = b.start ? format(new Date(b.start), "h:mm a EEE") : "error";
        let end = b.end ? format(new Date(b.end), "h:mm a EEE") : "in progress";
        return (
          <div key={i}>
            From {start} ➡ {end}
          </div>
        );
      })}
    </div>
  );
}
