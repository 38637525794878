import { parseISO, add, formatISO, differenceInDays } from "date-fns";
import React from "react";
import { useNavigate, useLocation, Location } from "react-router-dom";

export type DaysRange = 6 | 13;

export const START_DATE_QUERY_PARAM = "s";
export const EMPLOYEE_ID_QUERY_PARAM = "e";
const LEADING_PAY_MONDAY = new Date(2020, 7 - 1, 6);

export function serialiseStartDateForQueryParam(startDate: Date) {
  return formatISO(startDate, { representation: "date" });
}

export function makeLocationPreserveParams(
  location: Location,
  {
    pathname,
    startDate,
    employee_id,
  }: {
    pathname?: string;
    startDate?: Date;
    employee_id?: number;
  },
) {
  let searchParams = new URLSearchParams(location.search);

  if (startDate) {
    searchParams.set(START_DATE_QUERY_PARAM, serialiseStartDateForQueryParam(startDate));
  }
  if (employee_id) {
    searchParams.set(EMPLOYEE_ID_QUERY_PARAM, String(employee_id));
  }
  let newLocation = {
    search: searchParams.toString(),
    pathname: pathname ? pathname : location.pathname,
  };
  return newLocation;
}

export function useStartDate(handleResponseError: (response: Response) => void, defaultRange: DaysRange = 13) {
  let navigate = useNavigate();
  let location = useLocation();
  let startParam = new URLSearchParams(location.search).get(START_DATE_QUERY_PARAM);
  const [range, setRange] = React.useState<DaysRange>(defaultRange);
  let startDate = React.useMemo(() => {
    return startParam ? checkDateAligns(parseISO(startParam)) : null;
  }, [startParam]);

  function checkDateAligns(date: Date): Date | null {
    // Check if it aligns.
    // eg: Navigating from /roster?s=2020-07-13 to /timesheets?s=2020-07-13
    //     The date needs to be fixed because it isn't a multiple of 14
    let difference = differenceInDays(date, LEADING_PAY_MONDAY);
    // The roster may change start date by 1 week instead of fortnight
    const PERIOD_LENGTH = range + 1; // 14 or 7
    const mod = difference % PERIOD_LENGTH;
    if (mod !== 0) {
      console.warn(`Invalid start date: ${date}`);
      return add(date, { days: -mod });
    }
    return date;
  }

  function setStartDate(newStartDate: Date) {
    let nextCalendarStartDate = add(newStartDate, { days: range + 1 });
    if (nextCalendarStartDate < new Date()) {
      // If the next start is before now. move the start forward.
      // This case exists because the server returns a fortnightly date, but roster has a weekly view.
      newStartDate = nextCalendarStartDate;
    }
    let newLocation = makeLocationPreserveParams(location, {
      startDate: newStartDate,
    });
    if (!startDate) {
      navigate(newLocation, { replace: true });
    } else {
      navigate(newLocation);
    }
  }

  async function fetchStartDate() {
    let response = await fetch("/api/start_date", {
      method: "GET",
    });
    if (!response.ok) {
      handleResponseError(response);
    } else {
      let json = await response.json();
      json.StartDate = parseISO(json.StartDate);
      if (startDate) {
        let difference = differenceInDays(startDate, json.StartDate);
        // The roster may change start date by 1 week instead of fortnight
        const PERIOD_LENGTH = range + 1; // 14 or 7
        if (difference % PERIOD_LENGTH !== 0) {
          setStartDate(json.StartDate);
        }
      } else {
        setStartDate(json.StartDate);
      }
    }
  }

  React.useEffect(() => {
    if (startDate == null) {
      // If startDate gets changed to null, get it again
      // eg: Can happen if you link to /leave without params
      // Also happens if you navigate to /timesheets without params
      fetchStartDate();
    }
  }, [startDate]);

  // useEffect doesn't work here because we end up with 2 state updates (2 api calls)
  let endDate = React.useMemo(() => {
    if (!startDate) return null;
    return add(startDate, { days: range });
  }, [startDate, range]);

  return [startDate, endDate, setRange] as const;
}

export function useEmployeeId() {
  let navigate = useNavigate();
  let location = useLocation();

  let employeeParam = new URLSearchParams(location.search).get(EMPLOYEE_ID_QUERY_PARAM);
  let employee_id = React.useMemo(() => {
    return employeeParam ? Number(employeeParam) : null;
  }, [employeeParam]);

  function setEmployeeId(new_employee_id: number) {
    let newLocation = makeLocationPreserveParams(location, {
      employee_id: new_employee_id,
    });
    if (!employee_id) {
      navigate(newLocation, { replace: true });
    } else {
      navigate(newLocation);
    }
  }
  return [employee_id, setEmployeeId] as const;
}
