import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ShiftObj, TakenBreakObj } from "store/reducers";
import { RootState } from "store/store";

import { goBackNow } from "./attendanceSlice";

export type PinResponse = {
  employee: Employee;
  employee_token: string;
  last_shift_obj?: ShiftObj;
  last_shift?: WorkedShift;
  guessed_location?: LocationSchema;
  current_location?: LocationSchema;
  ip_is_onsite: boolean;
  open_shift?: WorkedShift;
  still_valid?: boolean;
  url: string;
};

type SubmitPinPayload = {
  username: string;
  password: string;
};

type ClockOnPayload = {
  employee: Employee;
  location: LocationSchema | undefined | null;
  earningsRate: string;
};

type ArchiveShiftPayload = {
  open_shift: WorkedShift;
  note: string;
};

type ClockOffPayload = {
  open_shift: WorkedShift;
};

type StartBreakPayload = {
  open_shift: WorkedShift;
};

type EndBreakPayload = {
  unfinishedBreak: TakenBreak;
};

type StartTravelPayload = {
  employee_id: number;
  travel_to_location_id: number;
  travel_start_time: Date;
};

const attendanceApi = createApi({
  reducerPath: "attendanceApi",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).attendance.pinResponse?.employee_token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitPin: builder.mutation<PinResponse, SubmitPinPayload>({
      query: (payload) => ({
        url: "/api/login/pin",
        method: "POST",
        body: payload,
      }),
    }),
    clockOn: builder.mutation<ShiftObj, ClockOnPayload>({
      query: (payload) => ({
        url: "/api/worked_shift/clock_on",
        method: "POST",
        body: {
          employee_id: payload.employee.id,
          xero_EarningsRateName: payload.earningsRate,
          location_id: payload.location ? payload.location.id : null,
        },
      }),
    }),
    archiveShift: builder.mutation<WorkedShift, ArchiveShiftPayload>({
      query: ({ open_shift, note }) => ({
        url: `/api/archive_shift/${open_shift.id}`,
        method: "POST",
        body: { note: note || undefined },
      }),
      onQueryStarted: async (_, { dispatch, getState, queryFulfilled }) => {
        const response = await queryFulfilled;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (response.data) {
          const state = getState() as RootState;
          const employee_token = state.attendance.pinResponse?.employee_token;

          // It was archived, load again to show the clock on button again.
          if (employee_token) {
            dispatch(attendanceApi.endpoints.refresh.initiate(employee_token));
          }
        }
      },
    }),
    clockOff: builder.mutation<ShiftObj, ClockOffPayload>({
      query: ({ open_shift }) => ({
        url: "/api/worked_shift/clock_off",
        method: "POST",
        body: { worked_shift_id: open_shift.id },
      }),
    }),
    startBreak: builder.mutation<TakenBreakObj, StartBreakPayload>({
      query: ({ open_shift }) => ({
        url: "/api/taken_break/start_break",
        method: "POST",
        body: { worked_shift_id: open_shift.id },
      }),
    }),
    endBreak: builder.mutation<TakenBreakObj, EndBreakPayload>({
      query: ({ unfinishedBreak }) => ({
        url: "/api/taken_break/end_break",
        method: "POST",
        body: { taken_break_id: unfinishedBreak.id },
      }),
    }),
    startTravel: builder.mutation<TravelEvent, StartTravelPayload>({
      query: (payload) => ({
        url: "/api/start_travel",
        method: "POST",
        body: payload,
      }),
    }),
    refresh: builder.mutation<PinResponse, string>({
      query: (employeeToken) => ({
        url: "/api/auth/refresh",
        method: "POST",
        headers: {
          Authorization: `Bearer ${employeeToken}`,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const response = await queryFulfilled;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!response.data) {
          dispatch(goBackNow());
        }
      },
    }),
  }),
});

export { attendanceApi };

export const {
  useSubmitPinMutation,
  useClockOnMutation,
  useArchiveShiftMutation,
  useClockOffMutation,
  useStartBreakMutation,
  useEndBreakMutation,
  useStartTravelMutation,
  useRefreshMutation,
} = attendanceApi;
