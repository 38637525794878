import React from "react";
import { useAppSelector } from "store/hooks";
import { selectLeavePeriodById } from "store/reducers";

type LeavePeriodComponentProps = {
  leavePeriodId: number;
};

const LeavePeriod: React.FC<LeavePeriodComponentProps> = ({ leavePeriodId }) => {
  const leavePeriod = useAppSelector((state) => selectLeavePeriodById(state, leavePeriodId));

  if (!leavePeriod) {
    return null;
  }
  const title = `In the Pay Period ending on ${leavePeriod.xero_PayPeriodEndDate}`;
  return <span title={title}>{leavePeriod.xero_NumberOfUnits}h</span>;
};

export default LeavePeriod;
