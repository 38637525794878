import { format } from "date-fns";

type GroupedMessages = {
  [key: number]: Message[];
};

export type Thread = {
  messages: Message[];
  read: boolean;
  employee_id: number;
  last_activity: string;
  requires_ack: boolean;
};

// Group messages by employee_id
export const groupMessagesByEmployee = (messages: Message[]) => {
  const groupedMessages: { [employee_id: number]: Message[] } = {};
  messages.forEach((message) => {
    if (message.employee_id !== undefined) {
      let employeeMessages = groupedMessages[message.employee_id];
      if (!employeeMessages) {
        employeeMessages = [];
        groupedMessages[message.employee_id] = employeeMessages;
      }
      employeeMessages.push(message);
    } else {
      throw new Error("Error loading messages");
    }
  });
  return groupedMessages;
};

// Create threads with additional fields
export const createThreads = (groupedMessages: GroupedMessages): Thread[] => {
  return Object.keys(groupedMessages).map((employee_id) => {
    let employeeMessages = groupedMessages[parseInt(employee_id)];
    if (!employeeMessages) {
      throw new Error("Employee not found in groupedMessages");
    }
    employeeMessages = employeeMessages.sort((a, b) => new Date(a.when).getTime() - new Date(b.when).getTime());
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const read = employeeMessages.every((message) => message.read_at !== null);
    const lastMessage = employeeMessages[employeeMessages.length - 1];
    if (!lastMessage) {
      throw new Error("Last message not found in employeeMessages");
    }
    const last_activity = lastMessage.when;
    const requires_ack = employeeMessages.some((message) => !message.acknowledged_at && message.requires_ack);

    return {
      messages: employeeMessages,
      read,
      employee_id: parseInt(employee_id),
      last_activity,
      requires_ack,
    };
  });
};

export const formatRoster = (start: string, end: string, location: LocationSchema, department: Department) => {
  const startDate = format(new Date(start), "PPP");
  const startTime = format(new Date(start), "p");
  const endTime = format(new Date(end), "p");
  const rosterLabel = `${startDate} | ${startTime} - ${endTime} | ${department.name} at ${location.name}`;

  return rosterLabel;
};

// Do not show current or previous roster shifts
export const filteredDateRoster = (employeeRoster: RosteredShift[]) => {
  return employeeRoster.filter((roster) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const startDate = new Date(roster.start);
    return startDate >= today;
  });
};
