import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "store/store";
import "react-toastify/dist/ReactToastify.css";

import App from "./app";

function Main() {
  return (
    <Provider store={store}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <App />
      </React.Suspense>
    </Provider>
  );
}

const app = document.getElementById("app");
if (!app) {
  throw new Error("No app element for React to render into.");
}
const root = createRoot(app);
root.render(<Main />);
