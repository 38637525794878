import React, { createContext, useState, useContext } from "react";
import EmployeeNotesModal from "shared/employee_notes_modal";
import { useAppSelector } from "store/hooks";
import { selectEmployeeById } from "store/reducers";

interface EmployeeNotesModalContextType {
  employeeNotesModalIsOpen: boolean;
  openEmployeeNotesModal: (employeeId: number) => void;
  closeEmployeeNotesModal: () => void;
  employee: Employee | null;
}

interface EmployeeNotesModalProviderProps {
  children: React.ReactNode;
}

const EmployeeNotesModalContext = createContext<EmployeeNotesModalContextType>({
  employeeNotesModalIsOpen: false,
  openEmployeeNotesModal: () => {},
  closeEmployeeNotesModal: () => {},
  employee: null,
});

export function EmployeeNotesModalProvider({ children }: EmployeeNotesModalProviderProps) {
  const [employeeNotesModalIsOpen, setEmployeeNotesModalIsOpen] = useState(false);

  const [employeeId, setEmployeeId] = useState<number | null>(null);

  const employee = useAppSelector((state) => (employeeId ? selectEmployeeById(state, employeeId) : null));

  function openEmployeeNotesModal(employeeId: number) {
    setEmployeeId(employeeId);
    setEmployeeNotesModalIsOpen(true);
  }

  function closeEmployeeNotesModal() {
    setEmployeeNotesModalIsOpen(false);
    setEmployeeId(null);
  }

  return (
    <EmployeeNotesModalContext.Provider
      value={{
        employeeNotesModalIsOpen,
        openEmployeeNotesModal,
        closeEmployeeNotesModal,
        // @ts-expect-error TS2322
        employee,
      }}
    >
      {children}
      {employee && <EmployeeNotesModal />}
    </EmployeeNotesModalContext.Provider>
  );
}

export function useEmployeeNotesModal() {
  return useContext(EmployeeNotesModalContext);
}
