import React from "react";

import PublicHolidayEventModal from "./public_holiday_modal";

const NewPublicHolidayEvent = () => {
  const [publicHolidayEventModal, setPublicHolidayEventModal] = React.useState<any>(undefined);

  function openPublicHolidayEventModal() {
    setPublicHolidayEventModal(
      <PublicHolidayEventModal closePublicHolidayEventModal={() => setPublicHolidayEventModal(undefined)} />,
    );
  }

  return (
    <div className="bg-background">
      <span className="py-2 mr-4 mt-2 text-xl font-semibold text-primary">Public Holidays</span>
      <button
        onClick={() => openPublicHolidayEventModal()}
        className="bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring"
      >
        New Public Holiday Event
      </button>
      {publicHolidayEventModal}
    </div>
  );
};

export default NewPublicHolidayEvent;
