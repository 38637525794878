import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatISO } from "date-fns";
import { handleResponseError } from "store/handleResponseError";

export type LeaveFilterType = "PENDING" | "APPROVED" | "ARCHIVED" | "ALL";

const fetchLeaveApplications = createAsyncThunk(
  "leaveApplication/fetchAll",
  async (
    parameters: {
      employee_id?: number;
      xero_PayPeriodEndDate?: Date;
      filterType?: LeaveFilterType;
      xero_tenantId?: string;
    },
    thunkAPI,
  ) => {
    let url = new URL("/api/leave_application", location.origin);

    if (parameters.employee_id != null) {
      url.searchParams.append("employee_id", String(parameters.employee_id));
    }

    if (parameters.xero_PayPeriodEndDate != null) {
      url.searchParams.append(
        "xero_PayPeriodEndDate",
        formatISO(parameters.xero_PayPeriodEndDate, { representation: "date" }),
      );
    }

    if (parameters.xero_tenantId != null) {
      url.searchParams.append("xero_tenantId", parameters.xero_tenantId);
    }

    if (!!parameters.filterType) {
      url.searchParams.append("filter_type", parameters.filterType);
    }

    const response = await fetch(url, {
      method: "GET",
    });
    return handleResponseError<{
      leave_applications: LeaveApplication[];
      leave_periods: LeavePeriod[];
      employees: Employee[];
    }>(response, thunkAPI);
  },
);

const createLeaveApplication = createAsyncThunk("leaveApplication/create", async (body: any, thunkAPI) => {
  const response = await fetch("/api/leave_application", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return handleResponseError<{
    leave_application: LeaveApplication;
    leave_periods: LeavePeriod[];
  }>(response, thunkAPI);
});

const updateLeaveApplication = createAsyncThunk(
  "leaveApplication/update",
  async ({ leaveApplicationId, body }: { leaveApplicationId: number; body: any }, thunkAPI) => {
    const response = await fetch(`/api/leave_application/${leaveApplicationId}`, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return handleResponseError<{
      leave_application: LeaveApplication;
      leave_periods: LeavePeriod[];
    }>(response, thunkAPI);
  },
);

const deleteLeaveApplication = createAsyncThunk(
  "leaveApplication/delete",
  async ({ leaveApplicationId, reason }: { leaveApplicationId: number; reason?: string }, thunkAPI) => {
    const response = await fetch(`/api/leave_application/${leaveApplicationId}`, {
      method: "DELETE",
      body: JSON.stringify({
        reason,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return handleResponseError<LeaveApplication>(response, thunkAPI);
  },
);

export { fetchLeaveApplications, createLeaveApplication, updateLeaveApplication, deleteLeaveApplication };
