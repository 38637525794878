import { To, createSearchParams } from "react-router-dom";

export function createLoginLocation(): To {
  const _continue = location.pathname + location.search;
  return {
    pathname: "/login",
    search: createSearchParams({
      continue: _continue,
    }).toString(),
  };
}
