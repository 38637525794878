import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export type UnavailableEventResponse = {
  unavailable_events: UnavailableEvent[];
};

export const unavailableEventApi = createApi({
  reducerPath: "unavailableEventApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["UnavailableEvent"],
  endpoints: (builder) => ({
    fetchEmployeeAvailability: builder.query<UnavailableEventResponse, { employee_id?: number; date?: string }>({
      query: (data) => {
        const searchParams = new URLSearchParams();
        if (data.employee_id) {
          searchParams.append("employee_id", String(data.employee_id));
        }
        if (data.date) {
          searchParams.append("date", data.date);
        }
        return {
          url: `/api/unavailability?${searchParams.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["UnavailableEvent"],
    }),
    createUnavailableEvent: builder.mutation<UnavailableEvent, Partial<UnavailableEvent>>({
      query: (data) => ({
        url: "/api/unavailability",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["UnavailableEvent"],
    }),
    updateUnavailableEvent: builder.mutation<UnavailableEvent, { id: number; data: Partial<UnavailableEvent> }>({
      query: ({ id, data }) => ({
        url: `/api/unavailability/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UnavailableEvent"],
    }),
    deleteUnavailableEvent: builder.mutation<void, number>({
      query: (id) => ({
        url: `/api/unavailability/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UnavailableEvent"],
    }),
  }),
});

export const {
  useFetchEmployeeAvailabilityQuery,
  useCreateUnavailableEventMutation,
  useUpdateUnavailableEventMutation,
  useDeleteUnavailableEventMutation,
} = unavailableEventApi;
