import { format, isEqual, parse, parseISO } from "date-fns";
import React from "react";
import {
  useCreatePublicHolidayEventMutation,
  useFetchPublicHolidayEventsQuery,
  useFetchPublicHolidayRecommendationsQuery,
} from "store/reducers";

const PublicHolidayRecommendations = () => {
  const { data: publicHolidayRecommendations } = useFetchPublicHolidayRecommendationsQuery();
  const { data: publicHolidayEvents } = useFetchPublicHolidayEventsQuery();
  const [createPublicHolidayEvent] = useCreatePublicHolidayEventMutation();

  const filteredEvents = publicHolidayRecommendations?.filter((event) => {
    const dateObject = parseISO(event.Date);
    return !publicHolidayEvents?.public_holiday_events.find((existing_event) =>
      isEqual(parseISO(existing_event.date), dateObject),
    );
  });

  const events = () =>
    filteredEvents && filteredEvents.length > 0 ? (
      filteredEvents.map((event) => {
        const dateObject = parse(event["Date"], "yyyyMMdd", new Date());
        const readableDate = format(dateObject, "MMMM dd, yyyy");
        return (
          <div className="grid grid-cols-3 gap-4 mb-1" key={event._id}>
            <div className="text-grey-darker">
              <div>{event["Holiday Name"]}</div>
            </div>
            <div className="text-grey-darker">
              <div>{readableDate}</div>
            </div>
            <div>
              <button
                onClick={() =>
                  createPublicHolidayEvent({
                    name: event["Holiday Name"],
                    date: format(dateObject, "yyyy-MM-dd"),
                  })
                }
                className="bg-accent hover:bg-accent text-white font-bold py-1 px-2 rounded focus:outline-none focus:ring"
              >
                Add Event
              </button>
            </div>
          </div>
        );
      })
    ) : (
      <div>No Events To Recommend</div>
    );

  return (
    <div className="mt-8">
      <h3 className="text-primary-dark mb-2">Official Public-Holiday Calendar Recommendations</h3>
      {events()}
    </div>
  );
};

export default PublicHolidayRecommendations;
