import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatISO } from "date-fns";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Summary } from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { useFetchOrganisationsQuery, usePublishShiftsMutation } from "store/reducers/organisation";

import { Button } from "../components/button";
import { useHandleResponseError } from "../helpers/useHandleResponseError";
import { Duration } from "../timesheet_viewer/table_components/duration";
import { makeLocationPreserveParams } from "../timesheet_viewer/useStartDate";

export type SummaryData = {
  employee: Employee;
  summary: Summary;
  leave_total: number;
}[];

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  organisation: Organisation;
};

export function SummaryTable(props: Props) {
  const { startDate, endDate, organisation } = props;
  const location = useLocation();
  const { data: orgStore } = useFetchOrganisationsQuery();
  const [publishShifts] = usePublishShiftsMutation();
  const { data: configData } = useGetConfigQuery();
  const [summaryData, setSummaryData] = React.useState<SummaryData>();
  const handleResponseError = useHandleResponseError();

  React.useEffect(() => {
    async function fetchSummary() {
      if (!startDate) {
        return;
      }
      let response = await fetch("/api/summary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: formatISO(startDate, { representation: "date" }),
          xero_tenantId: organisation.xero_tenantId,
        }),
      });
      if (response.ok) {
        setSummaryData(await response.json());
      } else {
        handleResponseError(response);
      }
    }
    fetchSummary();
  }, [startDate, organisation, handleResponseError]);

  return (
    <table className="table">
      <thead>
        <tr>
          <th rowSpan={1} colSpan={7} className="table-header-name">
            <FontAwesomeIcon icon="info" /> Employee Summary
          </th>
        </tr>
        <tr>
          <th className="table-header-column-name">Employee</th>
          <th className="table-header-column-name">Ordinary</th>
          <th className="table-header-column-name">Vocational</th>
          <th className="table-header-column-name">Saturday</th>
          <th className="table-header-column-name">Toil Accrual</th>
          <th className="table-header-column-name">Travel</th>
          <th className="table-header-column-name">Leave</th>
        </tr>
      </thead>
      <tbody>
        {summaryData?.map((e, i) => {
          let organisation = orgStore?.organisations.find((o) => o.xero_tenantId === e.employee.xero_tenantId);

          let employeeTSLocation = makeLocationPreserveParams(location, {
            pathname: "/timesheets",
            employee_id: e.employee.id,
          });

          return (
            <tr key={i}>
              <td className="flex items-center table-body-cell">
                <Link to={employeeTSLocation}>{e.employee.name}</Link>

                {configData?.debug && (
                  <Button
                    className="ml-auto"
                    colour="accent"
                    size="sm"
                    onClick={() => {
                      if (organisation && startDate && endDate) {
                        publishShifts({
                          organisation,
                          startDate,
                          endDate,
                          employee_ids: [e.employee.id],
                        });
                      }
                    }}
                  >
                    Publish
                  </Button>
                )}
              </td>
              <td className="table-body-cell">
                {e.summary.worked.ordinary > 0 && (
                  <div>
                    <Duration seconds={e.summary.worked.ordinary} />
                  </div>
                )}
              </td>
              <td className="table-body-cell">
                {e.summary.worked.vocational > 0 && (
                  <div>
                    <Duration seconds={e.summary.worked.vocational} />
                  </div>
                )}
              </td>
              <td className="table-body-cell">
                {e.summary.worked.saturday > 0 && (
                  <div>
                    <Duration seconds={e.summary.worked.saturday} />
                  </div>
                )}
              </td>
              <td className="table-body-cell">
                {e.summary.toil_accrual > 0 && <Duration seconds={e.summary.toil_accrual} />}
              </td>
              <td className="table-body-cell">{e.summary.travel > 0 && e.summary.travel}</td>
              <td className="table-body-cell">{e.leave_total > 0 && <Duration seconds={e.leave_total} />}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
