import classNames from "classnames";
import React from "react";
import { useLazyGetXeroImportQuery } from "store/reducers";

import { EmployeeImport } from "./EmployeeImport";
import { Button } from "../components/button";
import { LoadingSpinner } from "../components/LoadingSpinner";

export const EmployeeImportList: React.FC = () => {
  const [getXeroImport, getXeroImportResult] = useLazyGetXeroImportQuery();

  // TODO: add buttons to sync other things here: teamup events, changes to employee data in Xero

  return (
    <div>
      <Button colour="accent" onClick={() => getXeroImport()} className="flex items-center">
        Find Missing Employees in Xero
        <div className="ml-1 flex items-center">
          <LoadingSpinner
            className={classNames({
              hidden: !getXeroImportResult.isLoading,
            })}
          />
        </div>
      </Button>
      {getXeroImportResult.isSuccess &&
        getXeroImportResult.data.employees.map((e) => <EmployeeImport key={e.employee.employee_id} e={e} />)}
    </div>
  );
};
