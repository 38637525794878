import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { parseISO } from "date-fns";

type PublicHolidayEventResponse = {
  public_holiday_events: PublicHolidayEvent[];
};

type PublicHolidayRecommendationResponse = {
  result: {
    records: {
      _id: number; // 1
      Date: string; // 20230101
      "Holiday Name": string; // New Year's Day
      Information: string; // New Year's Day is the first day of the year.
      "More Information": string; // https://www.cmtedd.act.gov.au/communication/holidays
      Jurisdiction: string; // sa
    }[];
  };
};

const publicHolidayApi = createApi({
  reducerPath: "publicHolidayApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["PublicHolidayEvent"],
  endpoints: (builder) => ({
    fetchPublicHolidayEvents: builder.query<PublicHolidayEventResponse, void>({
      query: () => "/api/public_holiday",
      providesTags: (result) => {
        return result
          ? [
              ...result.public_holiday_events.map((event) => ({
                type: "PublicHolidayEvent" as const,
                // TODO(store): this id tag doesn't do anything yet because we always invalidate the whole list
                id: event.id,
              })),
              { type: "PublicHolidayEvent", id: "LIST" },
            ]
          : [{ type: "PublicHolidayEvent", id: "LIST" }];
      },
    }),
    fetchPublicHolidayRecommendations: builder.query<PublicHolidayRecommendationResponse["result"]["records"], void>({
      query: () => ({
        // Get the resource_id from here:
        // https://data.gov.au/dataset/ds-dga-b1bc6077-dadd-4f61-9f8c-002ab2cdff10/details
        url: "https://data.gov.au/data/api/3/action/datastore_search",
        params: {
          // Australian public holidays combined (2021-2024)
          // https://data.gov.au/dataset/ds-dga-b1bc6077-dadd-4f61-9f8c-002ab2cdff10/distribution/dist-dga-33673aca-0857-42e5-b8f0-9981b4755686/details?q=
          resource_id: "33673aca-0857-42e5-b8f0-9981b4755686",
          q: "sa",
          // None of these work, even though it says it does https://data.gov.au/data/api/1/util/snippet/api_info.html?resource_id=33673aca-0857-42e5-b8f0-9981b4755686
          // q: 'Jurisdiction:sa'
          // sql: 'SELECT * from "33673aca-0857-42e5-b8f0-9981b4755686" WHERE Jurisdiction LIKE \'sa\'',
          // sql: 'SELECT * from "33673aca-0857-42e5-b8f0-9981b4755686" WHERE "33673aca-0857-42e5-b8f0-9981b4755686".Jurisdiction LIKE \'sa\'',
        },
      }),
      transformResponse: (response: PublicHolidayRecommendationResponse) => {
        const records = response.result.records.filter((record) => {
          // Filter out public holidays in the past and not in
          // South Australia
          const dateObject = parseISO(record.Date);
          return record.Jurisdiction == "sa" && dateObject >= new Date();
        });
        return records;
      },
    }),
    createPublicHolidayEvent: builder.mutation<PublicHolidayEvent, Partial<PublicHolidayEvent>>({
      query: (event) => ({
        url: "/api/public_holiday",
        method: "POST",
        body: event,
      }),
      invalidatesTags: [{ type: "PublicHolidayEvent", id: "LIST" }],
    }),
    updatePublicHolidayEvent: builder.mutation<
      PublicHolidayEvent,
      { id: PublicHolidayEvent["id"]; data: Partial<PublicHolidayEvent> }
    >({
      query: ({ id, data }) => ({
        url: `/api/public_holiday/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "PublicHolidayEvent", id },
        { type: "PublicHolidayEvent", id: "LIST" },
      ],
    }),
    deletePublicHolidayEvent: builder.mutation<void, PublicHolidayEvent["id"]>({
      query: (id) => ({
        url: `/api/public_holiday/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "PublicHolidayEvent", id },
        { type: "PublicHolidayEvent", id: "LIST" },
      ],
    }),
  }),
});

export { publicHolidayApi };
export const {
  useFetchPublicHolidayEventsQuery,
  useFetchPublicHolidayRecommendationsQuery,
  useCreatePublicHolidayEventMutation,
  useUpdatePublicHolidayEventMutation,
  useDeletePublicHolidayEventMutation,
} = publicHolidayApi;
