import classNames from "classnames";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { createLoginLocation } from "./redirectLogin";

const SESSION_EXPIRED_TOAST_ID = "Login session expired toast";

export function useHandleResponseError() {
  const navigate = useNavigate();
  const location = useLocation();

  return React.useCallback(
    async function (response: Response) {
      if (response.status === 401) {
        // Make sure if you want to handle 401 differently that you don't call this function
        if (location.pathname !== "/login") {
          navigate(createLoginLocation());
        }
        toast("Login session expired. Please login again.", {
          toastId: SESSION_EXPIRED_TOAST_ID,
          type: "error",
        });
      } else {
        const contentType = response.headers.get("content-type");
        let message;
        if (contentType && contentType.includes("application/json")) {
          // clone() so that body be read again later without "TypeError: Body has already been consumed"
          const json = await response.clone().json();
          // eg: flask.abort(400, description="")
          message = json.message || json.description;
        }
        let url = new URL(response.url);
        const toastContent = (
          <div>
            <div className={classNames({ "font-semibold": message })}>
              {response.statusText || `HTTP error ${response.status}`}
            </div>
            {message && <div>{message}</div>}
            {<div className="text-xs">{url.pathname}</div>}
          </div>
        );
        toast(toastContent, {
          type: "error",
        });
      }
    },
    [navigate, location],
  );
}
