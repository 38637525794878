import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import React from "react";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>((props, ref) => (
  <label className="flex items-center text-primary text-base gap-1">
    <CheckboxPrimitive.Root
      {...props}
      ref={ref}
      className="CheckboxRoot bg-white rounded h-5 w-5 flex items-center justify-center border border-primary focus:ring hover:bg-primary-lightest"
    >
      <CheckboxPrimitive.Indicator className="CheckboxIndicator flex text-accent-dark">
        <FontAwesomeIcon icon="check" className="h-3 w-3" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {props.children}
  </label>
));
Checkbox.displayName = "Checkbox";

export { Checkbox };
