import { faChevronUp, faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as SelectPrimitive from "@radix-ui/react-select";
import classNames from "classnames";
import React from "react";
import "./Select.css";

const Select: React.FC<
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> & {
    placeholder?: string;
  }
> = (props) => {
  const { placeholder, ...rest } = props;
  return (
    <SelectPrimitive.Root {...rest}>
      <SelectPrimitive.Trigger className="SelectTrigger flex items-center justify-center rounded p-2 text-base gap-1 bg-white text-primary shadow border border-primary hover:bg-background focus:ring">
        <SelectPrimitive.Value placeholder={placeholder} className="text-primary-light" />
        <SelectPrimitive.Icon className="SelectIcon text-primary">
          <FontAwesomeIcon icon={faChevronDown} />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>

      <SelectPrimitive.Portal>
        <SelectPrimitive.Content className="SelectContent bg-white rounded shadow overflow-hidden">
          <SelectPrimitive.ScrollUpButton className="SelectScrollButton">
            <FontAwesomeIcon icon={faChevronUp} />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport className="SelectViewport p-2">{props.children}</SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton className="SelectScrollButton">
            <FontAwesomeIcon icon={faChevronDown} />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
};
Select.displayName = "Select";
// Alternative to ScollUpButton and ScrollDownButton you can use a scrollbar https://www.radix-ui.com/primitives/docs/components/select#with-custom-scrollbar

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <SelectPrimitive.Item
      className={classNames("SelectItem", className, "text-base text-primary rounded-sm flex items-center relative")}
      {...props}
      ref={forwardedRef}
    >
      <SelectPrimitive.ItemIndicator className="SelectItemIndicator absolute left-0 inline-flex items-center justify-center">
        <FontAwesomeIcon icon={faCheck} />
      </SelectPrimitive.ItemIndicator>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = "SelectItem";

export { Select, SelectItem };
