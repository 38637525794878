import classNames from "classnames";
import { add, format, formatISO } from "date-fns";
import React from "react";
import { rosterApi } from "store/reducers";

import { RosterDump } from "./roster_dump";
import { Button } from "../components/button";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Modal } from "../components/modal";
import PayPeriod from "../timesheet_viewer/payperiod";

type Props = {
  dismissModal: any;

  fromStartDate: Date;
  fromEndDate: Date;
  copyEmployees: Employee[];
};

export function RosterCopyForwardModal(props: Props) {
  const { dismissModal, fromStartDate, fromEndDate, copyEmployees } = props;

  const [copyRosterForward, copyRosterForwardMutation] = rosterApi.useCopyRosterForwardMutation();

  const [toStartDate, setStartDate] = React.useState(add(fromStartDate, { days: 7 }));
  const toEndDate = React.useMemo(() => {
    return add(toStartDate, { days: 6 });
  }, [toStartDate]);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = React.useState<number[]>(copyEmployees.map((e) => e.id));

  async function callCopyRosterForward() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (fromStartDate && toEndDate) {
      let response = await copyRosterForward({
        body: {
          from_start_date: formatISO(fromStartDate, { representation: "date" }),
          from_end_date: formatISO(fromEndDate, { representation: "date" }),
          to_start_date: formatISO(toStartDate, { representation: "date" }),
          to_end_date: formatISO(toEndDate, { representation: "date" }),
          employee_ids: selectedEmployeeIds,
        },
      }).unwrap();
      if (response.overlapping.length === 0) {
        // we're done, just close. Keep the modal open if there are warning messages to show.
        dismissModal(undefined);
      }
    }
  }

  const unselectAllEmployees = () => {
    setSelectedEmployeeIds([]);
  };

  const selectAllEmployees = () => {
    setSelectedEmployeeIds(copyEmployees.map((e) => e.id));
  };

  const handleSelectEmployee = (id: number) => {
    setSelectedEmployeeIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((employeeId) => employeeId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <Modal isOpen dismissModal={dismissModal} title="Copy roster" showBottomCloseButton={false}>
      <div className="mb-1">
        <p>
          Copy the roster from <strong className="whitespace-nowrap">{format(fromStartDate, "dd MMM yyyy")}</strong>{" "}
          &ndash; <strong className="whitespace-nowrap">{format(fromEndDate, "dd MMM yyyy")}</strong> forward to:
        </p>
        <div>
          <PayPeriod startDate={toStartDate} endDate={toEndDate} setStartDate={setStartDate} />
        </div>
      </div>
      <div className="flex gap-2 my-3">
        <Button colour="accent" onClick={selectAllEmployees}>
          Select All
        </Button>
        <Button colour="accent" onClick={unselectAllEmployees}>
          Unselect All
        </Button>
      </div>
      {copyRosterForwardMutation.isUninitialized && (
        <ol className="mb-1">
          {copyEmployees.map((employee) => (
            <div key={employee.id}>
              <input
                type="checkbox"
                checked={selectedEmployeeIds.includes(employee.id)}
                onChange={() => handleSelectEmployee(employee.id)}
              />
              <label className="ml-2">{employee.name}</label>
            </div>
          ))}
        </ol>
      )}
      {copyRosterForwardMutation.isLoading && <div>Copying Roster...</div>}
      {copyRosterForwardMutation.isSuccess && copyRosterForwardMutation.data.overlapping.length > 0 && (
        <div className="mb-1">
          <p className="text-danger mb-1">Some shifts overlap with existing shifts and were not copied.</p>
          <RosterDump showEmployeeName rostered_shifts={copyRosterForwardMutation.data.overlapping} />
        </div>
      )}
      <div className="flex">
        <div className="ml-auto" />
        {copyRosterForwardMutation.isUninitialized && (
          <Button colour="accent" onClick={callCopyRosterForward} className="flex flex-row items-center">
            Copy
            <div className="ml-1 flex items-center">
              <LoadingSpinner
                className={classNames({
                  hidden: !copyRosterForwardMutation.isLoading,
                })}
              />
            </div>
          </Button>
        )}
        {copyRosterForwardMutation.isSuccess && <Button onClick={dismissModal}>Okay</Button>}
      </div>
    </Modal>
  );
}
