import { skipToken } from "@reduxjs/toolkit/query";
import EmployeeName from "components/EmployeeName";
import SearchInput from "components/Search";
import { formatISO } from "date-fns";
import React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchLeaveApplications, useFetchEmployeesQuery } from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { selectIsManager } from "store/reducers/config/configSlice";
import { fetchRRUsers } from "store/reducers/rrUsers/rrUsersAPI";
import { useFetchWorkedShiftQuery } from "store/reducers/shift";

import { EmployeeFilterSelect, RoleFilter } from "./employee_filter";
import EmployeeList from "./employee_list";
import PayPeriod from "./payperiod";
import TimesheetSummary from "./timesheet_summary";
import TimesheetTable from "./timesheet_table";
import { useStartDate, useEmployeeId } from "./useStartDate";
import { useHandleResponseError } from "../helpers/useHandleResponseError";
import { LeaveRequests } from "../leave/leave_requests";

const TimesheetViewer = () => {
  const [searchItem, setSearchItem] = React.useState("");
  const [roleFilter, setRoleFilter] = React.useState<RoleFilter[]>([]);
  let handleResponseError = useHandleResponseError();
  let [startDate, endDate] = useStartDate(handleResponseError);

  const dispatch = useAppDispatch();
  const { data: configData } = useGetConfigQuery();
  const isManager = useAppSelector(selectIsManager);

  let [employeeId, setEmployeeId] = useEmployeeId();
  const { data: employees } = useFetchEmployeesQuery({
    date: startDate ? formatISO(startDate, { representation: "date" }) : undefined,
    // TODO(store): when we switch between employees in runs the query again and rerenders
    force_include_ids: employeeId ? [employeeId] : [],
  });

  let timesheetEmployee: Employee | undefined = React.useMemo(() => {
    if (configData?.currentEmployee?.payroll_role === "employee") {
      return configData.currentEmployee;
    }
    if (employeeId && employees) {
      return employees.employees.find((e) => e.id === employeeId);
    }
    return undefined;
  }, [employeeId, employees, configData]);

  const { data: workedShiftData } = useFetchWorkedShiftQuery(
    timesheetEmployee && startDate
      ? {
          employee_id: timesheetEmployee.id,
          start_date: formatISO(startDate, { representation: "date" }),
        }
      : skipToken,
  );

  React.useEffect(() => {
    if (timesheetEmployee) {
      document.title = `${timesheetEmployee.name}'s Timesheet | RadPay`;
    } else {
      document.title = "Timesheet | RadPay";
    }
  }, [timesheetEmployee]);

  React.useEffect(() => {
    if (employeeId != null) return; // don't set default if already set
    if (!employees) return;
    const firstEmployee = employees.employees[0];
    if (!firstEmployee) return;
    // Set default employee
    if (isManager && configData?.currentEmployee?.xero_tenantId != null) {
      setEmployeeId(configData.currentEmployee.id);
    } else if (isManager) {
      setEmployeeId(firstEmployee.id as number);
    } else {
      const currentEmployeeId = configData?.currentEmployee?.id;
      if (currentEmployeeId != null) {
        setEmployeeId(currentEmployeeId);
      }
    }
  }, [employees, configData, employeeId, isManager, setEmployeeId]);

  React.useEffect(() => {
    (async () => {
      // Wait for data to be available
      if (startDate && endDate && timesheetEmployee) {
        // updateTimesheetRange(startDate, endDate);
        await dispatch(
          fetchLeaveApplications({
            employee_id: timesheetEmployee.id,
            xero_PayPeriodEndDate: endDate,
          }),
        );
      }
    })();
  }, [startDate, endDate, timesheetEmployee?.id]);

  React.useEffect(() => {
    dispatch(fetchRRUsers());
  }, [dispatch]);

  return (
    <div className="flex bg-background px-2 mx-auto">
      {isManager && (
        <div className="flex flex-col hidden md:flex mr-3 flex-shrink-0" style={{ width: "15em" }}>
          <EmployeeFilterSelect roleFilter={roleFilter} onChangeFilter={(value) => setRoleFilter(value)} />
          <SearchInput searchItem={searchItem} setSearchItem={setSearchItem} />
          <EmployeeList
            startDate={startDate}
            timesheetEmployee={timesheetEmployee}
            searchItem={searchItem}
            roleFilter={roleFilter}
            employees={employees?.employees ?? []}
          />
        </div>
      )}

      <div className="bg-background flex-grow">
        <span className="py-2 mt-2 text-xl font-semibold text-primary">Timesheet viewer</span>
        <p className="my-2">Employee name: {timesheetEmployee && <EmployeeName employeeId={timesheetEmployee.id} />}</p>
        Pay period: <PayPeriod startDate={startDate} endDate={endDate} />
        <TimesheetSummary workedShiftData={workedShiftData} />
        {timesheetEmployee && <LeaveRequests employee={timesheetEmployee} />}
        {startDate && endDate && (
          <TimesheetTable
            startDate={startDate}
            endDate={endDate}
            timesheetEmployee={timesheetEmployee}
            workedShiftData={workedShiftData}
          />
        )}
      </div>
    </div>
  );
};

export default TimesheetViewer;
