import classNames from "classnames";
import React from "react";
import { FetchShiftsResponse } from "store/reducers";

import { Duration } from "./table_components/duration";

type Props = {
  workedShiftData: FetchShiftsResponse | undefined;
};

export default function TimesheetSummary(props: Props) {
  const { workedShiftData } = props;
  // invisible so that it doesn't jump down after it loads
  return (
    <div className="flex py-2">
      <div className={classNames("mr-4", { invisible: !workedShiftData })}>
        {workedShiftData && <Duration seconds={workedShiftData.summary.worked.total} />} total
      </div>
      <div className={classNames("mr-4", { invisible: !workedShiftData })}>
        {workedShiftData && <Duration seconds={workedShiftData.summary.toil_accrual} />} time in lieu
      </div>
      <div className={classNames("mr-4", { invisible: !workedShiftData })}>
        {workedShiftData?.summary.travel} travel units
      </div>
    </div>
  );
}
