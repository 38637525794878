import { add, formatISO } from "date-fns";
import React, { useCallback } from "react";
import { useRosterParams } from "roster/useRosterParams";
import { useAppSelector } from "store/hooks";
import { FetchAllRosteredShiftBody, selectAllRrUsers } from "store/reducers";
import { useStartDate } from "timesheet_viewer/useStartDate";

import { useHandleResponseError } from "./useHandleResponseError";

export const useRosterQueryParams = () => {
  const handleResponseError = useHandleResponseError();

  const [{ g: groupBy }, setRosterParams, { companyRoleFilter }] = useRosterParams();
  const [startDate, endDate] = useStartDate(handleResponseError, 6);
  const rrUsers = useAppSelector(selectAllRrUsers);

  const matchingUserIds = React.useMemo(() => {
    if (rrUsers.length === 0 || companyRoleFilter.length === 0) {
      return null;
    }

    return rrUsers
      .filter((user) =>
        user.company_roles.some((companyRoleId) =>
          companyRoleFilter.some((filter) => filter.includesCompanyRole(companyRoleId)),
        ),
      )
      .map((u) => u.id);
  }, [rrUsers, companyRoleFilter]);

  const getFetchAllBody = useCallback((): FetchAllRosteredShiftBody | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!startDate || !endDate || matchingUserIds === undefined || !groupBy) {
      return undefined;
    }
    return {
      startDate: formatISO(startDate, { representation: "date" }),
      endDate: formatISO(add(endDate, { days: 1 }), { representation: "date" }),
      rrUserIds: matchingUserIds,
      groupBy,
    };
  }, [startDate, endDate, matchingUserIds, groupBy]);

  const rosterQueryParams = React.useMemo(() => {
    return getFetchAllBody();
  }, [getFetchAllBody]);

  return rosterQueryParams;
};
