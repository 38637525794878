import classNames from "classnames";
import React from "react";

import { ShiftType } from "./shift_row";

type Props = {
  /**
   * Optional shiftType="rostered_shift" and in PlaceholderRow.
   */
  workedShift?: WorkedShift;
  children?: any;
  shiftType?: ShiftType;
};

const TableCell = ({ workedShift, children, shiftType }: Props) => (
  <td
    className={classNames("relative p-2 border-b border-primary-light text-sm whitespace-nowrap text-center", {
      "text-primary line-through": workedShift?.archived,
      "bg-accent-light": shiftType === "rostered_shift",
      "bg-white": shiftType !== "rostered_shift",
      "text-primary-dark": !workedShift?.archived && shiftType !== "rostered_shift",
    })}
  >
    {children}
  </td>
);

export default TableCell;
