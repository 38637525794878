import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SearchInputProps {
  setSearchItem: (value: string) => void;
  searchItem: string;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  return (
    <div className="my-2 p-1 bg-white flex border border-primary-light rounded">
      <input
        placeholder="Search by name"
        className="p-1 px-2 appearance-none outline-none w-full text-primary-dark"
        onChange={(e) => props.setSearchItem(e.target.value)}
        value={props.searchItem}
      />
      <div className="text-primary-light w-8 py-1 pl-2 pr-1 border-l flex items-center border-primary-light">
        <button className="cursor-pointer w-6 h-6 text-primary outline-none focus:outline-none">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
