import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type Query = {
  xero_tenantId: string;
};

type Result = {
  LeaveTypes: XeroLeaveType[];
};

const leaveTypeApi = createApi({
  reducerPath: "leaveTypeApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getLeaveTypes: builder.query<Result, Query>({
      query: ({ xero_tenantId }) => {
        return {
          url: "/api/fetch_leave_types",
          params: { xero_tenantId },
        };
      },
    }),
  }),
});

export { leaveTypeApi };
