import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    fetchLocations: builder.query<LocationSchema[], void>({
      query: () => "/api/fetch_locations",
      transformResponse: (response: { locations: LocationSchema[] }) => {
        return response.locations;
      },
    }),
  }),
});

export const { useFetchLocationsQuery } = locationApi;
export { locationApi };
