import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { shiftApi } from "./shiftApi";
import { RootState } from "../../store";

const adapter = createEntityAdapter<WorkedShift>();
const initialState = adapter.getInitialState();

const workedShiftSlice = createSlice({
  name: "workedShift",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(shiftApi.endpoints.fetchWorkedShift.matchFulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.worked_shifts);
    });
    builder.addMatcher(shiftApi.endpoints.createWorkedShift.matchFulfilled, (state, action) => {
      adapter.addOne(state, action.payload);
    });
    builder.addMatcher(shiftApi.endpoints.updateWorkedShift.matchFulfilled, (state, action) => {
      adapter.upsertOne(state, action.payload);
    });
    builder.addMatcher(shiftApi.endpoints.deleteWorkedShift.matchFulfilled, (state, action) => {
      adapter.upsertOne(state, action.payload);
    });
  },
});

export const workedShiftAdapter = adapter;
export { workedShiftSlice };
export const {
  selectById: selectWorkedShiftById,
  selectAll: selectAllWorkedShifts,
  selectEntities: selectWorkedShiftEntities,
} = adapter.getSelectors((state: RootState) => state.workedShift);
