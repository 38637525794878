export type MessageOption = {
  value: MessageType;
  label: string;
};

const messageOptions = [
  { value: "general", label: "General" },
  { value: "unfit_for_work", label: "Unfit for work" },
  { value: "sick_personal_leave", label: "Sick - personal leave" },
  { value: "careers_leave", label: "Careers leave" },
  { value: "running_late", label: "Running late" },
  { value: "roster_change", label: "Roster change" },
  { value: "payroll", label: "Payroll" },
  { value: "other", label: "Other" },
] as const satisfies MessageOption[];

export default messageOptions;
