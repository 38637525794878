import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "store/handleResponseError";

const fetchRRUsers = createAsyncThunk("rrUser/fetchAll", async (_arg, thunkAPI) => {
  let url = new URL("/api/rr_user", location.origin);
  const response = await fetch(url.toString(), { method: "GET" });
  return handleResponseError<{ rr_users: RRUser[] }>(response, thunkAPI);
});

export { fetchRRUsers };
