import { IconButton } from "components/iconButton";
import { format } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectRosteredShiftById } from "store/reducers";

import TableCell from "./table_cell";

type Props = {
  rosteredShiftId: number;
  revertShiftToRosteredHours: (rosteredShift: RosteredShift) => void;
};

export const RosteredShiftRow: React.FC<Props> = (props) => {
  const { rosteredShiftId, revertShiftToRosteredHours } = props;
  const rosteredShift = useAppSelector((state) => selectRosteredShiftById(state, rosteredShiftId));
  if (rosteredShift === undefined) {
    return null;
  }

  return (
    <tr key={rosteredShiftId}>
      <TableCell shiftType="rostered_shift">
        <span className="cursor-pointer font-medium text-primary-dark" title="Rostered shift">
          R
        </span>
      </TableCell>
      <TableCell shiftType="rostered_shift">{format(new Date(rosteredShift.start), "h:mm a")}</TableCell>
      <TableCell shiftType="rostered_shift">{format(new Date(rosteredShift.end), "h:mm a")}</TableCell>
      <TableCell shiftType="rostered_shift" />
      <TableCell shiftType="rostered_shift" />
      <TableCell shiftType="rostered_shift">{rosteredShift.department.name}</TableCell>
      <TableCell shiftType="rostered_shift">
        <div>{rosteredShift.location.name}</div>
        <div>{rosteredShift.notes}</div>
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => revertShiftToRosteredHours(rosteredShift)}
          title="Revert shift to rostered hours"
          colour="accent"
          size="lg"
          icon="history"
        />
      </TableCell>
    </tr>
  );
};
