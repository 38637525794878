import classNames from "classnames";
import { Argument as ClassNamesArgument } from "classnames";
import React from "react";

export type ColourType = "primary" | "accent" | "success" | "danger" | "none";
type Props = {
  colour?: ColourType;
  textColour?: ClassNamesArgument;
  size?: "icon" | "sm" | "lg";
  children?: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<"button">;

function getBgColourClasses(colour: ColourType, disabled: boolean) {
  return {
    "bg-accent hover:bg-accent-dark": colour === "accent" && !disabled,
    "bg-primary hover:bg-primary-dark": colour === "primary" && !disabled,
    "bg-success hover:bg-success-dark": colour === "success" && !disabled,
    "bg-danger hover:bg-danger-dark": colour === "danger" && !disabled,
    "bg-accent-light cursor-not-allowed": colour === "accent" && disabled,
    "bg-primary-light cursor-not-allowed": colour === "primary" && disabled,
    "bg-success-light cursor-not-allowed": colour === "success" && disabled,
    "bg-danger-light cursor-not-allowed": colour === "danger" && disabled,
    "bg-none": colour === "none",
  };
}

export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  let {
    colour = "primary",
    textColour = "text-white",
    size = "lg",
    className = "", // custom class
    children,
    ...otherProps
  } = props;
  // otherProps could have onClick, disabled, etc
  return (
    <button
      ref={ref}
      {...otherProps}
      className={classNames("font-semibold rounded focus:outline-none focus:ring", textColour, className, {
        ...getBgColourClasses(colour, otherProps.disabled ?? false),
        "py-1 px-2": size === "sm",
        "py-2 px-3": size === "lg",
        "p-0": size === "icon",
      })}
    >
      {children}
    </button>
  );
});

Button.displayName = "Button";
