import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import React from "react";
import { FetchAllRosteredShiftsResponse } from "store/reducers";

type Props = {
  rosterFetchAllData: FetchAllRosteredShiftsResponse;
};

export function MachineServicesTable(props: Props) {
  const { rosterFetchAllData } = props;
  return (
    <table className="table">
      <thead>
        <tr>
          <th rowSpan={1} colSpan={3} className="table-header-name text-warning">
            <FontAwesomeIcon icon="exclamation-circle" /> Machine Services
          </th>
        </tr>
        <tr>
          <th className="table-header-column-name">Machine</th>
          <th className="table-header-column-name">Start</th>
          <th className="table-header-column-name">End</th>
        </tr>
      </thead>
      <tbody>
        {rosterFetchAllData.machine_unavailables.map((service) => {
          const machine = rosterFetchAllData.machines.find((m) => m.id === service.machine_id);
          return (
            <tr key={service.id}>
              <td className="table-body-cell">{machine?.name}</td>
              <td className="table-body-cell">{format(parseISO(service.start), "dd/MM/yyyy hh:mm a")}</td>
              <td className="table-body-cell">{format(parseISO(service.end), "dd/MM/yyyy hh:mm a")}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
