import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { DropdownMenuPortal, DropdownMenuItem, DropdownMenuRoot, DropdownMenuTrigger } from "components/DropdownMenu";
import React from "react";
import { Link, useLocation, LinkProps } from "react-router-dom";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { selectIsManager } from "store/reducers/config/configSlice";

import { RPCaret } from "../components/RPCaret";
import { LOGIN_TOOLTIP } from "../constants";
import { useAppSelector } from "../store/hooks";
import { makeLocationPreserveParams } from "../timesheet_viewer/useStartDate";

type Props = {
  to: LinkProps["to"];
  children: React.ReactNode;
} & LinkProps;

let NavBarLink = (props: Props) => {
  const { data: configData } = useGetConfigQuery();
  const { to, children, ...otherProps } = props;
  return (
    <Link
      to={to}
      className={classNames(
        "px-4 py-2 my-2 text-sm font-semibold bg-transparent rounded-lg hover:text-primary-dark focus:text-primary-dark hover:bg-background focus:bg-background focus:outline-none focus:ring whitespace-nowrap no-underline text-primary",
      )}
      {...otherProps}
    >
      {children}
    </Link>
  );
};

const NavBar = () => {
  const location = useLocation();
  const { data: configData } = useGetConfigQuery();
  const isManager = useAppSelector(selectIsManager);

  let summaryLocation = makeLocationPreserveParams(location, {
    pathname: "/summary",
  });
  let timesheetLocation = makeLocationPreserveParams(location, {
    pathname: "/timesheets",
  });
  let leaveLocation = makeLocationPreserveParams(location, {
    pathname: "/leave",
  });
  // Temporarily disabled this to fix https://github.com/fluidsolar/radreport-feedback/issues/5257
  // let rosterLocation = makeLocationPreserveParams(location, {
  //   pathname: "/roster",
  // });
  let rosterLocation = "/roster";
  let availabilityLocation = makeLocationPreserveParams(location, {
    pathname: "/availability",
  });
  let publicHoliday = makeLocationPreserveParams(location, {
    pathname: "/public-holidays",
  });
  // let messages = makeLocationPreserveParams(location, {
  //   pathname: "/messages",
  // });
  let messages = "/messages";

  return (
    <div className={classNames("w-full antialiased bg-white text-primary")}>
      <nav className="flex flex-row flex-grow items-center flex-wrap max-w-screen-xl mx-auto">
        <a
          href="/"
          className="text-lg font-semibold tracking-widest text-primary-dark uppercase rounded-lg focus:outline-none focus:shadow-outline whitespace-no-wrap no-underline px-4 py-2 my-2 mr-auto"
        >
          Rad Pay
        </a>
        {configData?.currentEmployee && <NavBarLink to={timesheetLocation}>Timesheets</NavBarLink>}
        {isManager && <NavBarLink to={summaryLocation}>Summary</NavBarLink>}
        {configData?.currentEmployee && <NavBarLink to={leaveLocation}>Leave</NavBarLink>}
        {configData?.currentEmployee && <NavBarLink to={rosterLocation}>Roster</NavBarLink>}
        {isManager && <NavBarLink to={availabilityLocation}>Availability</NavBarLink>}
        {isManager && <NavBarLink to={publicHoliday}>Public Holidays</NavBarLink>}
        {configData?.currentEmployee && <NavBarLink to={messages}>Messages</NavBarLink>}
        {configData?.currentEmployee == null && (
          <NavBarLink to="/login" title={LOGIN_TOOLTIP}>
            Login
          </NavBarLink>
        )}
        {configData?.currentEmployee && (
          <DropdownMenuRoot>
            <DropdownMenuTrigger
              className={classNames("p-2 rounded font-medium focus:ring hover:bg-background text-sm", {
                "text-primary": !configData.debug,
                "text-danger": configData.debug,
              })}
            >
              {configData.currentEmployee.name}
              <RPCaret className="inline" />
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuItem asChild>
                <Link to="/profile">Profile</Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <a href="/api/logout">Logout</a>
              </DropdownMenuItem>
              {configData.currentEmployee.payroll_role === "owner" && (
                <DropdownMenuItem asChild>
                  <a href="/api/oauth/xero/authorise">
                    <FontAwesomeIcon icon="link" />
                    <span className="mx-4">Link to Xero</span>
                  </a>
                </DropdownMenuItem>
              )}
              {isManager && (
                <DropdownMenuItem asChild>
                  <Link to="/settings">Settings</Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem asChild>
                <a
                  target="_blank"
                  href="https://docs.google.com/document/d/11p-GbwTDdxJH1P9Sw-rUnRS6QsQOaLWuOxJLc4ZZRt4/edit#heading=h.ffe0i81i9va2"
                >
                  Changelog {CLIENT_VERSION}
                </a>
              </DropdownMenuItem>
            </DropdownMenuPortal>
          </DropdownMenuRoot>
        )}
      </nav>
    </div>
  );
};

export default NavBar;
