import { useUploadFileMutation, useUploadMutation } from "store/reducers";

export const useFileUpload = (
  selectedEmployee: Employee | null | undefined,
  setTempAttachment: React.Dispatch<React.SetStateAction<Upload | undefined>>,
) => {
  const [uploadFile, uploadFileResult] = useUploadFileMutation();
  const [upload, uploadResult] = useUploadMutation();
  const isUploading = uploadFileResult.isLoading || uploadResult.isLoading;

  const fileAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null) {
      console.warn("event.target.files == null");
      return;
    }

    if (!selectedEmployee) return;

    const file = event.target.files[0];

    if (!file) {
      throw new Error("No file selected");
    }

    if (file.size > 30 * 1024 * 1024) {
      // see server config MAX_CONTENT_LENGTH
      throw new Error("File is too large");
    }

    let data = new FormData();
    data.append("file", file);
    let res1 = await uploadFile(data).unwrap();
    if (uploadFileResult.isError) {
      throw new Error(`Upload file error`);
    }
    let res2 = await upload({
      filename: res1.filename,
      original_filename: file.name,
      employee_id: selectedEmployee.id,
    }).unwrap();
    setTempAttachment(res2);
    return res2;
  };

  return {
    fileAsync,
    isUploading,
    uploadResult,
    uploadFileResult,
  };
};
