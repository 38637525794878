import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { rosterApi } from "../rosteredShift";

const adapter = createEntityAdapter<UnavailableEvent>();
const initialState = adapter.getInitialState();

const unavailableEventSlice = createSlice({
  name: "unavailableEvents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(rosterApi.endpoints.fetchAll.matchFulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.unavailable_events);
    });
  },
});

export const unavailableEventAdapter = adapter;
export { unavailableEventSlice };
export const {
  selectById: selectUnavailableEventById,
  selectAll: selectAllUnavailableEvents,
  selectEntities: selectUnavailableEventEntities,
} = adapter.getSelectors((state: RootState) => state.unavailableEvent);
