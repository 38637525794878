import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/button";
import { DropdownMenuPortal, DropdownMenuItem, DropdownMenuRoot, DropdownMenuTrigger } from "components/DropdownMenu";
import { min, max, differenceInDays, areIntervalsOverlapping } from "date-fns";
import LeavePeriod from "leave/LeavePeriod";
import React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectLeaveApplicationById, updateLeaveApplication } from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";

type Props = {
  startDate: Date;
  endDate: Date;
  leaveApplicationId: number;
  openLeaveApplicationModal: any;
  openLeaveDeclineModal: any;
};

export function RosterLeaveRow(props: Props) {
  let { startDate, endDate, leaveApplicationId, openLeaveApplicationModal, openLeaveDeclineModal } = props;

  const dispatch = useAppDispatch();
  const leaveApplication = useAppSelector((state) => selectLeaveApplicationById(state, leaveApplicationId));
  const isManager = useAppSelector(selectIsManager);

  function updateLeave(id: number, data: Partial<LeaveApplication>) {
    dispatch(
      updateLeaveApplication({
        leaveApplicationId: id,
        body: data,
      }),
    );
  }

  if (!leaveApplication) return null;

  if (
    areIntervalsOverlapping(
      {
        start: startDate,
        end: endDate,
      },
      {
        start: new Date(leaveApplication.xero_StartDate),
        end: new Date(leaveApplication.xero_EndDate),
      },
      { inclusive: true },
    )
  ) {
    let rangeStart = max([startDate, new Date(leaveApplication.xero_StartDate)]);
    let rangeEnd = min([endDate, new Date(leaveApplication.xero_EndDate)]);
    const startDateOffset = differenceInDays(rangeStart, startDate);
    const spanningDays = differenceInDays(rangeEnd, rangeStart) + 1;
    return (
      <tr className="roster-table-leave-row">
        <td />
        {startDateOffset > 0 && <td colSpan={startDateOffset} />}
        <td colSpan={spanningDays}>
          <DropdownMenuRoot>
            <DropdownMenuTrigger title={leaveApplication.xero_Title || "-"} asChild>
              <Button className="w-full">
                <LeavePeriod
                  // @ts-expect-error TS2322
                  leavePeriodId={leaveApplication.leave_period_ids[0]}
                />{" "}
                {leaveApplication.approved && <FontAwesomeIcon icon="check" />}
                {leaveApplication.archived && <FontAwesomeIcon icon="times" />}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              {!leaveApplication.approved && !leaveApplication.archived && (
                <DropdownMenuItem onSelect={() => openLeaveApplicationModal(leaveApplication)}>
                  <FontAwesomeIcon icon="edit" /> Edit
                </DropdownMenuItem>
              )}
              {isManager && !leaveApplication.approved && !leaveApplication.archived && (
                <DropdownMenuItem
                  onSelect={() =>
                    updateLeave(leaveApplication.id, {
                      ...leaveApplication,
                      approved: true,
                    })
                  }
                >
                  <FontAwesomeIcon icon="check" /> Approve
                </DropdownMenuItem>
              )}
              {isManager && leaveApplication.approved && !leaveApplication.archived && (
                <DropdownMenuItem
                  onSelect={() =>
                    updateLeave(leaveApplication.id, {
                      ...leaveApplication,
                      approved: false,
                    })
                  }
                >
                  <FontAwesomeIcon icon="times" /> Unapprove
                </DropdownMenuItem>
              )}
              {isManager && !leaveApplication.approved && !leaveApplication.archived && (
                <DropdownMenuItem onSelect={() => openLeaveDeclineModal(leaveApplication)}>
                  <FontAwesomeIcon icon="trash-alt" /> Decline
                </DropdownMenuItem>
              )}
              {isManager && !leaveApplication.approved && leaveApplication.archived && (
                <DropdownMenuItem
                  onSelect={() =>
                    updateLeave(leaveApplication.id, {
                      ...leaveApplication,
                      archived: false,
                    })
                  }
                >
                  <FontAwesomeIcon icon="trash-restore" /> Undecline
                </DropdownMenuItem>
              )}
            </DropdownMenuPortal>
          </DropdownMenuRoot>
        </td>
        <td colSpan={8 - startDateOffset - spanningDays} />
      </tr>
    );
  } else {
    return null;
  }
}
