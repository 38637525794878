// https://github.com/jkroso/parse-duration/blob/master/index.js
const duration = /(-?(?:\d+\.?\d*|\d*\.?\d+)(?:e[-+]?\d+)?)\s*([a-z]*)/gi;

/**
 * conversion ratios
 */
const ratios: any = {
  second: 1000,
  sec: 1000,
  s: 1000,
  minute: 1000 * 60,
  min: 1000 * 60,
  m: 1000 * 60,
  hour: 1000 * 60 * 60,
  hr: 1000 * 60 * 60,
  h: 1000 * 60 * 60,
};

/**
 * convert `str` to ms
 *
 * @param {String} str
 * @param {String} format
 * @return {Number}
 */
export function parseDuration(str = "", format = "s") {
  let result: number | null = null;
  // ignore commas
  str = str.replace(/(\d),(\d)/g, "$1$2");
  str.replace(duration, function (_, n, unitsMatch) {
    const units =
      ratios[unitsMatch] ||
      ratios[unitsMatch.toLowerCase().replace(/s$/, "")] ||
      // default to minutes
      ratios.minute;
    if (units) result = (result || 0) + parseFloat(n) * units;
    return "";
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return result && result / ratios[format];
}

function tests() {
  console.info("running tests");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  console.assert(10 == parseDuration("10s"), "10s");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  console.assert(10 * 60 == parseDuration("10m"), "10m");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  console.assert(60 * 60 + 1 * 60 == parseDuration("1h 1m"), "1h 1m");
}
