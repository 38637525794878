import { formatISO } from "date-fns";
import React from "react";
import { useFetchEmployeesQuery } from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { useFetchEmployeeAvailabilityQuery } from "store/reducers/unavailableEvent/unavailableEventApi";

import NewUnavailableEvent from "./new_unavailable_event";
import UnavailableEvents from "./unavailable_events_table";

const date = new Date();

const Availability = () => {
  const { data: employeeAvailability } = useFetchEmployeeAvailabilityQuery({
    date: formatISO(date, { representation: "date" }),
  });
  const { data: configData } = useGetConfigQuery();
  const { data: employees, isSuccess: isSuccessEmployees } = useFetchEmployeesQuery(undefined);

  React.useEffect(() => {
    document.title = "Availability | RadPay";
  });

  if (!isSuccessEmployees) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mb-3">
      <div className="container mx-auto py-10">
        <div className="mt-3">
          <NewUnavailableEvent employees={employees.employees} />
        </div>
        <div className="mt-2">
          <UnavailableEvents employeeAvailability={employeeAvailability} employees={employees.employees} />
        </div>
      </div>
    </div>
  );
};

export default Availability;
