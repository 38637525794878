import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  useClockOffMutation,
  useClockOnMutation,
  useEndBreakMutation,
  useStartBreakMutation,
  useStartTravelMutation,
} from "store/reducers/attendance";
import {
  goBackNow,
  selectClockResponse,
  selectLastClockAction,
  selectPinResponse,
} from "store/reducers/attendance/attendanceSlice";
import { useFetchLocationsQuery } from "store/reducers/location/locationAPI";

import ClockLocation from "./clock_location";
import EarningTypes from "./earning_types";
import { ForgotToClockOff } from "./ForgotToClockOff";
import { PreviousShift } from "./previous_shift";
import { Button } from "../components/button";
import { SelectDropDown } from "../components/selectDropDown";
import { Duration } from "../timesheet_viewer/table_components/duration";

export default function Clock() {
  const dispatch = useAppDispatch();
  const [earningsRate, setEarningsRate] = React.useState<any>();
  const [location, setLocation] = React.useState<LocationSchema | null>();

  const pinResponse = useAppSelector(selectPinResponse);
  const lastClockAction = useAppSelector(selectLastClockAction);
  const clockResponse = useAppSelector(selectClockResponse);
  const [clockOn, { isLoading: isClockOnLoading }] = useClockOnMutation();
  const [startBreak] = useStartBreakMutation();
  const [clockOff] = useClockOffMutation();
  const [startTravel] = useStartTravelMutation();
  const [endBreak] = useEndBreakMutation();

  const [travelToLocation, setTravelToLocation] = React.useState<LocationSchema>();
  const [startRecordingTravel, setStartRecordingTravel] = React.useState<boolean>(false);
  const { data: locations, isSuccess: isSuccessLocations } = useFetchLocationsQuery();

  const defaultLocation = React.useMemo(() => {
    if (!pinResponse || !locations) return null;
    let location = locations[0]; // Default to the first location
    if (pinResponse.guessed_location) {
      return locations.find((l) => l.id === pinResponse.guessed_location?.id) || location;
    } else if (pinResponse.current_location) {
      return locations.find((l) => l.id === pinResponse.current_location?.id) || location;
    } else if (pinResponse.last_shift?.location) {
      return locations.find((l) => l.id === pinResponse.last_shift?.location?.id) || location;
    }
    return location;
  }, [locations, pinResponse]);

  React.useEffect(() => {
    setLocation(defaultLocation);
    if (defaultLocation) {
      setTravelToLocation(defaultLocation);
    }
  }, [defaultLocation]);

  if (!pinResponse) {
    return null;
  }

  const unfinishedBreak = pinResponse.open_shift?.taken_breaks.find((b) => b.end == null);

  function onChangeEarningsType(e: any) {
    let value = e.target.value;
    setEarningsRate(value);
  }

  function onChangeLocation(e: any) {
    if (!locations) throw new Error("Locations not loaded");
    if (e.target.value === "") {
      setLocation(null);
    } else {
      let workedSite = locations.find((site) => site.name === e.target.value);
      setLocation(workedSite);
    }
  }

  let onChangeTravelToLocation: React.ComponentProps<"select">["onChange"] = function (e) {
    if (!locations) throw new Error("Locations not loaded");
    let location = locations.find((location) => location.name === e.target.value);
    setTravelToLocation(location);
  };

  // Location 7 is remote. No use warning in that case.
  const warnRemote = !pinResponse.ip_is_onsite && location?.id !== 7;

  if (!isSuccessLocations) return null;

  return (
    <>
      {(!lastClockAction || lastClockAction === "archive") && (
        <div className="flex flex-col">
          <div className="flex items-center mb-4">
            <span className="text-primary text-4xl font-bold mr-2">{pinResponse.employee.name}</span>{" "}
            <button
              className="bg-danger hover:bg-danger-dark text-white font-bold py-1 px-2 rounded text-sm"
              onClick={() => dispatch(goBackNow())}
            >
              Not me (go back)
            </button>
          </div>
          {lastClockAction === "archive" && (
            <span className="text-primary text-2xl font-bold mb-2">Shift was archived.</span>
          )}
          {warnRemote && (
            <div className="rounded bg-danger-light p-3 mb-4">
              Clock On at <span className="font-semibold">{location?.name}</span> is restricted to approved devices
              only.
            </div>
          )}
          {!pinResponse.open_shift && (
            <>
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {!locations && "Loading..."}
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {locations && (
                <>
                  {pinResponse.employee.earnings_vocational && (
                    <EarningTypes onChangeEarningsType={onChangeEarningsType} earningsRate={earningsRate} />
                  )}
                  <ClockLocation location={location} onChangeLocation={onChangeLocation} />
                  <div className="mb-4">
                    <Button
                      disabled={isClockOnLoading || warnRemote}
                      colour="accent"
                      size="lg"
                      onClick={() =>
                        clockOn({
                          employee: pinResponse.employee,
                          location: location,
                          earningsRate: earningsRate,
                        })
                      }
                      autoFocus
                    >
                      Clock On
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          {pinResponse.open_shift && pinResponse.still_valid === false && (
            <ForgotToClockOff open_shift={pinResponse.open_shift} />
          )}
          {startRecordingTravel && (
            <div className="flex flex-col mb-6">
              <SelectDropDown
                showLabel="Travel To"
                value={travelToLocation && travelToLocation.name}
                onChange={onChangeTravelToLocation}
              >
                {locations.map((location) => {
                  return <option key={location.id}>{location.name}</option>;
                })}
              </SelectDropDown>
            </div>
          )}
          {pinResponse.open_shift && pinResponse.still_valid !== false && !startRecordingTravel && !unfinishedBreak && (
            <div className="flex">
              <Button
                disabled={warnRemote}
                onClick={() => pinResponse.open_shift && startBreak({ open_shift: pinResponse.open_shift })}
                colour="accent"
                className="flex-1 mr-2"
                autoFocus
              >
                <FontAwesomeIcon icon="coffee" /> Start Break
              </Button>
              <Button
                disabled={warnRemote}
                onClick={() => setStartRecordingTravel(true)}
                colour="accent"
                className="flex-1 mr-2"
              >
                <FontAwesomeIcon icon="car-side" /> Record Travel
              </Button>
              <Button
                disabled={warnRemote}
                onClick={() => pinResponse.open_shift && clockOff({ open_shift: pinResponse.open_shift })}
                colour="danger"
                className="flex-1"
              >
                <FontAwesomeIcon icon="power-off" /> Clock Off
              </Button>
            </div>
          )}
          {pinResponse.open_shift && pinResponse.still_valid !== false && startRecordingTravel && !unfinishedBreak && (
            <>
              <div className="flex items-center mb-2 text-warning-light">
                <FontAwesomeIcon icon="exclamation-circle" className="mr-2" />
                Only record travel if you are using your own car.
              </div>
              <div className="flex justify-between">
                <Button colour="accent" size="lg" onClick={() => setStartRecordingTravel(false)}>
                  <FontAwesomeIcon icon="arrow-left" /> Back
                </Button>
                <Button
                  colour="accent"
                  size="lg"
                  onClick={() => {
                    if (travelToLocation) {
                      startTravel({
                        employee_id: pinResponse.employee.id,
                        travel_to_location_id: travelToLocation.id,
                        travel_start_time: new Date(),
                      });
                    }
                  }}
                  className="ml-2"
                  autoFocus
                  disabled={!travelToLocation}
                >
                  <FontAwesomeIcon icon="car-side" /> Start Travel
                </Button>
              </div>
            </>
          )}
          {pinResponse.open_shift && pinResponse.still_valid !== false && unfinishedBreak && (
            <div>
              <button
                onClick={() => endBreak({ unfinishedBreak })}
                className="bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded"
                autoFocus
              >
                End Break
              </button>
            </div>
          )}
        </div>
      )}
      {lastClockAction && lastClockAction !== "archive" && (
        <div>
          {lastClockAction === "start_break" && (
            <div className="text-primary text-4xl font-bold mb-4">Started Break</div>
          )}
          {lastClockAction === "end_break" && clockResponse && "taken_break_id" in clockResponse && (
            <div>
              <div className="text-primary text-4xl font-bold mb-4">Ended Break</div>
              <div className="flex flex-col bg-primary-lightest text-primary font-semibold p-4 rounded-sm mb-4">
                <span className="text-lg">Break time: </span>
                <span className="text-2xl text-accent">
                  <Duration seconds={clockResponse.duration} showZeroHours={false} />
                </span>
              </div>
            </div>
          )}

          {lastClockAction === "clock_off" && clockResponse && "worked_shift_id" in clockResponse && (
            <>
              <div className="text-primary text-4xl font-bold mb-4">Clocked off</div>
              <div className="flex flex-col bg-primary-lightest text-primary p-4 rounded-sm mb-4 font-semibold">
                <span className="text-lg">Total working time: </span>
                <span className="text-2xl text-accent">
                  <Duration seconds={clockResponse.total_worked_duration} />
                </span>
                {clockResponse.taken_breaks.length > 0 && (
                  <>
                    <span className="text-lg">Total break: </span>
                    <span className="text-2xl text-accent">
                      <Duration seconds={clockResponse.total_break_duration} />
                    </span>
                  </>
                )}
              </div>
            </>
          )}
          {lastClockAction === "clock_on" && (
            <>
              <div className="text-primary text-4xl font-bold mb-4">Clocked on</div>
              <PreviousShift pinResponse={pinResponse} />
            </>
          )}
          {lastClockAction === "start_travel" && (
            <div className="text-primary text-4xl font-bold mb-4">Travel recorded</div>
          )}
          <div>
            <button
              onClick={() => dispatch(goBackNow())}
              className="bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded"
              autoFocus
            >
              Done, Go back
            </button>
          </div>
        </div>
      )}
    </>
  );
}
