import * as HoverCard from "@radix-ui/react-hover-card";
import React from "react";

// Root component
const HoverCardRoot: React.FC<React.ComponentPropsWithoutRef<typeof HoverCard.Root>> = (props) => (
  <HoverCard.Root {...props} />
);

// Trigger component
const HoverCardTrigger = React.forwardRef<
  React.ElementRef<typeof HoverCard.Trigger>,
  React.ComponentPropsWithoutRef<typeof HoverCard.Trigger>
>((props, ref) => <HoverCard.Trigger {...props} ref={ref} />);
HoverCardTrigger.displayName = "HoverCardTrigger";

// Portal component
const HoverCardPortal: React.FC<React.ComponentPropsWithoutRef<typeof HoverCard.Portal>> = (props) => (
  <HoverCard.Portal {...props} />
);

// Content component
const HoverCardContent = React.forwardRef<
  React.ElementRef<typeof HoverCard.Content>,
  React.ComponentPropsWithoutRef<typeof HoverCard.Content>
>((props, ref) => (
  <HoverCard.Content
    {...props}
    ref={ref}
    className="bg-white rounded-lg shadow-lg p-4 w-auto min-w-[200px] max-w-[300px] max-h-[var(--radix-hover-card-content-available-height)] break-words"
  />
));
HoverCardContent.displayName = "HoverCardContent";

// Arrow component
const HoverCardArrow = React.forwardRef<
  React.ElementRef<typeof HoverCard.Arrow>,
  React.ComponentPropsWithoutRef<typeof HoverCard.Arrow>
>((props, ref) => <HoverCard.Arrow {...props} ref={ref} className="fill-white" />);
HoverCardArrow.displayName = "HoverCardArrow";

export { HoverCardRoot, HoverCardTrigger, HoverCardPortal, HoverCardContent, HoverCardArrow };
