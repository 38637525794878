import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

import { EMPLOYEE_ID_QUERY_PARAM } from "../timesheet_viewer/useStartDate";

type Props = {
  employee: Employee;
};
export function EmployeeLink(props: Props) {
  const { employee } = props;

  function getTimesheetLink() {
    const params = new URLSearchParams();
    params.append(EMPLOYEE_ID_QUERY_PARAM, String(employee.id));
    return params.toString();
  }

  const timesheetLink = React.useMemo(getTimesheetLink, [employee]);

  return (
    <Link
      to={{
        pathname: "/timesheets",
        search: timesheetLink,
      }}
    >
      <FontAwesomeIcon icon="external-link-alt" />
    </Link>
  );
}
