import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FetchShiftsResponse, approveWhyToMessage } from "store/reducers";

type Props = {
  worked_shift: WorkedShift;
  workedShiftData: FetchShiftsResponse;
  [k: string]: any;
};

const ShiftStatus = ({ worked_shift, workedShiftData }: Props) => {
  let status;
  let approveWhyList =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    worked_shift && workedShiftData.to_be_approved[worked_shift.id];
  if (worked_shift.status_type === "attendance_created") {
    status = (
      <span className="cursor-pointer font-medium text-success" title="Created by clock in">
        A
      </span>
    );
  } else if (worked_shift.status_type === "admin_created") {
    status = (
      <span className="cursor-pointer font-medium text-accent-dark" title="Created by manager">
        M
      </span>
    );
  } else if (worked_shift.status_type === "script_created") {
    status = (
      <span className="cursor-pointer font-medium text-warning" title="Created by a script or imported">
        ?
      </span>
    );
  } else if (
    worked_shift.status_type === "admin_edited" ||
    worked_shift.status_type === "attendance_created-admin_edited"
  ) {
    status = (
      <span className="cursor-pointer font-medium text-warning" title="Created by clock in but edited by manager">
        E
      </span>
    );
  } else if (worked_shift.status_type === "employee_created-admin_edited") {
    status = (
      <span className="cursor-pointer font-medium text-warning" title="Created by employee but edited by manager">
        E
      </span>
    );
  }
  return (
    <>
      {approveWhyList && (
        <FontAwesomeIcon
          icon="exclamation-circle"
          className="text-danger"
          title={approveWhyList.map((why) => approveWhyToMessage[why]).join("\n")}
        />
      )}{" "}
      {status}
    </>
  );
};

export default ShiftStatus;
