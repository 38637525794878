import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import HoverEmployeeNotesButton from "components/HoverEmployeeNotesButton";
import { formatISO } from "date-fns";
import { useEmployeeNotesModal } from "helpers/employeeNotes";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectAllRrUsers } from "store/reducers";

import { RoleFilter } from "./employee_filter";
import { makeLocationPreserveParams } from "./useStartDate";
import { useExpandMaxHeight } from "../helpers/useExpandMaxHeight";
import { useHandleResponseError } from "../helpers/useHandleResponseError";

function LeaveRequestsIcon({
  employee,
  approvalShiftsAmountList,
}: {
  employee: Employee;
  approvalShiftsAmountList?: ApprovalShiftsAmount;
}) {
  const totalRequests = approvalShiftsAmountList?.[employee.id]?.leave;
  const pendingRequests = approvalShiftsAmountList?.[employee.id]?.leave_pending;
  let className = "";
  if (totalRequests != null && pendingRequests != null) {
    if (totalRequests > 0) {
      if (pendingRequests === 0) {
        className = "text-success";
      } else if (pendingRequests > 0) {
        className = "text-danger";
      }
      if (className) {
        return (
          <div className="flex items-center">
            <FontAwesomeIcon
              icon="plane-departure"
              className={className}
              title={
                pendingRequests > 0
                  ? "There are leave requests to be approved for this employee"
                  : "This employee has approved leave requests"
              }
            />
          </div>
        );
      }
    }
  }
  return null;
}

type ApprovalShiftsAmount = {
  [k: string]: {
    shifts: number;
    leave: number;
    leave_pending: number;
  };
};

type Props = {
  roleFilter: RoleFilter[];
  searchItem: string;
  timesheetEmployee?: Employee;
  employees: Employee[];
  [k: string]: any;
};

let EmployeeList = (props: Props) => {
  let { startDate, searchItem, timesheetEmployee, roleFilter, employees } = props;
  let handleResponseError = useHandleResponseError();
  // key: employee_id, value: problematic shifts amount
  const [approvalShiftsAmountList, setApprovalShiftsAmountList] = React.useState<ApprovalShiftsAmount>();
  const location = useLocation();
  const rrUsers = useAppSelector(selectAllRrUsers);
  const listRef = React.useRef<any>();
  const { openEmployeeNotesModal } = useEmployeeNotesModal();

  React.useEffect(() => {
    async function fetchEmployeeIdsForApproval() {
      let response = await fetch("/api/fetch_employee_ids_for_approval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: formatISO(startDate, { representation: "date" }),
        }),
      });
      if (response.ok) {
        setApprovalShiftsAmountList(await response.json());
      } else {
        handleResponseError(response);
      }
    }

    if (!startDate) {
      return;
    }
    fetchEmployeeIdsForApproval();
  }, [startDate, handleResponseError]);

  function hasProblematicShifts(employee: Employee) {
    const shifts = approvalShiftsAmountList?.[employee.id]?.shifts;
    return shifts != null && shifts > 0;
  }

  function filterByName(employee: Employee) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (searchItem === null) return employee;
    else if (employee.name.toLowerCase().includes(searchItem.toLowerCase())) {
      return employee;
    }
    return undefined;
  }

  const listMaxHeight = useExpandMaxHeight(listRef);

  return (
    <div className="shadow bg-white left-0 rounded max-h-select overflow-y-auto mt-2">
      <div className="flex flex-col w-full overflow-y-auto" ref={listRef} style={{ maxHeight: listMaxHeight }}>
        {employees
          .filter((employee) => {
            const rrUser = rrUsers.find((u) => u.id === employee.radreport_user_id);
            return (
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (!roleFilter ||
                roleFilter.length === 0 ||
                roleFilter.some((f) => {
                  if (
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    employee.payroll_role == null ||
                    rrUser == null ||
                    rrUser.company_roles.length === 0
                  ) {
                    return false;
                  }
                  return rrUser.company_roles.some((companyRoleId) => f.includesCompanyRole(companyRoleId));
                })) &&
              filterByName(employee)
            );
          })
          .map((employee) => {
            let eLocation = makeLocationPreserveParams(location, {
              employee_id: employee.id,
            });
            return (
              <div key={employee.id} className="cursor-pointer w-full border-primary-lightest border-b">
                <Link
                  to={eLocation}
                  className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative text-primary-dark hover:bg-primary-lightest no-underline"
                >
                  <div className="w-full items-center flex justify-between">
                    <div
                      className={classNames("mx-2 -mt-1", {
                        "font-semibold": employee === timesheetEmployee,
                      })}
                    >
                      {employee.name}
                    </div>
                    <div className="flex">
                      {hasProblematicShifts(employee) && (
                        <div className="flex items-center mr-1">
                          <FontAwesomeIcon
                            icon="exclamation-circle"
                            className="text-danger"
                            title="There are shifts to be approved for this employee"
                          />
                        </div>
                      )}
                      <LeaveRequestsIcon employee={employee} approvalShiftsAmountList={approvalShiftsAmountList} />
                      <HoverEmployeeNotesButton
                        employeeId={employee.id}
                        onClick={() => openEmployeeNotesModal(employee.id)}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmployeeList;
