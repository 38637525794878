import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { skipToken } from "@reduxjs/toolkit/query";
import { format, add } from "date-fns";
import { useRosterQueryParams } from "helpers/roster";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { rosterApi, useFetchLocationsQuery } from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";
import { fetchDepartments } from "store/reducers/department/departmentAPI";
import { fetchRRUsers } from "store/reducers/rrUsers/rrUsersAPI";

import { MachineServicesTable } from "./machine-services-table";
import { PublishRosterModal } from "./publish_roster_modal";
import RosterMainTable from "./roster-main-table";
import RosterSideMenu from "./roster-side-menu";
import { RosterCopyForwardModal } from "./roster_copy_forward.modal";
import RosterNewShiftModal from "./roster_new_shift_modal";
import { Button } from "../components/button";
import { IconButton } from "../components/iconButton";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { useHandleResponseError } from "../helpers/useHandleResponseError";
import PayPeriod from "../timesheet_viewer/payperiod";
import { useStartDate } from "../timesheet_viewer/useStartDate";

export type OpenNewShiftModalFunction = (options: {
  date: Date | null;
  employee: Employee | null;
  rosteredShift?: RosteredShift;
}) => void;

export function RosterComponent() {
  React.useEffect(() => {
    document.title = "Roster | RadPay";
  });

  const location = useLocation();

  const handleResponseError = useHandleResponseError();

  const [startDate, endDate] = useStartDate(handleResponseError, 6);
  const [copyRosterModal, setCopyRosterModal] = React.useState<any>();
  const [rosterModal, setRosterModal] = React.useState<any>();
  const [employeeModal, setEmployeeModal] = React.useState<any>();
  const [publishRosterModal, setPublishRosterModal] = React.useState<any>();
  const [publishRoster, publishRosterResult] = rosterApi.usePublishRosterMutation({
    fixedCacheKey: "publishRoster",
  });

  const [showArchivedShifts, setShowArchivedShift] = React.useState<boolean>(false);

  const addNewEmployeeRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  const dispatch = useAppDispatch();
  const isManager = useAppSelector(selectIsManager);
  const { data: locations } = useFetchLocationsQuery();

  React.useEffect(() => {
    dispatch(fetchDepartments());
    dispatch(fetchRRUsers());
  }, [dispatch]);

  const rosterQueryParams = useRosterQueryParams();

  const rosterFetchAllQuery = rosterApi.useFetchAllQuery(rosterQueryParams ?? skipToken);

  const openConfirmationModal = () => {
    if (!startDate || !endDate || !rosterFetchAllQuery.isSuccess) return;
    const copyEmployees = rosterFetchAllQuery.data.employees;
    setCopyRosterModal(
      <RosterCopyForwardModal
        fromStartDate={startDate}
        fromEndDate={endDate}
        dismissModal={() => setCopyRosterModal(undefined)}
        copyEmployees={copyEmployees}
      />,
    );
  };

  function openPublishRosterModal() {
    if (startDate && endDate && rosterFetchAllQuery.isSuccess)
      setPublishRosterModal(
        <PublishRosterModal
          rosterFetchAllResponse={rosterFetchAllQuery.data}
          startDate={startDate}
          endDate={endDate}
          dismissModal={() => setPublishRosterModal(null)}
        />,
      );
  }

  let openNewShiftModal: OpenNewShiftModalFunction = (options: {
    date: Date | null;
    employee: Employee | null;
    rosteredShift?: RosteredShift;
  }) => {
    if (!rosterFetchAllQuery.isSuccess) throw new Error("Not loaded yet");
    const { date, employee, rosteredShift } = options;
    setRosterModal(
      <RosterNewShiftModal
        closeNewShiftModal={() => setRosterModal(undefined)}
        openNewShiftModal={openNewShiftModal}
        addShiftDate={date}
        addShiftEmployee={employee}
        rosteredShift={rosteredShift}
        rosterFetchAllResponse={rosterFetchAllQuery.data}
      />,
    );
  };

  if (!rosterFetchAllQuery.isSuccess) {
    return null;
  }

  return (
    <div className="flex bg-background px-2 mx-auto">
      <RosterSideMenu startDate={startDate} endDate={endDate} rosterFetchAllData={rosterFetchAllQuery.data} />

      <div className="px-10 py-10 bg-background w-full">
        <span className="py-2 mt-2 text-xl font-semibold text-primary">Roster</span>
        <Link
          to={{
            ...location,
            pathname: "/roster/print",
          }}
        >
          <IconButton className="ml-2" icon={faPrint} />
        </Link>

        <div className="flex items-center">
          Pay period: <PayPeriod startDate={startDate} endDate={endDate} />
          {startDate && endDate && isManager && (
            <Button size="lg" colour="accent" onClick={() => openConfirmationModal()}>
              Copy forward to {format(add(endDate, { days: 1 }), "dd MMM yyyy")}
            </Button>
          )}
          {isManager && (
            <>
              <Button
                size="lg"
                colour="accent"
                className="ml-2"
                onClick={() =>
                  openNewShiftModal({
                    date: startDate,
                    employee: null,
                    rosteredShift: undefined,
                  })
                }
              >
                <FontAwesomeIcon icon="user-plus" /> Add New Employee
              </Button>

              <Button
                className="ml-2 flex align-center"
                colour="accent"
                disabled={publishRosterResult.isLoading}
                onClick={openPublishRosterModal}
              >
                {publishRosterResult.isLoading && (
                  <>
                    <LoadingSpinner /> Publishing
                  </>
                )}
                {!publishRosterResult.isLoading && "Publish"}
              </Button>
            </>
          )}
          {isManager && (
            <label className="block text-primary font-bold cursor-pointer ml-auto">
              <input
                className="mr-2 leading-tight cursor-pointer"
                type="checkbox"
                onChange={(e) => setShowArchivedShift(e.target.checked)}
                checked={showArchivedShifts}
              />
              <span>Show Archived Shifts</span>
            </label>
          )}
        </div>
        {isManager && rosterFetchAllQuery.data.machine_unavailables.length > 0 && (
          <div className="mb-2">
            <MachineServicesTable rosterFetchAllData={rosterFetchAllQuery.data} />
          </div>
        )}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {rosterFetchAllQuery.isSuccess && startDate && endDate && (
          <RosterMainTable
            startDate={startDate}
            endDate={endDate}
            rosterFetchAllData={rosterFetchAllQuery.data}
            addNewEmployeeRef={addNewEmployeeRef}
            openNewShiftModal={openNewShiftModal}
            showArchivedShifts={showArchivedShifts}
          />
        )}
      </div>
      {rosterModal}
      {employeeModal}
      {copyRosterModal}
      {publishRosterModal}
    </div>
  );
}
