import classNames from "classnames";
import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { EmployeeFormValues } from "./EmployeeProfile";

export const EmployeeInput = ({
  label,
  name,
  required = false,
  register,
  errors,
  type = "text",
  ...otherProps // disabled etc
}: {
  label: string;
  name: keyof EmployeeFormValues;
  required?: boolean;
  register: UseFormRegister<EmployeeFormValues>;
  errors: FieldErrors<EmployeeFormValues>;
} & React.ComponentPropsWithoutRef<"input">) => {
  return (
    <label className="block">
      {label}
      <input
        className={classNames(
          "mt-1 block w-full px-3 py-2 border border-primary-light rounded focus:outline-none focus:ring",
          { "border-danger": errors[name] },
        )}
        {...register(name, { required })}
        type={type}
        {...otherProps}
      />
    </label>
  );
};
EmployeeInput.displayName = "EmployeeInput";
