import React from "react";

import LeaveApplicationModal from "./leave_application_modal";

export function RequestLeave() {
  const [leaveApplicationModal, setLeaveApplicationModal] = React.useState<any>();

  function openLeaveApplicationModal() {
    setLeaveApplicationModal(<LeaveApplicationModal closeLeaveApplicationModal={closeLeaveApplicationModal} />);
  }

  function closeLeaveApplicationModal() {
    setLeaveApplicationModal(undefined);
  }

  return (
    <>
      <button
        onClick={() => openLeaveApplicationModal()}
        className="bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring"
      >
        Apply for Leave
      </button>
      {leaveApplicationModal}
    </>
  );
}
