// configApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type ConfigData = {
  currentEmployee?: Employee;
  SENTRY_CLIENT_DSN: string;
  TEAMUP_READ_CALENDAR_KEY: string;
  debug: boolean;
};

export const configApi = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigData, void>({
      query: () => "/api/config",
    }),
  }),
});

export const { useGetConfigQuery } = configApi;
