import classNames from "classnames";
import React from "react";
import { useDrop } from "react-dnd";
import { useAppSelector } from "store/hooks";
import { selectIsManager } from "store/reducers/config/configSlice";

import { OpenNewShiftModalFunction } from "./RosterComponent";
import { RosterDragObject, RosterShift } from "./RosterShift";
import { IconButton } from "../components/iconButton";

export type RosterDropResult = {
  date: Date;
};

type Props = {
  rostered_shift: RosteredShift[];
  employee: Employee;
  openNewShiftModal: OpenNewShiftModalFunction;
  date: Date;
};

export function RosterShiftCell(props: Props) {
  const { rostered_shift, employee, openNewShiftModal, date } = props;
  const isManager = useAppSelector(selectIsManager);

  const [, drop] = useDrop<RosterDragObject, RosterDropResult>({
    accept: "rostered_shift",
    drop: () => {
      return {
        date,
      };
    },
  });

  return (
    <td
      className={classNames("roster-table-shift-cell", {
        "cursor-pointer hover:bg-primary-light": isManager,
      })}
      onClick={(event) => {
        if (isManager) {
          event.stopPropagation();
          openNewShiftModal({
            date,
            employee,
            rosteredShift: undefined,
          });
        }
      }}
      ref={drop}
    >
      {isManager && !rostered_shift.length && (
        <IconButton
          colour="primary"
          icon="plus"
          className="w-full h-full p-4 hover:bg-primary-light absolute inset-0"
        />
      )}

      {rostered_shift.map((shift) => {
        return <RosterShift key={shift.id} shift={shift} openNewShiftModal={openNewShiftModal} date={date} />;
      })}
    </td>
  );
}
