import classNames from "classnames";
import React from "react";

import Duration from "./duration";
import { ShiftOrTransient } from "./shift_row";
import { IconButton } from "../../components/iconButton";
import { parseDuration } from "../../helpers/parseDuration";

type Props = {
  onEnter: (duration: number | null) => void;
} & ShiftOrTransient;

export const DurationInput = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { shiftObj, workedShift, transientShift, onEnter } = props;
  const [editing, setEditing] = React.useState(false);
  const [duration, setDuration] = React.useState<number | null>(null);
  const [inputValue, setInputValue] = React.useState<string>("");
  const archived = workedShift && workedShift.archived;
  let inputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    // Syncs `shift` to `duration`
    // Initial value for duration
    // And after you click revert shift changes and we need to read the new `total_break_duration`
    setChanged(false);
    if (shiftObj) {
      setDuration(
        workedShift.break_duration ??
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (workedShift.taken_breaks && workedShift.taken_breaks.length > 0 ? shiftObj.total_break_duration : null),
      );
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (transientShift) {
      setDuration(null);
    }
  }, [shiftObj, workedShift]);

  React.useEffect(() => {
    // Syncs `duration` to `inputValue`
    if (duration != null) {
      setInputValue(String(duration / 60));
    } else {
      setInputValue("");
    }
  }, [duration]);

  React.useEffect(() => {
    if (!editing && changed) {
      // changed is so that that this isn't called when the initial value of `duration` is set by the hook
      onEnter(duration);
    }
  }, [editing, duration, changed]);

  React.useEffect(() => {
    if (editing) {
      inputRef.current.select();
    }
  }, [editing]);

  function finalise() {
    if (inputValue == "") {
      setDuration(null);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      let value = parseDuration(inputValue) ?? duration;
      setDuration(value);
    }
    setEditing(false);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      finalise();
    }
  }

  function onBlur(_event: any) {
    finalise();
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setChanged(true);
    setInputValue(event.target.value);
  }

  function revertBreak() {
    setChanged(true);
    setDuration(null);
  }

  return (
    <div ref={ref} className={"flex flex-row p-2 h-full"}>
      {editing && (
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          autoFocus
          className="block appearance-none w-full bg-white border border-primary hover:border-primary-dark px-4 py-2 rounded shadow leading-tight focus:outline-none"
        />
      )}
      {!editing && (
        <div className="flex items-center w-full">
          <button
            className={classNames("h-full flex-grow text-center", {
              "border rounded border-primary-light": !archived,
              "line-through": archived,
            })}
            disabled={archived}
            onClick={() => !archived && setEditing(true)}
            onFocus={() => !archived && setEditing(true)}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {duration != null && <Duration seconds={duration ?? 0} />}
            {duration == null &&
              shiftObj &&
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              shiftObj.total_break_duration != null && <Duration seconds={shiftObj.total_break_duration} />}
          </button>
          {!archived && shiftObj && workedShift.break_duration !== null && shiftObj.taken_breaks.length > 0 && (
            <IconButton
              icon="history"
              colour="accent"
              className="ml-1"
              title="Revert to the original break duration"
              onClick={() => revertBreak()}
            />
          )}
        </div>
      )}
    </div>
  );
});

DurationInput.displayName = "DurationInput";

export default DurationInput;
