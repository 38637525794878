import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";

import { PinResponse, attendanceApi } from "./attendanceAPI";
import { ShiftObj, TakenBreakObj } from "../shift/shiftApi";

export type ClockAction = "clock_on" | "clock_off" | "start_break" | "end_break" | "archive" | "start_travel";

interface AttendanceState {
  pinResponse: PinResponse | null;
  lastClockAction: ClockAction | null;
  clockResponse: ShiftObj | TakenBreakObj | TravelEvent | WorkedShift | null;
}

const initialState: AttendanceState = {
  pinResponse: null,
  lastClockAction: null,
  clockResponse: null,
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    goBackNow: (state) => {
      state.lastClockAction = null;
      state.clockResponse = null;
      state.pinResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(attendanceApi.endpoints.submitPin.matchFulfilled, (state, action) => {
      state.pinResponse = action.payload;
    });
    builder.addMatcher(attendanceApi.endpoints.refresh.matchFulfilled, (state, action) => {
      state.pinResponse = action.payload;
    });
    builder.addMatcher(attendanceApi.endpoints.clockOn.matchFulfilled, (state, action) => {
      state.lastClockAction = "clock_on";
      state.clockResponse = action.payload;
    });
    builder.addMatcher(attendanceApi.endpoints.clockOff.matchFulfilled, (state, action) => {
      state.lastClockAction = "clock_off";
      state.clockResponse = action.payload;
    });
    builder.addMatcher(attendanceApi.endpoints.startBreak.matchFulfilled, (state, action) => {
      state.lastClockAction = "start_break";
      state.clockResponse = action.payload;
    });
    builder.addMatcher(attendanceApi.endpoints.endBreak.matchFulfilled, (state, action) => {
      state.lastClockAction = "end_break";
      state.clockResponse = action.payload;
    });
    builder.addMatcher(attendanceApi.endpoints.startTravel.matchFulfilled, (state, action) => {
      state.lastClockAction = "start_travel";
      state.clockResponse = action.payload;
    });
    builder.addMatcher(attendanceApi.endpoints.archiveShift.matchFulfilled, (state, action) => {
      state.lastClockAction = "archive";
      state.clockResponse = action.payload;
    });
  },
});

export const { goBackNow } = attendanceSlice.actions;

export const selectLastClockAction = (state: RootState) => state.attendance.lastClockAction;
export const selectClockResponse = (state: RootState) => state.attendance.clockResponse;

export const selectPinResponse = (state: RootState) => state.attendance.pinResponse;

export { attendanceSlice };
