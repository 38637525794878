import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FetchAllRosteredShiftsResponse, GROUP_BY } from "store/reducers/rosteredShift";

import { useRosterParams } from "./useRosterParams";
import { EmployeeFilterSelect } from "../timesheet_viewer/employee_filter";

const RosterSideMenu = ({
  rosterFetchAllData,
}: {
  startDate: Date | null;
  endDate: Date | null;
  rosterFetchAllData: FetchAllRosteredShiftsResponse;
}) => {
  const location = useLocation();

  const [{ g: groupBy }, setRosterParams, { companyRoleFilter }] = useRosterParams();

  function onClickRadio(event: any) {
    setRosterParams({ g: event.target.value });
  }

  React.useEffect(() => {
    const el = document.getElementById(location.hash.replace("#", ""));
    if (el) {
      el.scrollIntoView();
    }
  }, [location.hash]);

  return (
    <>
      <div className="fixed top-0 w-64 flex flex-col mt-10" style={{ maxHeight: "calc(100vh - 100px)", top: "70px" }}>
        <div className="shadow bg-white left-0 rounded max-h-select overflow-y-auto">
          <div className="flex flex-col w-full h-full px-4 py-8">
            <div className="mb-4">
              <EmployeeFilterSelect
                roleFilter={companyRoleFilter}
                onChangeFilter={(f) => {
                  setRosterParams({ roles: f.map((r) => r.value) });
                }}
              />
            </div>
            <div className="mb-4">
              <div className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Group by</div>
              {GROUP_BY.map((group) => (
                <label key={group.value} className="w-full block">
                  <input type="radio" checked={groupBy === group.value} value={group.value} onChange={onClickRadio} />{" "}
                  {group.label}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="shadow bg-white left-0 rounded max-h-select overflow-y-auto mt-3">
          <div className="flex flex-col w-full h-full px-4 py-8">
            <div className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Jump to</div>
            {rosterFetchAllData.groups.map((group) => (
              <div className="pb-1" key={group.id}>
                <Link
                  className="block w-full text-left text-primary hover:text-primary-dark no-underline"
                  to={{
                    ...location,
                    hash: String(group.id),
                  }}
                  replace
                >
                  {group.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-64 flex-shrink-0">
        {/* TODO: hack. use variable for width of sidebar and left-margin of content? */}
      </div>
    </>
  );
};

export default RosterSideMenu;
