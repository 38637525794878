/**
 * Get the remaining space that an element can take to fill up the screen.
 * Useful for dropdowns or lists with scrollbars.
 *
 */
export function useExpandMaxHeight(elRef: any) {
  function fixMaxHeight() {
    if (!elRef.current) {
      return 100; // start it at a sensible height just incase
    }
    const MAX_HEIGHT = window.innerHeight - elRef.current.getBoundingClientRect().top;
    return MAX_HEIGHT;
  }

  const listMaxHeight = fixMaxHeight();
  return listMaxHeight;
}
