import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export type RosteredShiftGroup = {
  id: number | string;
  name: string;
  employees: number[];
};

export type AllEmployeeRosteredShifts = {
  employee_id: number;
  rostered_shift_ids: number[];
  leave_application_ids: number[];
  unavailable_event_ids: number[];
};

export type FetchAllRosteredShiftsResponse = {
  groups: RosteredShiftGroup[];

  all_employee_rostered_shifts: AllEmployeeRosteredShifts[];
  employees: Employee[];
  rostered_shifts: RosteredShift[];
  leave_applications: LeaveApplication[];
  leave_periods: LeavePeriod[];
  unavailable_events: UnavailableEvent[];

  machine_unavailables: MachineUnavailable[];
  machines: Machine[];
};

export type RosterGroupBy = "location" | "department" | "alphabetical";

type GroupByOption = {
  label: string;
  value: RosterGroupBy;
};

export const GROUP_BY = [
  {
    label: "Alphabetical",
    value: "alphabetical",
  },
  {
    label: "Location",
    value: "location",
  },
  {
    label: "Department",
    value: "department",
  },
] as const satisfies GroupByOption[];

export type FetchAllRosteredShiftBody = {
  startDate: string;
  endDate: string;
  rrUserIds: number[] | null;
  groupBy: RosterGroupBy;
};

type CreateRosteredShift = {
  body: Partial<RosteredShift> & {
    rostered_break: RosteredBreak | null;
  };
};

type UpdateRosteredShift = {
  rosteredShiftId: number;
  body: Partial<RosteredShift> & {
    rostered_break: RosteredBreak | null;
  };
};

type DeleteRosteredShift = {
  rosteredShiftId: number;
  // For updating the store
  employeeId: number;
};

type DeleteMultipleRosteredShifts = {
  body: {
    employee_id: number;
    start_date: string;
    end_date: string;
  };
};

type CopyRosterForwardResponse = {
  overlapping: RosteredShift[];
};

type CopyRosterForwardRequest = {
  body: {
    from_start_date: string;
    from_end_date: string;
    to_start_date: string;
    to_end_date: string;
    employee_ids: number[];
  };
};

type PrefillRowResponse = {
  roster_shifts: RosteredShift[];
};
type PrefillRowRequest = CreateRosteredShift;

type PublishRosterRequest = {
  body: {
    start: string;
    end: string;
    employee_ids: number[];
  };
};

// Define a service using a base URL and expected endpoints
const rosterApi = createApi({
  reducerPath: "rosterApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["RosteredShift"],

  endpoints: (builder) => ({
    fetchAll: builder.query<FetchAllRosteredShiftsResponse, FetchAllRosteredShiftBody>({
      query: (data) => ({
        url: "/api/fetch_all_rostered_shifts",
        method: "POST",
        body: {
          start_date: data.startDate,
          end_date: data.endDate,
          rr_user_ids: data.rrUserIds,
          group_by: data.groupBy,
        },
      }),
      providesTags: [
        {
          type: "RosteredShift",
          id: "LIST",
        },
      ],
    }),

    createRosteredShift: builder.mutation<RosteredShift, CreateRosteredShift>({
      query: ({ body }) => {
        return {
          url: "/api/rostered_shift",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        {
          type: "RosteredShift",
          id: "LIST",
        },
      ],
      // This is another way to update the store after a mutation. As an alternative to `builder.addMatcher` in rosteredShiftSlice.ts. However, doing it like this dispatches another action:

      // async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
      //   const res = await queryFulfilled;
      //   dispatch(rosteredShiftSlice.actions.upsertOne(res.data));
      // },
    }),

    updateRosteredShift: builder.mutation<RosteredShift, UpdateRosteredShift>({
      query: ({ rosteredShiftId, body }) => ({
        url: `/api/rostered_shift/${rosteredShiftId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteRosteredShift: builder.mutation<RosteredShift, DeleteRosteredShift>({
      query: ({ rosteredShiftId }) => ({
        url: `/api/rostered_shift/${rosteredShiftId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        {
          type: "RosteredShift",
          id: "LIST",
        },
      ],
    }),

    deleteMultipleRosteredShifts: builder.mutation<{ rostered_shifts: RosteredShift[] }, DeleteMultipleRosteredShifts>({
      query: ({ body }) => ({
        url: `/api/delete_multiple_roster_shifts`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: [
        {
          type: "RosteredShift",
          id: "LIST",
        },
      ],
    }),

    copyRosterForward: builder.mutation<CopyRosterForwardResponse, CopyRosterForwardRequest>({
      query: ({ body }) => ({
        url: `/api/roster/copy`,
        method: "POST",
        body,
      }),
    }),

    prefillRow: builder.mutation<PrefillRowResponse, PrefillRowRequest>({
      query: ({ body }) => ({
        url: `/api/prefill_roster_shift_row`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        {
          type: "RosteredShift",
          id: "LIST",
        },
      ],
    }),

    publishRoster: builder.mutation<void, PublishRosterRequest>({
      query: ({ body }) => ({
        url: `/api/rostered_shift/publish`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        {
          type: "RosteredShift",
          id: "LIST",
        },
      ],
    }),
  }),
});

export { rosterApi };
