import { differenceInYears, format } from "date-fns";
import React from "react";
import Select from "react-select";
import { useAppSelector } from "store/hooks";
import {
  XeroImportEmployee,
  selectAllEmployees,
  useUpdateEmployeeMutation,
  useXeroImportMutation,
} from "store/reducers";
import { useFetchOrganisationsQuery } from "store/reducers/organisation";

import EmployeeEdit from "./employee_edit";
import { Button } from "../components/button";

type EmployeeOption = {
  value: string;
  label: string;
  employee: Employee;
  isDisabled: boolean;
};

export function EmployeeImport({ e }: { e: XeroImportEmployee }) {
  const { data: orgStore } = useFetchOrganisationsQuery();

  const employees = useAppSelector(selectAllEmployees);

  const [xeroImport, xeroImportResult] = useXeroImportMutation();
  const [updateEmployee, updateEmployeeResult] = useUpdateEmployeeMutation();

  // Need to use the employee in the store so that EmployeeEdit updates are displayed
  const employeeFromStore = employees.find((e) => e.xero_EmployeeID === xeroImportResult.data?.xero_EmployeeID);
  const [selectedEmployee, setSelectedEmployee] = React.useState<EmployeeOption>();

  const { employee, xero_tenantId } = e;

  async function onClickImport() {
    await xeroImport({
      EmployeeID: employee.employee_id,
      xero_tenantId,
    });
  }

  async function onClickLink() {
    if (!selectedEmployee) return;
    await updateEmployee({
      employeeId: selectedEmployee.employee.id,
      body: {
        EmployeeID: employee.employee_id,
        tenantId: xero_tenantId,
      },
    });
  }

  let org = orgStore?.organisations.find((o) => o.xero_tenantId === xero_tenantId);

  let employeeOptions = employees
    .map((e) => {
      return {
        value: String(e.id),
        label: e.name,
        employee: e,
      } as EmployeeOption;
    })
    .sort((a, b) => {
      // sort disabled to bottom
      return Number(Boolean(a.employee.xero_EmployeeID)) - Number(Boolean(b.employee.xero_EmployeeID));
    });

  return (
    <div key={employee.employee_id}>
      {!employeeFromStore && (
        <div>
          <div>
            {employee.first_name} {employee.last_name}
          </div>
          {employee.date_of_birth && (
            <div>
              {format(new Date(employee.date_of_birth), "dd/MM/yyyy")} (
              {differenceInYears(new Date(), new Date(employee.date_of_birth))})
            </div>
          )}
          <div>{org?.xero_tenantName}</div>
          <Button colour="accent" onClick={onClickImport} disabled={!xeroImportResult.isUninitialized}>
            Import
          </Button>
          <div>
            Link to existing employee. <span className="text-danger">Red</span> means the employee is already linked, be
            careful.
          </div>
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {employeeOptions && (
            <div className="flex flex-row" style={{ maxWidth: 400 }}>
              <div className="flex-grow">
                <Select
                  styles={{
                    option: (provided, state) => {
                      const opacity = state.isDisabled ? 0.5 : 1;
                      return {
                        ...provided,
                        color: state.data.employee.xero_EmployeeID ? "red" : undefined,
                        opacity,
                      };
                    },
                  }}
                  onChange={(e) => setSelectedEmployee(e as EmployeeOption)}
                  value={selectedEmployee}
                  options={employeeOptions}
                  isClearable
                />
              </div>
              <Button
                className="ml-1"
                colour="accent"
                size="sm"
                disabled={updateEmployeeResult.isLoading}
                onClick={onClickLink}
              >
                Link
              </Button>
            </div>
          )}
        </div>
      )}
      {employeeFromStore && <EmployeeEdit employee={employeeFromStore} employees={employees} />}
    </div>
  );
}
