import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { sub, add, differenceInDays, isSameWeek } from "date-fns";
import format from "date-fns/format";
import React from "react";
import { useLocation, Link } from "react-router-dom";

import { makeLocationPreserveParams } from "./useStartDate";
import { Button } from "../components/button";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
};

/**
 * setStartDate:
 *  If a function is passed, don't change the router query params; invoke the callback instead.
 */
let PayPeriod = (props: Props) => {
  const { startDate, endDate, setStartDate } = props;
  const location = useLocation();

  const now = new Date();
  const activePeriod =
    startDate &&
    endDate &&
    (isSameWeek(startDate, now, { weekStartsOn: 1 }) || isSameWeek(endDate, now, { weekStartsOn: 1 }));

  function getNextDate(type: "PREV" | "NEXT") {
    if (!startDate || !endDate) {
      return startDate || undefined;
    }
    let newStart: Date | undefined;
    let days = differenceInDays(endDate, startDate) + 1;
    if (type === "PREV") {
      newStart = sub(startDate, { days });
    }

    if (type === "NEXT") {
      newStart = add(startDate, { days });
    }
    return newStart;
  }

  function getNextLink(type: "PREV" | "NEXT") {
    if (!startDate || !endDate) {
      return "/timesheets";
    }
    let newStart = getNextDate(type);
    let newLocation = makeLocationPreserveParams(location, {
      startDate: newStart,
    });
    return newLocation.pathname + `?${newLocation.search}`;
  }

  function getBgClasses() {
    return activePeriod ? "bg-danger hover:bg-danger-dark" : "bg-accent hover:bg-accent-dark";
  }

  return (
    <div className="text-base py-2 inline">
      <div className="inline-flex items-center">
        {!setStartDate && (
          <Link
            to={getNextLink("PREV")}
            className={classNames(
              "text-white font-bold py-2 px-4 rounded-l rounded-r-none focus:outline-none focus:ring",
              getBgClasses(),
            )}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </Link>
        )}
        {!setStartDate && (
          <Link
            to={getNextLink("NEXT")}
            className={classNames(
              "text-white font-bold py-2 px-4 rounded-r rounded-l-none focus:outline-none focus:ring",
              getBgClasses(),
            )}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </Link>
        )}
        {setStartDate && (
          <Button
            onClick={() =>
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              setStartDate && setStartDate(getNextDate("PREV") as Date)
            }
            colour={activePeriod ? "danger" : "accent"}
            className={classNames(
              "font-bold py-2 px-4 rounded-l rounded-r-none focus:outline-none focus:ring",
              getBgClasses(),
            )}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </Button>
        )}
        {setStartDate && (
          <Button
            onClick={() =>
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              setStartDate && setStartDate(getNextDate("NEXT") as Date)
            }
            colour={activePeriod ? "danger" : "accent"}
            className={classNames(
              "font-bold py-2 px-4 rounded-r rounded-l-none focus:outline-none focus:ring",
              getBgClasses(),
            )}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </Button>
        )}
        {startDate && endDate ? (
          <span
            className={classNames("font-semibold text-lg mx-1 ", {
              "text-danger": activePeriod,
              "text-primary-dark": !activePeriod,
            })}
          >
            {format(startDate, "dd MMM yyyy")} - {format(endDate, "dd MMM yyyy")}
          </span>
        ) : (
          <span className="mx-1 text-primary-dark font-semibold">Loading...</span>
        )}
        {/* <button className="bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded ml-2">
          <FontAwesomeIcon icon="calendar-alt" />
        </button> */}
      </div>
    </div>
  );
};
export default PayPeriod;
