import { LoadingSpinner } from "components/LoadingSpinner";
import { useEmployeeNotesModal } from "helpers/employeeNotes";
import React, { useEffect } from "react";
import { useUpdateEmployeeMutation } from "store/reducers";

import { Button } from "../components/button";
import { Modal } from "../components/modal";
import { TextArea } from "../components/textarea";

const EmployeeNotesModal = () => {
  const [updateEmployee, updateEmployeeResult] = useUpdateEmployeeMutation();
  const { employee, employeeNotesModalIsOpen, closeEmployeeNotesModal } = useEmployeeNotesModal();
  const [notes, setNotes] = React.useState("");

  useEffect(() => {
    if (employee) {
      setNotes(employee.notes || "");
    }
  }, [employee]);

  async function submitNotes() {
    if (employee) {
      await updateEmployee({
        employeeId: employee.id,
        body: { notes },
      });
    }
  }

  async function deleteNotes() {
    if (employee) {
      await updateEmployee({
        employeeId: employee.id,
        body: { notes: "" },
      });
    }
  }

  if (!employee) {
    return null;
  }

  return (
    <Modal isOpen={employeeNotesModalIsOpen} dismissModal={closeEmployeeNotesModal} title={`${employee.name} Notes`}>
      <div className="w-full rounded-lg bg-white">
        <div className="mb-4">
          <span className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Notes:</span>

          <TextArea
            name="Notes"
            placeholder="Message..."
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="mb-1"
            autoFocus
          />
          {updateEmployeeResult.isLoading ? (
            <LoadingSpinner className="self-center" />
          ) : (
            <div className="mt-2 flex gap-2">
              <Button colour="accent" onClick={() => submitNotes()}>
                {employee.notes ? "Update note" : "Submit note"}
              </Button>
              {employee.notes && notes && (
                <Button colour="danger" onClick={() => deleteNotes()}>
                  {employee.notes && "Delete note"}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeNotesModal;
