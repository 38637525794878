import { createSelector } from "@reduxjs/toolkit";
import { atLeastRole } from "helpers/roles";

import { configApi } from "./configAPI";

const selectConfig = configApi.endpoints.getConfig.select();

const selectTeamupReadCalendarKey = createSelector(
  [(state) => selectConfig(state).data],
  (config) => config?.TEAMUP_READ_CALENDAR_KEY,
);

const selectCurrentEmployee = createSelector(
  [(state) => selectConfig(state).data],
  (config) => config?.currentEmployee,
);

export const selectTeamupIframe = createSelector([selectTeamupReadCalendarKey], (key) => {
  if (!key) return null;
  return `https://teamup.com/${key}?view=mw&title=AdelaideMRI%20Leave%20Calendar&showProfileAndInfo=0&showSidepanel=1&showAgendaHeader=1&showAgendaDetails=0&showYearViewHeader=1`;
});

export const selectIsManager = createSelector([selectCurrentEmployee], (currentEmployee) => {
  if (!currentEmployee) return false;
  return atLeastRole(currentEmployee, "manager");
});
