import React from "react";
import { useAppSelector } from "store/hooks";
import { selectAllLocations } from "store/reducers/location/locationSlice";

import { SelectDropDown } from "../components/selectDropDown";

type Props = {
  location?: LocationSchema | null;
  onChangeLocation: any;
};

let ClockLocation = (props: Props) => {
  const { location, onChangeLocation } = props;
  const mriLocations = useAppSelector(selectAllLocations);

  return (
    <div className="flex flex-col mb-3">
      <SelectDropDown showLabel="Location" value={(location && location.name) || ""} onChange={onChangeLocation}>
        {mriLocations.map((site) => (
          <option key={site.id}>{site.name}</option>
        ))}
        <option value="">Unspecified</option>
      </SelectDropDown>
    </div>
  );
};

export default ClockLocation;
