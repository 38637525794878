import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
import { usePublishShiftsMutation } from "store/reducers/organisation";

import { Button } from "../components/button";
import { LoadingSpinner } from "../components/LoadingSpinner";

type PercentMessage = {
  state: "PROGRESS";
  message: string;
  progress?: number;
  total?: number;
};

type DoneMessage = {
  state: "DONE";
  message: string;
};

type ErrorMessage = {
  state: "ERROR";
  message: string;
  error: { [key: string]: any };
};

type ProgressMessage = DoneMessage | PercentMessage | ErrorMessage;

type Props = {
  startDate: Date;
  endDate: Date;
  organisation: Organisation;
  disabled?: boolean;
  title?: string;
};

export function PublishWorkedShifts(props: Props) {
  let { startDate, endDate, organisation, disabled, title } = props;
  const [publishShifts, { isLoading: isPublishing, error: publishError }] = usePublishShiftsMutation();
  const [hasPublishedShifts, setHasPublishedShifts] = React.useState<"success" | "error">();
  const [progressList, setProgressList] = React.useState<ProgressMessage[]>();
  const progressRef = React.useRef<HTMLDivElement | null>(null);

  const [ws, setWebSocket] = React.useState<WebSocket>();
  const WEBSOCKET_URL =
    // This variable is defined in .env.development
    import.meta.env.VITE_WEBSOCKET_URL || location.origin.replace("https", "wss").replace("http", "ws") + "/socket";
  React.useEffect(() => {
    // websocket cleanup
    return function () {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  function connectWs(task_id: string) {
    let ws = new WebSocket(WEBSOCKET_URL);
    setWebSocket(ws);
    ws.onerror = () => {
      toast("WebSocket error", {
        type: "error",
      });
    };
    ws.addEventListener("open", async function (_event) {
      setProgressList([]);
      ws.send(JSON.stringify({ task_id }));
    });

    ws.addEventListener("message", function (event) {
      if (event.data) {
        let data = JSON.parse(event.data);
        setProgressList((state) => (state ? [...state, data] : [data]));
        requestAnimationFrame(() => {
          if (progressRef.current) {
            progressRef.current.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
        });
        if (data.state === "DONE") {
          setHasPublishedShifts("success");
          ws.close();
        }
        if (data.state === "ERROR") {
          setHasPublishedShifts("error");
          ws.close();
        }
      }
    });
  }

  let onClickPublishShift = async () => {
    setHasPublishedShifts(undefined);
    const response = await publishShifts({
      organisation,
      startDate,
      endDate,
    }).unwrap();
    try {
      let task_id = response;
      connectWs(task_id);
    } catch (error) {
      const err = error as { status: number; data?: any };
      if (err.status === 500) {
        const json = err.data;
        if (json.Type) {
          toast(
            <div>
              <div>Xero {json.Type}</div>
              <div>{json.Message}</div>
            </div>,
            { type: "error" },
          );
        } else if (json.Title) {
          toast(
            <div>
              <div>{json.Title}</div>
              <div>{json.Detail}</div>
            </div>,
            { type: "error" },
          );
        }
      }
    }
  };

  return (
    <div className="py-1" ref={progressRef}>
      <Button
        disabled={disabled}
        title={title}
        onClick={onClickPublishShift}
        colour={hasPublishedShifts == "success" ? "success" : hasPublishedShifts == "error" ? "danger" : "accent"}
        className="flex flex-row items-center"
      >
        <FontAwesomeIcon icon="paper-plane" />
        <div className="ml-1">Publish {organisation.xero_tenantName} to Xero</div>
        {hasPublishedShifts == "success" && <FontAwesomeIcon icon="check" />}
        {progressList && !hasPublishedShifts && (
          <div className="ml-1 flex items-center">
            <LoadingSpinner />
          </div>
        )}
      </Button>

      {progressList && (
        <div className="my-1 px-3 py-2 bg-white border-primary border rounded list-disc text-primary-dark">
          {progressList.map((p, i) => (
            <div key={i}>
              {p.state === "PROGRESS" && (
                <>
                  {p.progress != null && (
                    <span>
                      {p.progress} / {p.total}
                    </span>
                  )}{" "}
                  {p.message}
                </>
              )}
              {p.state === "DONE" && <div className="text-success">Done</div>}
              {p.state === "ERROR" && (
                <div className="text-danger">
                  <pre>{JSON.stringify(p.error, null, 2)}</pre>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
