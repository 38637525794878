import Tippy from "@tippyjs/react";
import { format, isToday } from "date-fns";
import React from "react";
import { WeekDay } from "store/reducers";

import Duration from "./duration";
import { Button } from "../../components/button";
import { IconButton } from "../../components/iconButton";

type Props = {
  day: WeekDay;
  onClickCreate: React.ComponentProps<typeof Button>["onClick"];
  onClickLeave: React.ComponentProps<typeof Button>["onClick"];
  onClickTravel: React.ComponentProps<typeof Button>["onClick"];
};

export function DaySummaryRow({ day, onClickCreate, onClickLeave, onClickTravel }: Props) {
  const showHelp = day.auto_break != 0;

  const HelpPopoverContent = (
    <div>
      {day.auto_break > 0 && (
        <div>
          Subtracted <Duration showZeroHours={false} seconds={day.auto_break} /> automatic break
        </div>
      )}
    </div>
  );

  const rowStyles = isToday(new Date(day.date)) ? "bg-danger text-white" : "text-primary-dark";
  return (
    <tr key={"date-row-" + day.day} className={`whitespace-nowrap ${rowStyles}`}>
      <td className="ml-2 p-2 text-sm font-bold" colSpan={4}>
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {day && format(new Date(day.date), "EEE, dd MMM yyyy")}
      </td>
      <td className="text-center text-sm font-bold" colSpan={1}>
        {day.shifts.length > 0 && (
          <>
            <Duration seconds={day.paid_ordinary_duration} /> payable{" "}
            {showHelp && (
              <Tippy content={HelpPopoverContent}>
                <IconButton tabIndex={0} icon="question-circle" />
              </Tippy>
            )}
          </>
        )}
      </td>
      <td colSpan={1} />
      <td className="text-center text-sm font-bold" colSpan={1}>
        {day.shifts.length > 0 && (
          <>
            {day.travel_count} travel unit
            <IconButton
              icon="car-side"
              colour="accent"
              className="ml-2"
              onClick={onClickTravel}
              title="Click to edit travel count"
            />
          </>
        )}
      </td>
      <td className="bg-background text-center text-sm font-bold" colSpan={1}>
        <IconButton colour="accent" size="lg" onClick={onClickCreate} title="Create a new shift" icon="plus" />

        <IconButton
          colour="accent"
          size="lg"
          onClick={onClickLeave}
          title="Create a leave request"
          icon="plane-departure"
        />
      </td>
    </tr>
  );
}
