/* eslint-disable react/display-name */
import React from "react";
import { useLocation, useNavigate, To } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "store/hooks";
import { useGetConfigQuery } from "store/reducers";
import { useFetchLocationsQuery } from "store/reducers";
import { useRefreshMutation, useSubmitPinMutation } from "store/reducers/attendance";
import { selectPinResponse } from "store/reducers/attendance/attendanceSlice";

import ClockComponent from "../attendance/clock";
import { Button } from "../components/button";
import { useHandleResponseError } from "../helpers/useHandleResponseError";

function useInput({
  type,
  name,
  label,
  autoComplete = undefined,
}: {
  type: string;
  name: string;
  label: string;
  autoComplete?: string;
}) {
  const [value, setValue] = React.useState("");
  const input = (
    <label className="block text-primary text-sm font-bold mb-2">
      {label}
      <input
        className="shadow appearance-none border border-primary-light rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:ring"
        value={value}
        name={name}
        onChange={(e) => setValue(e.target.value)}
        type={type}
        autoComplete={autoComplete}
      />
    </label>
  );
  return [value, setValue, input] as const;
}

export const EMPLOYEE_TOKEN = "employee_token";

export default () => {
  React.useEffect(() => {
    document.title = "Login | RadPay";
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [refresh] = useRefreshMutation();
  let handleResponseError = useHandleResponseError();

  const [refreshingToken, setRefreshingToken] = React.useState(false);

  const [submitPin] = useSubmitPinMutation();
  const pinResponse = useAppSelector(selectPinResponse);
  const getConfigQuery = useGetConfigQuery();
  // Preload locations for clock.tsx
  useFetchLocationsQuery();

  async function handleToken() {
    // This was for when RR authorised with initials and redirected
    // directly to a working "Clock on" button in RadPay.
    // Consider removing this because RR can't authorise with initials anymore.
    const params = new URLSearchParams(location.search);
    const employee_token = params.get(EMPLOYEE_TOKEN);
    // eg: http://localhost:5000?employee_token=xxx-xxx
    if (employee_token) {
      // remove the employee_token
      params.delete(EMPLOYEE_TOKEN);
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
      setRefreshingToken(true);
      await refresh(employee_token);
      setRefreshingToken(false);
    }
  }

  React.useEffect(() => {
    handleToken();
  }, []);

  let [username, setUsername, usernameInput] = useInput({
    type: "text",
    label: "Username",
    name: "username",
    autoComplete: "username",
  });
  let [password, setPassword, passwordInput] = useInput({
    type: "password",
    label: "Password",
    name: "password",
    autoComplete: "current-password",
  });

  let onSubmit: React.ComponentProps<typeof Button>["onClick"] = async function (event) {
    event.preventDefault();
    let response = await fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    if (response.ok) {
      // `continue` is the location the user was trying to access before they were redirected to login, e.g.:
      //   `<Navigate to={createLoginLocation()} />` in `app.tsx`
      let params = new URLSearchParams(location.search);
      const toLocation: To = params.get("continue") ?? {
        // Default to `/timesheets` if there's no `continue` location
        pathname: "/timesheets",
      };
      // Reload the `currentEmployee`
      await getConfigQuery.refetch();
      navigate(toLocation);
    } else if (response.status === 401) {
      // Handle 401 so it doesn't redirect to login
      toast("Unauthorised", {
        type: "error",
      });
    } else {
      handleResponseError(response);
    }
  };

  async function onClickClockOn() {
    await submitPin({ username, password });
    setPassword("");
  }

  return (
    <div className="container mx-auto" style={{ maxWidth: "500px" }}>
      <div className="flex flex-col bg-background">
        <div className="flex-1 mt-10">
          {!refreshingToken && pinResponse && (
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <ClockComponent />
            </div>
          )}
          {!refreshingToken && !pinResponse && (
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="pb-4 text-2xl font-semibold text-primary justify-center flex">Rad Pay</div>
              <div className="mb-4">{usernameInput}</div>
              <div className="mb-4">{passwordInput}</div>
              <div className="flex items-center mb-4 w-full">
                <Button size="lg" colour="accent" type="button" className="flex-1" onClick={onSubmit}>
                  Login
                </Button>

                <Button size="lg" colour="accent" type="button" onClick={onClickClockOn} className="flex-1 ml-1">
                  Clock On/Off
                </Button>
              </div>
              <p className="text-primary text-sm">Use the same Login as RadReport</p>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
