import EmployeeName from "components/EmployeeName";
import HoverEmployeeNotesButton from "components/HoverEmployeeNotesButton";
import { useEmployeeNotesModal } from "helpers/employeeNotes";
import React from "react";
import { useAppSelector } from "store/hooks";
import { AllEmployeeRosteredShifts } from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";

import { OpenDeleteConfirmationModal } from "./roster-main-table";
import { IconButton } from "../components/iconButton";

type Props = {
  employee: Employee;
  employee_rostered_shift: AllEmployeeRosteredShifts;
  openConfirmationModal: OpenDeleteConfirmationModal;
};

export function RosterNameCell(props: Props) {
  let { employee, employee_rostered_shift, openConfirmationModal } = props;
  const isManager = useAppSelector(selectIsManager);

  const { openEmployeeNotesModal } = useEmployeeNotesModal();

  return (
    <th className="roster-table-name-cell">
      <div>
        <div>
          <EmployeeName employeeId={employee.id} />
        </div>
        <div className="flex justify-center items-center">
          {isManager && (
            <>
              <div>
                <IconButton
                  icon="trash-alt"
                  colour="danger"
                  className="mr-1"
                  onClick={() => openConfirmationModal(employee.id)}
                />
              </div>

              <div>
                <HoverEmployeeNotesButton
                  employeeId={employee.id}
                  onClick={() => openEmployeeNotesModal(employee.id)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </th>
  );
}
