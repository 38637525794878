import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Known API errors are returned as json in the response body.
 */
export type ApiError = {
  code: number;
  description: string;
  name: string;
};

// TODO(store): is there a better way to get this type
export type ThunkAPI = Parameters<Parameters<typeof createAsyncThunk>[1]>[1];

export async function handleResponseError<ResponseType = unknown>(response: Response, thunkAPI: ThunkAPI) {
  const { rejectWithValue } = thunkAPI;
  const data = await response.json();
  if (response.ok) {
    return data as ResponseType;
  } else {
    // rejectWithValue does two things:
    // 1. Makes sure that the AsyncThunk fulfilled actions don't contain response error data (from 4xx, 5xx etc)
    // 2. Allows listeners to filter with `isRejectedWithValue` and displays Toasts
    //
    // Note: in dev you need to use `flask run --no-debug` to see a toast,
    // otherwise it errors on `response.json()` because the response is html.
    return rejectWithValue({ data: data as ApiError });
  }
}
