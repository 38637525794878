import { format } from "date-fns";
import React from "react";
import { useArchiveShiftMutation } from "store/reducers/attendance";

import { TextArea } from "../components/textarea";

type Props = {
  open_shift: WorkedShift;
};

export function ForgotToClockOff(props: Props) {
  const { open_shift } = props;
  const [archiveShift] = useArchiveShiftMutation();
  const [note, setNote] = React.useState("");

  function handleChange(e: any) {
    setNote(e.target.value);
  }

  return (
    <>
      <div className="rounded bg-danger-light p-3">
        {open_shift.start && (
          <span>Clocked on since {format(new Date(open_shift.start), "h:mm a EEE dd MMM yyyy")}</span>
        )}
        <div>This shift isn&apos;t from today.</div>
      </div>
      <div className="my-2">
        Send a message to help payroll:
        <TextArea placeholder="e.g. I finished at 6pm but forgot to clock off" value={note} onChange={handleChange} />
      </div>
      <button
        onClick={() => {
          archiveShift({ open_shift, note: note.trim() });
        }}
        className="bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded"
      >
        Continue&hellip;
      </button>
    </>
  );
}
