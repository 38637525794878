import React from "react";

import { Button } from "../components/button";
import { Modal } from "../components/modal";

type Props = {
  closeConfirmationModal: any;
  onClickYes: any;
  children?: React.ReactNode;
  autoFocus?: boolean;
};

const ConfirmationModal = (props: Props) => {
  const { closeConfirmationModal, onClickYes, children, autoFocus = true } = props;
  return (
    <Modal
      isOpen={true}
      dismissModal={closeConfirmationModal}
      title="Warning"
      contentLabel="Confirmation Modal"
      buttons={
        <Button size="sm" colour="accent" onClick={onClickYes} autoFocus={autoFocus}>
          Yes
        </Button>
      }
    >
      <div className="flex flex-col w-64">
        {!children && "Are you sure?"}
        {children}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
