import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "store/handleResponseError";

const fetchDepartments = createAsyncThunk("department/fetchAll", async (_arg, thunkAPI) => {
  let response = await fetch("/api/fetch_departments", {
    method: "GET",
  });
  return handleResponseError<{ departments: Department[] }>(response, thunkAPI);
});

export { fetchDepartments };
