import React from "react";

import { Button } from "../components/button";
import { Modal } from "../components/modal";
import { TextArea } from "../components/textarea";

type Props = {
  closeLeaveDeclineModal: any;
  onClickArchive: any;
};

const LeaveDeclineModal = (props: Props) => {
  const { closeLeaveDeclineModal, onClickArchive } = props;
  const [reason, setReason] = React.useState<string>();
  return (
    <Modal
      isOpen={true}
      dismissModal={closeLeaveDeclineModal}
      title="Decline"
      contentLabel="Leave Decline Modal"
      buttons={
        <Button size="sm" colour="danger" onClick={() => onClickArchive(reason)}>
          Decline
        </Button>
      }
    >
      <div className="md:w-full px-3">
        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
          Reason to decline
        </label>
        <TextArea
          name="reason"
          placeholder="Reason..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          maxLength={100}
        />
      </div>
    </Modal>
  );
};

export default LeaveDeclineModal;
