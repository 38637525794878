import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactModal from "react-modal";

import { IconButton } from "./iconButton";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: 0,
    maxHeight: "100vh",
    maxWidth: "50em",
    width: "100%",
  },
};

type Props = {
  title: React.ReactNode;
  buttons?: React.ReactNode;
  showBottomCloseButton?: boolean;
  children?: React.ReactNode;
  dismissModal: () => any;
  // [x: string]: any; // Any props not listed above will be spread onto the underlying
};

export function Modal(props: Props & ReactModal.Props) {
  let { children, title, buttons, showBottomCloseButton = true, dismissModal, ...otherProps } = props;
  return (
    <ReactModal
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={dismissModal}
      overlayClassName="overlay"
      {...otherProps}
    >
      <div>
        <div className="flex justify-between border-b border-primary-lightest">
          <div className="px-5 py-4">
            <FontAwesomeIcon icon="exclamation-circle" className="text-accent" />
            <span className="font-bold text-primary text-lg ml-2">{title}</span>
          </div>
          <IconButton onClick={dismissModal} className="w-12" icon="times-circle" colour={"danger"} />
        </div>

        <div className="px-10 py-5 text-primary">{children}</div>
        {showBottomCloseButton && (
          <div className="px-5 py-4 flex justify-end">
            <button
              onClick={dismissModal}
              className="text-sm py-2 px-3 text-primary hover:text-primary-dark transition duration-150"
            >
              Close
            </button>

            {buttons}
          </div>
        )}
      </div>
    </ReactModal>
  );
}
