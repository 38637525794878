import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { employeeApi } from "./employeeAPI";
import { RootState } from "../../store";
import { rosterApi } from "../rosteredShift/rosterApi";

const employeeAdapter = createEntityAdapter<Employee>();

const initialState = employeeAdapter.getInitialState();

const employeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(employeeApi.endpoints.fetchEmployees.matchFulfilled, (state, action) => {
        employeeAdapter.upsertMany(state, action.payload.employees);
      })
      .addMatcher(rosterApi.endpoints.fetchAll.matchFulfilled, (state, action) => {
        employeeAdapter.upsertMany(state, action.payload.employees);
      })
      .addMatcher(employeeApi.endpoints.xeroImport.matchFulfilled, employeeAdapter.upsertOne)
      .addMatcher(employeeApi.endpoints.updateEmployee.matchFulfilled, (state, action) => {
        employeeAdapter.upsertOne(state, action.payload);
      });
  },
});

export const { selectById: selectEmployeeById, selectAll: selectAllEmployees } =
  employeeAdapter.getSelectors<RootState>((state) => state.employee);

export { employeeAdapter, employeeSlice };
