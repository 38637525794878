import classNames from "classnames";
import React from "react";

type Props = {
  width?: number;
  height?: number;
} & React.ComponentPropsWithoutRef<"div">;

export function LoadingSpinner(props: Props) {
  const { width = 20, height = 20, className } = props;
  return (
    <div
      className={classNames(className, "loader ease-linear rounded-full inline-block")}
      style={{ borderWidth: 3, width, height, marginRight: "0.25rem" }}
    />
  );
}
