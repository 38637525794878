import React from "react";
import { GROUP_BY } from "store/reducers";
import { ArrayParam, createEnumParam, QueryParamConfig, useQueryParams, withDefault } from "use-query-params";

import { ALL_ROLE_FILTERS, RoleFilter } from "../timesheet_viewer/employee_filter";

export function useRosterParams() {
  const [rosterParams, setRosterParams] = useQueryParams({
    roles: withDefault(ArrayParam as QueryParamConfig<string[] | null | undefined>, [] as string[]),
    g: withDefault(createEnumParam(GROUP_BY.map((g) => g.value)), GROUP_BY[0].value),
  });

  const companyRoleFilter: RoleFilter[] = React.useMemo(() => {
    return rosterParams.roles.map((role_value) => ALL_ROLE_FILTERS.find((rf) => rf.value === role_value) as RoleFilter);
  }, [rosterParams.roles]);

  return [rosterParams, setRosterParams, { companyRoleFilter }] as const;
}
