import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { format, formatISO, set } from "date-fns";
import useCombinedRefs from "helpers/useCombinedRefs";
import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { rosterApi, selectEmployeeById } from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";

import { RosterDropResult } from "./roster-shift-cell";
import { OpenNewShiftModalFunction } from "./RosterComponent";
import { Duration } from "../timesheet_viewer/table_components/duration";

export type RosterDragObject = {
  id: number;
  type: "rostered_shift";
  rostered_shift: RosteredShift;
};

type Props = {
  shift: RosteredShift;
  openNewShiftModal: OpenNewShiftModalFunction;
  date: Date;
};

export function RosterShift(props: Props) {
  const shiftRef = useRef<HTMLDivElement>(null);
  const query = new URLSearchParams(useLocation().search);
  const shiftQueryParam = query.get("shift");
  const shiftId = shiftQueryParam ? parseInt(shiftQueryParam, 10) : null;

  const { shift, openNewShiftModal, date } = props;

  const employee = useAppSelector((state) => selectEmployeeById(state, shift.employee_id));
  const isManager = useAppSelector(selectIsManager);
  const [createRosteredShift, { isLoading: isCreatingRosteredShift }] = rosterApi.useCreateRosteredShiftMutation();

  const [updateRosteredShift, { isLoading: isUpdatingRosteredShift }] = rosterApi.useUpdateRosteredShiftMutation();

  React.useEffect(() => {
    requestAnimationFrame(() => {
      if (shift.id === shiftId && shiftRef.current) {
        shiftRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    });
  }, [shiftId, shift.id]);

  const [, drag] = useDrag<
    RosterDragObject,
    RosterDropResult & {
      dropEffect: "move" | "copy"; // defined by library
    }
  >({
    type: "rostered_shift",
    item: () => ({
      type: "rostered_shift",
      id: shift.id,
      rostered_shift: shift,
    }),
    end: (item, monitor) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!item) return; // TODO: remove?
      const dropResult = monitor.getDropResult();
      if (!dropResult) return;
      const { date, dropEffect } = dropResult;

      const start = set(new Date(item.rostered_shift.start), {
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate(),
      });
      const end = set(new Date(item.rostered_shift.end), {
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate(),
      });
      const startBreak =
        (item.rostered_shift.rostered_breaks[0]?.start &&
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          set(new Date(item.rostered_shift.rostered_breaks[0].start), {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
          })) ||
        null;
      const endBreak =
        (item.rostered_shift.rostered_breaks[0]?.end &&
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          set(new Date(item.rostered_shift.rostered_breaks[0].end), {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
          })) ||
        null;

      let rostered_break: RosteredBreak | null;
      if (startBreak && endBreak) {
        rostered_break = {
          start: formatISO(startBreak, { representation: "complete" }),
          end: formatISO(endBreak, { representation: "complete" }),
        };
      } else {
        rostered_break = null;
      }

      if (dropEffect === "move") {
        updateRosteredShift({
          rosteredShiftId: item.id,
          body: {
            start: formatISO(start, { representation: "complete" }),
            end: formatISO(end, { representation: "complete" }),
            rostered_break,
            modalities: item.rostered_shift.modalities,
          },
        });
      } else {
        createRosteredShift({
          body: {
            start: formatISO(start, { representation: "complete" }),
            end: formatISO(end, { representation: "complete" }),

            employee_id: item.rostered_shift.employee_id,
            notes: item.rostered_shift.notes,
            rostered_break,

            department_id: item.rostered_shift.department.id,
            location_id: item.rostered_shift.location.id,
            modalities: item.rostered_shift.modalities,
          },
        });
      }
    },
  });

  const combinedRef = useCombinedRefs<HTMLDivElement>(drag, shiftRef);

  const highlightShift = shift.id === shiftId ? "roster-table-shift-cell-inner-highlight" : "";

  return (
    <div
      ref={combinedRef}
      className={`${shift.archived ? "roster-table-shift-cell-inner-archived" : "roster-table-shift-cell-inner"} ${highlightShift}`}
      onClick={(event) => {
        event.stopPropagation();
        if (shift.archived) return;
        // @ts-expect-error TS2322
        openNewShiftModal({ date, employee, rosteredShift: shift });
      }}
    >
      <div
        className={classNames("overflow-hidden", {
          "line-through": shift.archived,
        })}
        title={format(new Date(shift.start), "h:mm a") + "-" + format(new Date(shift.end), "h:mm a")}
        style={{ textOverflow: "ellipsis" }}
      >
        <span className="whitespace-nowrap">{format(new Date(shift.start), "h:mm a")}</span>-
        <span className="whitespace-nowrap">{format(new Date(shift.end), "h:mm a")}</span>
      </div>
      <div className="flex flex-wrap items-center justify-center">
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {shift.department && (
          <div
            style={{
              textOverflow: "ellipsis",
              // Yellow
              backgroundColor:
                shift.department.name === "Doctor" || shift.department.name === "Injections"
                  ? "hsl(60 100% 50%)"
                  : undefined,
            }}
            title={shift.department.name}
            className={classNames("block rounded-full px-1 text-xs font-bold overflow-hidden", {
              "bg-warning text-white": shift.department.name === "Radiographer",
              "bg-accent text-white": shift.department.name === "Sonographer",
              "bg-success text-white": shift.department.name === "Administration",
              "bg-danger text-white": shift.department.name === "Training",
              "bg-warning-light text-white": shift.department.name === "Technician",
              "bg-accent-dark text-white": shift.department.name === "IT",
              "text-primary": shift.department.name === "Doctor" || shift.department.name === "Injections",
            })}
          >
            {shift.department.name === "Radiographer" && <span title="Radiographer">Rad</span>}
            {shift.department.name === "Sonographer" && <span title="Sonographer">Sono</span>}
            {shift.department.name === "Administration" && <span title="Administration">Admin</span>}
            {shift.department.name === "Training" && <span title="Training">Training</span>}
            {shift.department.name === "Technician" && <span title="Technician">Tech</span>}
            {shift.department.name === "Doctor" && <span title="Doctor">Doctor</span>}
            {shift.department.name === "Injections" && <span title="Injections">Inj</span>}
            {shift.department.name === "IT" && <span title="IT">IT</span>}
          </div>
        )}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {shift.location && (
          <div
            style={{
              textOverflow: "ellipsis",
            }}
            title={shift.location.name}
            className="block rounded-full text-white px-1 text-xs font-bold overflow-hidden bg-primary mx-1"
          >
            {shift.location.short_name}
          </div>
        )}
      </div>
      {shift.modalities.length > 0 && (
        <div className="flex items-center justify-center mt-2">
          {shift.modalities.map((modality) => (
            <div
              key={modality}
              style={{
                textOverflow: "ellipsis",
              }}
              title={modality}
              className="block rounded-full text-white px-1 text-xs font-bold overflow-hidden bg-primary mx-1"
            >
              {modality}
            </div>
          ))}
        </div>
      )}

      <div className="mt-2 text-xs">
        {shift.rostered_breaks[0] && (
          <div
            title={
              format(new Date(shift.rostered_breaks[0].start), "h:mm a") +
              "-" +
              format(new Date(shift.rostered_breaks[0].end), "h:mm a")
            }
            style={{ textOverflow: "ellipsis" }}
          >
            <FontAwesomeIcon icon="coffee" className="mr-1" />
            <span className="whitespace-nowrap">{format(new Date(shift.rostered_breaks[0].start), "h:mm a")}</span>-
            <span className="whitespace-nowrap ">{format(new Date(shift.rostered_breaks[0].end), "h:mm a")}</span>
          </div>
        )}
        {shift.rostered_breaks.length === 0 && (
          <div
            style={{
              textOverflow: "ellipsis",
            }}
            title="Break duration"
            className="block text-xs font-semibold overflow-hidden"
          >
            <Duration seconds={0} showZeroHours={false} />
            <FontAwesomeIcon icon="coffee" />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center">
        {shift.notes && shift.notes !== "" && <FontAwesomeIcon icon="comment" className="ml-1" title={shift.notes} />}
        {shift.published_at && isManager && (
          <FontAwesomeIcon
            icon="check"
            className={classNames(
              {
                "text-success": shift.emailed,
              },
              {
                "text-danger": !shift.emailed,
              },
              "ml-auto",
            )}
            title={"Published" + (shift.emailed ? " and emailed" : "")}
          />
        )}
      </div>
    </div>
  );
}
