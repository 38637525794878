import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { formatISO } from "date-fns";

type FetchAllBody = {
  /**
   * Specifying the date excludes terminated employees before that date.
   */
  date?: string | null;
  include_not_linked_to_rr?: boolean;
  include_not_linked_to_xero?: boolean;
  force_include_ids?: number[];
};

export type XeroImportEmployee = {
  employee: XeroEmployee;
  xero_tenantId: string;
};

type XeroImportResponse = {
  employees: XeroImportEmployee[];
};

const today = formatISO(new Date(), { representation: "date" });

const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["Employee"],
  endpoints: (builder) => ({
    getXeroImport: builder.query<XeroImportResponse, void>({
      query: () => "/api/xero_import",
    }),
    xeroImport: builder.mutation<
      Employee,
      {
        EmployeeID: string;
        xero_tenantId: string;
      }
    >({
      query: ({ EmployeeID, xero_tenantId }) => ({
        url: "/api/xero_import",
        method: "POST",
        body: { EmployeeID, xero_tenantId },
      }),
      invalidatesTags: ["Employee"],
    }),

    updateEmployee: builder.mutation<
      Employee,
      {
        employeeId: number;
        body: Partial<Employee> & {
          // Non xero_Prefixed fields are accepted
          EmployeeID?: string;
          tenantId?: string;
        };
      }
    >({
      query: ({ employeeId, body }) => ({
        url: `/api/employee/${employeeId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Employee"],
    }),

    fetchEmployees: builder.query<{ employees: Employee[] }, FetchAllBody | undefined>({
      query: (options = {}) => {
        const {
          include_not_linked_to_rr,
          include_not_linked_to_xero,
          // Defaults to today if undefined (not when null, null is passed through)
          date = today,
          force_include_ids,
        } = options;
        const searchParams = new URLSearchParams();
        if (include_not_linked_to_rr) searchParams.set("include_not_linked_to_rr", "1");
        if (include_not_linked_to_xero) searchParams.set("include_not_linked_to_xero", "1");
        if (date !== null) {
          searchParams.set("date", date);
        }
        if (force_include_ids) searchParams.set("force_include_ids", force_include_ids.join(","));
        return { url: `/api/employee?${searchParams}` };
      },
      providesTags: ["Employee"],
    }),
    fetchXeroEmployee: builder.query<XeroEmployee | undefined, number>({
      query: (employee_id) => `/api/xero_employee/${employee_id}`,
      transformResponse: (response: { employees: XeroEmployee[] }) => {
        return response.employees[0];
      },
    }),
  }),
});

export { employeeApi };
export const {
  useFetchEmployeesQuery,
  useLazyFetchEmployeesQuery,
  useFetchXeroEmployeeQuery,
  useUpdateEmployeeMutation,
  useXeroImportMutation,
  useLazyGetXeroImportQuery,
} = employeeApi;
