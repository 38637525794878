import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Portal } from "@radix-ui/react-portal";
import { skipToken } from "@reduxjs/toolkit/query";
import EmployeeName from "components/EmployeeName";
import FileUpload from "components/FileUpload";
import { Select, SelectItem } from "components/Select";
import { format, formatISO, startOfDay } from "date-fns";
import { useFileUpload } from "helpers/useFileUpload";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  createLeaveApplication,
  leaveTypeApi,
  selectIsManager,
  selectLeavePeriodEntities,
  updateLeaveApplication,
  useFetchEmployeesQuery,
  useGetConfigQuery,
  useOverlappingLeaveApplicationsQuery,
} from "store/reducers";

import { Button } from "../components/button";
import EmployeeComboBox from "../components/employeeComboBox";
import { Modal } from "../components/modal";
import { TextArea } from "../components/textarea";

type Props = {
  closeLeaveApplicationModal: any;
  leave?: LeaveApplication;
  defaults?: {
    employee_id?: number;
    start?: Date;
  };
};

const LeaveApplicationModal = (props: Props) => {
  const { closeLeaveApplicationModal, leave, defaults } = props;

  const { data: configData } = useGetConfigQuery();
  const isManager = useAppSelector(selectIsManager);

  const dispatch = useAppDispatch();

  const [leaveStartDate, setLeaveStartDate] = React.useState<Date>(
    defaults?.start ||
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (leave?.xero_StartDate && new Date(leave.xero_StartDate)) ||
      startOfDay(new Date()),
  );
  const [leaveEndDate, setLeaveEndDate] = React.useState<Date>(
    defaults?.start ||
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (leave?.xero_StartDate && new Date(leave.xero_EndDate)) ||
      startOfDay(new Date()),
  );
  const overlappingLeavePeriods = useOverlappingLeaveApplicationsQuery(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    leaveStartDate && leaveEndDate
      ? {
          start: formatISO(leaveStartDate, { representation: "date" }),
          end: formatISO(leaveEndDate, { representation: "date" }),
        }
      : skipToken,
  );
  const [leaveDatesChanged, setLeaveDatesChanged] = React.useState(false);
  const [leavePeriodsForm, setLeavePeriodsForm] =
    React.useState<Pick<LeavePeriod, "xero_NumberOfUnits" | "xero_PayPeriodEndDate">[]>();
  const leavePeriodEntities = useAppSelector(selectLeavePeriodEntities);
  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = React.useState(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = React.useState(false);
  const [notes, setNotes] = React.useState(leave?.xero_Title || "");
  const [leaveType, setLeaveType] = React.useState<XeroLeaveType>();
  const [tempAttachment, setTempAttachment] = React.useState<Upload | undefined>(
    (leave && leave.attachment) || undefined,
  );
  const { data: employees } = useFetchEmployeesQuery(undefined);

  // While it is null we don't render the Select
  const [selectedEmployee, setSelectedEmployee] = React.useState<Employee | null | undefined>(null);
  const xero_tenantId = selectedEmployee?.xero_tenantId;
  const leaveTypesQuery = leaveTypeApi.useGetLeaveTypesQuery(
    xero_tenantId !== undefined ? { xero_tenantId } : skipToken,
  );

  const { fileAsync, isUploading, uploadResult, uploadFileResult } = useFileUpload(selectedEmployee, setTempAttachment);

  React.useEffect(() => {
    function getDefaultEmployee() {
      if (!employees) return null;
      if (leave) {
        let employee = employees.employees.find(
          (e: Employee) => e.id === leave.employee_id || e.id === defaults?.employee_id,
        );
        return employee; // should not be undefined unless there's a bug and the employee wasn't loaded
      } else {
        return configData?.currentEmployee;
      }
    }
    setSelectedEmployee(getDefaultEmployee());
  }, [employees, configData, leave, defaults]);

  // Initialise leave periods for new leave request
  React.useEffect(() => {
    if (
      (!leave || leaveDatesChanged) &&
      // When leave dates change, it fetches the new data. Make sure it's done fetching so it doesn't use the old data.
      !overlappingLeavePeriods.isFetching &&
      overlappingLeavePeriods.data
    ) {
      setLeavePeriodsForm(
        overlappingLeavePeriods.data.periods.map((p) => ({
          ...p,
          xero_PayPeriodEndDate: p.end,
          xero_NumberOfUnits: p.units,
        })),
      );
      setLeaveDatesChanged(false);
    }
  }, [leave, overlappingLeavePeriods, leaveDatesChanged]);

  // Initialise leave periods from existing leave request
  React.useEffect(() => {
    if (leave) {
      const leavePeriods = leave.leave_period_ids
        .map((id) => leavePeriodEntities[id])
        .filter((p): p is LeavePeriod => !!p);
      setLeavePeriodsForm(
        leavePeriods.map((p) => ({
          ...p,
        })),
      );
    }
  }, [leaveTypesQuery.data]);

  function toggleStartDatePicker() {
    setStartDatePickerIsOpen(!startDatePickerIsOpen);
  }

  function toggleEndDatePicker() {
    setEndDatePickerIsOpen(!endDatePickerIsOpen);
  }

  function onChangeStartDate(date: Date) {
    setLeaveStartDate(date);
    if (leaveEndDate < date) {
      setLeaveEndDate(date);
    }
    setLeaveDatesChanged(true);
  }

  function onChangeEndDate(date: Date) {
    setLeaveEndDate(date);
    if (leaveStartDate > date) {
      setLeaveStartDate(date);
    }
    setLeaveDatesChanged(true);
  }
  function onChangeLeaveAmount(e: any, index: number) {
    setLeavePeriodsForm(
      leavePeriodsForm?.map((data, i) => {
        if (index === i) {
          return {
            ...data,
            xero_NumberOfUnits: e.target.value,
          };
        }
        return data;
      }),
    );
  }

  function onChangeNotes(e: any) {
    setNotes(e.target.value);
  }

  function onChangeLeaveType(leaveTypeId: string) {
    let leaveType = leaveTypesQuery.data?.LeaveTypes.find((type) => type.leave_type_id === leaveTypeId);
    setLeaveType(leaveType);
  }

  function removeFile() {
    setTempAttachment(undefined);
  }

  async function onClickSubmit() {
    if (!configData || !leaveType || !selectedEmployee) {
      throw new Error("Missing data");
    }
    if ((!isUploading || uploadResult.data) && !overlappingLeavePeriods.isLoading) {
      let updateObject = {
        xero_LeaveTypeID: leaveType.leave_type_id,
        xero_LeaveTypeName: leaveType.name,
        xero_Title: notes === "" ? undefined : notes,
        xero_StartDate: formatISO(leaveStartDate, {
          representation: "date",
        }),
        xero_EndDate: formatISO(leaveEndDate, { representation: "date" }),
        leave_periods: leavePeriodsForm?.map((data) => ({
          xero_PayPeriodEndDate: data.xero_PayPeriodEndDate,
          xero_NumberOfUnits: data.xero_NumberOfUnits,
        })),
        attachment_filename: tempAttachment ? tempAttachment.filename : null,
      };

      if (leave) {
        await dispatch(
          updateLeaveApplication({
            leaveApplicationId: leave.id,
            body: updateObject as Partial<LeaveApplication>,
          }),
        );
        closeLeaveApplicationModal();
      } else {
        await dispatch(
          createLeaveApplication({
            ...updateObject,
            employee_id: selectedEmployee.id,
          }),
        );
        closeLeaveApplicationModal();
      }
    }
  }

  React.useEffect(() => {
    if (leaveTypesQuery.data) {
      if (leave) {
        // Initialise from the existing leave request
        setLeaveType(
          leaveTypesQuery.data.LeaveTypes.find((leaveType) => {
            return leaveType.leave_type_id === leave.xero_LeaveTypeID;
          }),
        );
      } else {
        // Select the first leave type by default (should be Annual Leave in Xero's response)
        setLeaveType(leaveTypesQuery.data.LeaveTypes[0]);
      }
    }
  }, [leaveTypesQuery.data, leave]);

  return (
    <Modal
      isOpen={true}
      dismissModal={closeLeaveApplicationModal}
      title={leave ? "Edit Leave Request" : isManager ? "Create a Leave Request" : "Request Leave"}
      contentLabel="Apply for Leave Modal"
      buttons={
        <Button onClick={onClickSubmit} colour="accent" disabled={overlappingLeavePeriods.isLoading}>
          Submit
        </Button>
      }
    >
      <div className="flex flex-col">
        {isManager && (
          <div className="md:flex mb-6">
            <div className="md:w-1/2">
              {!leave && employees && selectedEmployee !== null && (
                <EmployeeComboBox
                  defaultValue={selectedEmployee}
                  employees={employees.employees}
                  onSelectEmployee={(employee) => setSelectedEmployee(employee)}
                />
              )}
              {leave && (
                <div>
                  <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Employee Name
                  </label>
                  <div className="flex">
                    <span className="text-primary-dark">
                      {selectedEmployee && <EmployeeName employeeId={selectedEmployee.id} />}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="md:flex mb-6">
          <div className="md:w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Leave Type</label>
            {leaveTypesQuery.isLoading && <div>Loading...</div>}
            {leaveTypesQuery.isError && <div>Error</div>}
            {/* leaveType checked first because otherwise it doesn't bind to the new value when changing from undefined */}
            {leaveType && leaveTypesQuery.isSuccess && (
              <Select onValueChange={onChangeLeaveType} value={leaveType.leave_type_id}>
                {leaveTypesQuery.data.LeaveTypes.map((leaveType) => {
                  return (
                    <SelectItem value={leaveType.leave_type_id} key={leaveType.leave_type_id}>
                      {leaveType.name}
                    </SelectItem>
                  );
                })}
              </Select>
            )}
          </div>
        </div>
        <div className="md:flex mb-6">
          <div className="md:w-1/2 mb-3 md:mb-0 mr-3">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
              First day of leave
            </label>
            <div className="flex">
              <DatePicker
                className={
                  "appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded-l py-3 px-4 mb-3 focus:outline-none cursor-not-allowed"
                }
                selected={leaveStartDate}
                onChange={onChangeStartDate}
                open={startDatePickerIsOpen}
                onSelect={toggleStartDatePicker}
                dateFormat="dd/MM/yyyy"
                // Append to document.body
                popperContainer={Portal}
                readOnly
                disabledKeyboardNavigation
              />
              <Button
                onClick={toggleStartDatePicker}
                colour="accent"
                className="mb-3 rounded-r rounded-l-none py-2 px-4"
              >
                <FontAwesomeIcon icon="calendar-alt" />
              </Button>
            </div>
          </div>
          <div className="md:w-1/2">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
              Last day of leave
            </label>
            <div className="flex">
              <DatePicker
                className={
                  "appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded-l py-3 px-4 mb-3 focus:outline-none cursor-not-allowed"
                }
                selected={leaveEndDate}
                onChange={onChangeEndDate}
                open={endDatePickerIsOpen}
                onSelect={toggleEndDatePicker}
                dateFormat="dd/MM/yyyy"
                popperContainer={Portal}
                readOnly
                disabledKeyboardNavigation
              />
              <Button onClick={toggleEndDatePicker} colour="accent" className="mb-3 rounded-r rounded-l-none py-2 px-4">
                <FontAwesomeIcon icon="calendar-alt" />
              </Button>
            </div>
          </div>
        </div>

        <div className="md:flex mb-6">
          <div className="md:w-1/2 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
              Leave Required
            </label>
            {leavePeriodsForm?.map((period, i) => {
              return (
                <div key={i}>
                  <div>In Pay Period ending {format(new Date(period.xero_PayPeriodEndDate), "dd/MM/yyyy")}</div>
                  <div className="flex mb-3">
                    <input
                      type="number"
                      className={
                        "appearance-none block w-full bg-grey-lighter text-grey-darker border border-primary-light rounded-l py-3 px-4 focus:outline-none focus:ring"
                      }
                      value={period.xero_NumberOfUnits}
                      onChange={(e) => onChangeLeaveAmount(e, i)}
                    />
                    <div className="bg-accent text-white font-bold py-2 px-4 rounded-r focus:outline-none items-center flex">
                      <span className="flex whitespace-nowrap">work hours</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="md:w-1/2 px-3">
                      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                        Available balance
                      </label>
                      <div>
                        <input
                          className={
                            "appearance-none block w-full bg-grey-lighter text-grey-darker border border-primary-light rounded py-3 px-4 mb-3 focus:outline-none cursor-not-allowed bg-background"
                          }
                          disabled
                        ></input>
                      </div>
                    </div> */}
        </div>

        <div className="md:flex mb-2">
          <div className="md:w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Notes</label>
            <TextArea
              name="Notes"
              placeholder="Message..."
              value={notes}
              onChange={(e) => onChangeNotes(e)}
              maxLength={100}
            />
          </div>
        </div>

        <div className="md:flex mb-2">
          <div className="md:w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Attachments</label>
            <FileUpload uploadFile={fileAsync} attachment={tempAttachment} removeFile={removeFile} />
            {isUploading && "Uploading..."}
            {uploadResult.error && "description" in uploadResult.error && (
              <div className="text-danger">{uploadResult.error.description}</div>
            )}
            {uploadFileResult.error && "description" in uploadFileResult.error && (
              <div className="text-danger">{uploadFileResult.error.description}</div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LeaveApplicationModal;
