import { format } from "date-fns";
import React from "react";
import { PinResponse } from "store/reducers";

type Props = {
  pinResponse: PinResponse;
};

export function PreviousShift(props: Props) {
  const { pinResponse } = props;

  if (!pinResponse.last_shift || !pinResponse.last_shift_obj) return null;

  return (
    <div className="flex flex-col bg-primary-lightest text-primary p-2 rounded-sm mb-4 font-semibold">
      <span className="text-lg mb-2">Last Shift: </span>
      <div className="text-lg">
        {pinResponse.last_shift_obj.start && format(new Date(pinResponse.last_shift_obj.start), "h:mm a")} -{" "}
        {pinResponse.last_shift_obj.end && format(new Date(pinResponse.last_shift_obj.end), "h:mm a")} at{" "}
        {pinResponse.last_shift.location && pinResponse.last_shift.location.name} on{" "}
        {pinResponse.last_shift_obj.start && format(new Date(pinResponse.last_shift_obj.start), "EEE dd/MM")}
      </div>
    </div>
  );
}
