import { add, format, getDay, getDaysInMonth } from "date-fns";
import { RRule } from "rrule";

/**
 * Get recurrring string from start date and recurring
 */
export function getRecurringString(startDate: Date | string, recurrence: RecurrentPattern) {
  let date = new Date(startDate);
  switch (recurrence) {
    case "daily":
      return "Daily";
    case "weekly":
      return `Weekly on ${format(date, "EEEE")}`;
    case "fortnightly":
      return `Fortnightly on ${format(date, "EEEE")}`;
    case "monthly":
      return `Monthly on the ${getNthDayText(getNthDayOfMonth(date))} ${format(date, "EEEE")}`;
    case "annually":
      return `Annually on ${format(date, "dd/MM")}`;
    default:
      return "Daily";
  }
}

export function getNthDayOfMonth(date: Date) {
  let daysInMonth = getDaysInMonth(date);
  if (date.getDate() + 7 > daysInMonth) return -1; // Last day of month
  return Math.ceil(date.getDate() / 7);
}

function getNthDayText(n: number) {
  switch (n) {
    case -1:
      return "last";
    case 1:
      return "first";
    case 2:
      return "second";
    case 3:
      return "third";
    case 4:
      return "fourth";
    default:
      return "invalid";
  }
}

function getRRuleFrequency(recurrence: RecurrentPattern) {
  switch (recurrence) {
    case "daily":
      return RRule.DAILY;
    case "weekly":
      return RRule.WEEKLY;
    // There is no RRule.FORTNIGHTLY, so use interval = 2 and RRule.WEEKLY to create RRule instead
    case "fortnightly":
      return RRule.WEEKLY;
    case "monthly":
      return RRule.MONTHLY;
    case "annually":
      return RRule.YEARLY;
    default:
      return RRule.DAILY;
  }
}

function getRRuleWeekday(date: Date) {
  const rruleDays = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];
  const day = getDay(date);
  return rruleDays[day];
}

export function createRRule(event: UnavailableEvent) {
  const freq = getRRuleFrequency(event.recurrent_pattern);
  const weekday = getRRuleWeekday(new Date(event.start_date));
  return new RRule({
    freq: freq,
    byweekday: freq === RRule.WEEKLY || freq === RRule.MONTHLY ? weekday : null,
    bysetpos: freq === RRule.MONTHLY ? getNthDayOfMonth(new Date(event.start_date)) : null,
    dtstart: new Date(event.start_date),
    until: event.end_date ? new Date(event.end_date) : null,
    //Interval = 2 only for "fortnightly"
    interval: event.recurrent_pattern === "fortnightly" ? 2 : 1,
  });
}

export function getUnavailableEvents(events: UnavailableEvent[], date: Date) {
  const unavailable_events = events.filter((event) => {
    const rule = createRRule(event);
    const dates = rule.between(date, add(date, { days: 1 }), true);
    return dates.length > 0;
  });

  //Suppress all events if there is allday unavailable event
  const allday_event = unavailable_events.find((e) => !e.start_time && !e.end_time);
  return allday_event ? [allday_event] : unavailable_events;
}
