import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { fetchDepartments } from "./departmentAPI";
import { RootState } from "../../store";

const departmentAdapter = createEntityAdapter<Department>();

const initialState = departmentAdapter.getInitialState();

const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepartments.fulfilled, (state, action) => {
      departmentAdapter.setAll(state, action.payload.departments);
    });
  },
});

export const { selectById: selectDepartmentById, selectAll: selectAllDepartments } =
  departmentAdapter.getSelectors<RootState>((state) => state.department);

export { departmentAdapter, departmentSlice };
