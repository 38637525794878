// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
  faLocationArrow,
  faSortDown,
  faBusinessTime,
  faCalendarAlt,
  faPaperPlane,
  faCog,
  faCalendarWeek,
  faUserClock,
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
  faSearch,
  faSuitcaseRolling,
  faExclamationCircle,
  faTimesCircle,
  faPlaneDeparture,
  faHourglassStart,
  faLink,
  faExternalLinkAlt,
  faSave,
  faTrashAlt,
  faAngleDown,
  faShoePrints,
  faWalking,
  faArrowUp,
  faArrowDown,
  faComments,
  faCheck,
  faExclamation,
  faCoffee,
  faCarSide,
  faHistory,
  faUserPlus,
  faPlus,
  faClock,
  faPaperclip,
  faEnvelope,
  faRedoAlt,
  faInfo,
  faCopy,
  faComment,
  faRoute,
  faArrowLeft,
  faPowerOff,
  faBan,
  faTrashRestore,
  faTimes,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faLocationArrow,
  faSortDown,
  faBusinessTime,
  faCalendarAlt,
  faPaperPlane,
  faCog,
  faCalendarWeek,
  faUserClock,
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
  faSearch,
  faSuitcaseRolling,
  faExclamationCircle,
  faTimesCircle,
  faPlaneDeparture,
  faHourglassStart,
  faLink,
  faExternalLinkAlt,
  faSave,
  faTrashAlt,
  faAngleDown,
  faShoePrints,
  faWalking,
  faArrowUp,
  faArrowDown,
  faComments,
  faCheck,
  faExclamation,
  faCoffee,
  faCarSide,
  faHistory,
  faUserPlus,
  faPlus,
  faClock,
  faPaperclip,
  faEnvelope,
  faRedoAlt,
  faInfo,
  faCopy,
  faComment,
  faRoute,
  faArrowLeft,
  faPowerOff,
  faBan,
  faTrashRestore,
  faTimes,
  faEdit,
);
