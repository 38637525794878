import React from "react";
import { useAppSelector } from "store/hooks";
import { selectEmployeeById } from "store/reducers";

import { HoverCardArrow, HoverCardContent, HoverCardPortal, HoverCardRoot, HoverCardTrigger } from "./HoverCard";
import { IconButton } from "./iconButton";

interface HoverEmployeeNotesButtonProps {
  employeeId: number;
  onClick: () => void;
}

const HoverEmployeeNotesButton: React.FC<HoverEmployeeNotesButtonProps> = ({ employeeId, onClick, ...props }) => {
  const employee = useAppSelector((state) => selectEmployeeById(state, employeeId));

  // Checking for terminated employees (undefined) in Message component
  const employeeHasNotes = employee && employee.notes;

  // Displays the hover card only if the employee has notes, and changes the icon color to indicate the presence of notes.
  return (
    <HoverCardRoot openDelay={0} closeDelay={0}>
      <HoverCardTrigger asChild>
        <div>
          <IconButton
            onClick={onClick}
            colour={employeeHasNotes ? "success" : "accent"}
            size="lg"
            icon="comments"
            {...props}
          />
        </div>
      </HoverCardTrigger>
      {employeeHasNotes && (
        <HoverCardPortal>
          <HoverCardContent side={"top"} sideOffset={5}>
            <div className="text-sm text-gray-700">{employee.notes}</div>
            <HoverCardArrow />
          </HoverCardContent>
        </HoverCardPortal>
      )}
    </HoverCardRoot>
  );
};

export default HoverEmployeeNotesButton;
