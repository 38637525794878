import { format, parseISO } from "date-fns";
import React from "react";
import { useDeletePublicHolidayEventMutation, useFetchPublicHolidayEventsQuery } from "store/reducers";

import PublicHolidayEventModal from "./public_holiday_modal";

const PublicHolidayEvents = () => {
  const { data: publicHolidayEvents, isLoading, isSuccess } = useFetchPublicHolidayEventsQuery();

  const [deletePublicHolidayEvent] = useDeletePublicHolidayEventMutation();

  const [publicHolidayEventModal, setPublicHolidayEventModal] = React.useState<any>(undefined);

  function openPublicHolidayEventModal(event: PublicHolidayEvent) {
    setPublicHolidayEventModal(
      <PublicHolidayEventModal
        publicHolidayEvent={event}
        closePublicHolidayEventModal={() => setPublicHolidayEventModal(undefined)}
      />,
    );
  }

  return (
    <div className="mt-5">
      <h3 className="text-primary-dark mb-2">Existing Public Holiday Events</h3>
      {isLoading && <div>Loading...</div>}
      {isSuccess && !publicHolidayEvents.public_holiday_events.length && <div>No events found</div>}
      {isSuccess &&
        publicHolidayEvents.public_holiday_events.length &&
        publicHolidayEvents.public_holiday_events.map((event: PublicHolidayEvent) => {
          const dateObject = parseISO(event.date);
          const readableDate = format(dateObject, "MMMM dd, yyyy");
          return (
            <div className="grid grid-cols-3 gap-4 mb-1" key={event.id}>
              <div className="text-grey-darker">
                <div>{event.name}</div>
              </div>
              <div className="text-grey-darker">
                <div>{readableDate}</div>
              </div>
              <div className="flex">
                <div>
                  <button
                    onClick={() => openPublicHolidayEventModal(event)}
                    className="bg-accent hover:bg-accent text-white font-bold py-1 px-2 rounded focus:outline-none focus:ring mr-2"
                  >
                    Edit Event
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => deletePublicHolidayEvent(event.id)}
                    className="bg-accent hover:bg-accent text-white font-bold py-1 px-2 rounded focus:outline-none focus:ring"
                  >
                    Delete Event
                  </button>
                </div>
              </div>
              {publicHolidayEventModal}
            </div>
          );
        })}
    </div>
  );
};

export default PublicHolidayEvents;
