import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";

import { shiftApi } from "../shift/shiftApi";

const travelEventAdapter = createEntityAdapter<TravelEvent>();

const travelEventSlice = createSlice({
  name: "travelEvent",
  initialState: travelEventAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(shiftApi.endpoints.fetchWorkedShift.matchFulfilled, (state, action) => {
        travelEventAdapter.upsertMany(state, action.payload.travel_events);
      })
      .addMatcher(shiftApi.endpoints.createTravelEvent.matchFulfilled, (state, action) => {
        travelEventAdapter.addOne(state, action.payload);
      })
      .addMatcher(shiftApi.endpoints.updateTravelEvent.matchFulfilled, (state, action) => {
        travelEventAdapter.upsertOne(state, action.payload);
      });
  },
});

export { travelEventSlice };

export const { selectById: selectTravelEventById, selectAll: selectAllTravelEvents } =
  travelEventAdapter.getSelectors<RootState>((state) => state.travelEvent);
