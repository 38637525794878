import { addMinutes, addSeconds, differenceInMinutes } from "date-fns";
import React from "react";

type Props = {
  seconds: number;
  showZeroHours?: boolean;
};

export const Duration = React.forwardRef<HTMLSpanElement, Props>((props: Props, ref) => {
  let { seconds, showZeroHours = true } = props;
  let date = new Date(0);
  let zero = addMinutes(date, date.getTimezoneOffset());
  let duration = addSeconds(zero, seconds);
  let total_minutes = differenceInMinutes(duration, zero);
  let hours = Math.floor(total_minutes / 60);
  let minutes = total_minutes % 60;
  return (
    <span ref={ref} title={(total_minutes / 60).toFixed(2)}>
      {showZeroHours && <>{hours}h </>}
      {!showZeroHours && hours > 0 && <>{hours}h </>}
      {minutes.toLocaleString("en-AU", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}
      m
    </span>
  );
});

Duration.displayName = "Duration";

export default Duration;
