import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { fetchRRUsers } from "./rrUsersAPI";
import { RootState } from "../../store";

const rrUsersAdapter = createEntityAdapter<RRUser>();

const initialState = rrUsersAdapter.getInitialState();

const rrUsersSlice = createSlice({
  name: "rrUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRRUsers.fulfilled, (state, action) => {
      rrUsersAdapter.setAll(state, action.payload.rr_users);
    });
  },
});

export const { selectById: selectRrUserById, selectAll: selectAllRrUsers } = rrUsersAdapter.getSelectors<RootState>(
  (state) => state.rrUser,
);

export { rrUsersAdapter, rrUsersSlice };
