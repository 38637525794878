import React from "react";

import { SelectDropDown } from "../components/selectDropDown";

type Props = {
  onChangeEarningsType: any;
  earningsRate: string;
  showLabel?: boolean;
};

type EarningType = {
  value: string;
  label: string;
};

export const EARNING_TYPES = [
  {
    value: "Ordinary Hours",
    label: "Ordinary Hours",
  },
  {
    value: "Vocational Training",
    label: "Vocational Training",
  },
] as const satisfies EarningType[];

let EarningTypes = (props: Props) => {
  const { onChangeEarningsType, earningsRate, showLabel = true } = props;
  return (
    <div className="flex flex-col mb-3">
      <SelectDropDown showLabel={showLabel ? "Work Type" : null} value={earningsRate} onChange={onChangeEarningsType}>
        {EARNING_TYPES.map((e) => (
          <option key={e.value} value={e.value}>
            {e.label}
          </option>
        ))}
      </SelectDropDown>
    </div>
  );
};

export default EarningTypes;
