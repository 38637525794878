import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { SummaryShiftRow } from "./summary_shift_row";

export type ShiftsForApprovalType = [WorkedShift, string];

type Props = {
  shiftsForApproval: ShiftsForApprovalType[];
};

export function ShiftsForApproval(props: Props) {
  const { shiftsForApproval } = props;

  return (
    <table className="table">
      <thead>
        <tr>
          <th rowSpan={1} colSpan={4} className="table-header-name">
            <FontAwesomeIcon icon="exclamation-circle" /> Errors and Warnings
          </th>
        </tr>
        <tr>
          <th className="table-header-column-name">Employee</th>
          <th className="table-header-column-name">Start</th>
          <th className="table-header-column-name">End</th>
          <th className="table-header-column-name">Reason</th>
        </tr>
      </thead>
      <tbody>
        {shiftsForApproval.map(([shift, why], i) => {
          return <SummaryShiftRow key={i} shift={shift} approveWhy={why} />;
        })}
      </tbody>
    </table>
  );
}
