type State = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA";
type Gender = "N" | "M" | "F" | "I";

export type StateOption = {
  value: State;
  label: string;
};

export type GenderOption = {
  value: Gender;
  label: string;
};

export const stateOptions: StateOption[] = [
  { value: "SA", label: "South Australia" },
  { value: "ACT", label: "Australian Capital Territory" },
  { value: "NSW", label: "New South Wales" },
  { value: "NT", label: "Northern Territory" },
  { value: "QLD", label: "Queensland" },
  { value: "TAS", label: "Tasmania" },
  { value: "VIC", label: "Victoria" },
  { value: "WA", label: "Western Australia" },
] as const satisfies StateOption[];

export const genderOptions: GenderOption[] = [
  { value: "N", label: "Not Stated" },
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "I", label: "Non-Binary" },
] as const satisfies GenderOption[];
