import React from "react";
import { useAppSelector } from "store/hooks";

import { selectEmployeeById } from "../store/reducers/employee";

type EmployeeNameProps = {
  employeeId: number;
};

const EmployeeName: React.FC<EmployeeNameProps> = ({ employeeId }) => {
  const employee = useAppSelector((state) => selectEmployeeById(state, employeeId));

  if (!employee) {
    return <span>Loading...</span>;
  }

  return <span>{employee.name}</span>;
};

export default EmployeeName;
