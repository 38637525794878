import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { formatISO } from "date-fns";

type OrganisationResponse = {
  organisations: Organisation[];
};

const organisationApi = createApi({
  reducerPath: "organisationApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["Organisation"],
  endpoints: (builder) => ({
    fetchOrganisations: builder.query<OrganisationResponse, void>({
      query: () => "/api/organisation",
      providesTags: (result) =>
        result
          ? [
              ...result.organisations.map(({ xero_tenantId }) => ({
                type: "Organisation" as const,
                xero_tenantId,
              })),
              { type: "Organisation", id: "LIST" },
            ]
          : [{ type: "Organisation", id: "LIST" }],
    }),

    publishShifts: builder.mutation<
      string,
      {
        organisation: Organisation;
        startDate: Date;
        endDate: Date;
        employee_ids?: number[];
      }
    >({
      query: ({ organisation, startDate, endDate, employee_ids }) => ({
        url: "/api/publish_worked_shifts",
        method: "POST",
        body: {
          xero_tenantId: organisation.xero_tenantId,
          start: formatISO(startDate, { representation: "date" }),
          end: formatISO(endDate, { representation: "date" }),
          employee_ids,
        },
        responseHandler: (response) => response.text(),
      }),
    }),

    createOrganisation: builder.mutation<Organisation, Organisation>({
      query: (organisation) => ({
        url: "/api/organisation",
        method: "POST",
        body: organisation,
      }),
      invalidatesTags: [{ type: "Organisation", id: "LIST" }],
    }),
  }),
});

export { organisationApi };

export const { useFetchOrganisationsQuery, usePublishShiftsMutation, useCreateOrganisationMutation } = organisationApi;

export default organisationApi;
