import { FontAwesomeIconProps, FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

import { ColourType, Button } from "./button";

function getTextColourClasses(colour: ColourType, disabled: boolean) {
  // don't refactor "text" and "bg" to variables because purgecss won't work
  return {
    "text-accent hover:text-accent-dark": colour === "accent" && !disabled,
    "text-primary hover:text-primary-dark": colour === "primary" && !disabled,
    "text-success hover:text-success-dark": colour === "success" && !disabled,
    "text-danger hover:text-danger-dark": colour === "danger" && !disabled,
    "text-accent-light cursor-not-allowed": colour === "accent" && disabled,
    "text-primary-light cursor-not-allowed": colour === "primary" && disabled,
    "text-success-light cursor-not-allowed": colour === "success" && disabled,
    "text-danger-light cursor-not-allowed": colour === "danger" && disabled,
  };
}

type Props = {
  colour?: ColourType;
  icon: FontAwesomeIconProps["icon"];
} & React.ComponentProps<typeof Button>;

export const IconButton = React.forwardRef<typeof Button, Props>((props, ref) => {
  let { colour = "primary", icon, className, ...otherProps } = props;
  return (
    <Button
      size="icon"
      colour="none"
      textColour={getTextColourClasses(colour, otherProps.disabled ?? false)}
      {...otherProps}
      ref={ref as any}
      className={classNames("cursor-pointer transition duration-150", className)}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
});

IconButton.displayName = "IconButton";
