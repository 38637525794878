import classNames from "classnames";
import EmployeeName from "components/EmployeeName";
import { format } from "date-fns";
import React from "react";

type Props = {
  rostered_shifts: RosteredShift[];
  showEmployeeName?: boolean;
};

/**
 * Dump a list of rostered shifts in a table, for viewing.
 */
export function RosterDump(props: Props) {
  const { rostered_shifts, showEmployeeName = false } = props;
  return (
    <table className="table">
      <thead>
        <tr>
          {showEmployeeName && <th className="table-header-column-name">Employee</th>}
          <th className="table-header-column-name">Date</th>
          <th className="table-header-column-name">Start</th>
          <th className="table-header-column-name">End</th>
          <th className="table-header-column-name">Location</th>
          <th className="table-header-column-name">Department</th>
        </tr>
      </thead>
      <tbody>
        {rostered_shifts.map((r, i) => (
          <tr
            key={i}
            className={classNames({
              "line-through text-danger": r.archived,
            })}
          >
            {showEmployeeName && (
              <td className="table-body-cell">
                <EmployeeName employeeId={r.employee_id} />
              </td>
            )}
            <td className="table-body-cell">{format(new Date(r.start), "EEE dd/MM/yyyy")}</td>
            <td className="table-body-cell">{format(new Date(r.start), "h:mm a")}</td>
            <td className="table-body-cell">{format(new Date(r.end), "h:mm a")}</td>
            <td className="table-body-cell">{r.location.name}</td>
            <td className="table-body-cell">{r.department.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
