import React from "react";

export function NotFound() {
  return (
    <div className="flex bg-background">
      <div className="mx-auto mt-10">
        <h1 className="text-6xl font-medium">Not found</h1>
        <p className="my-2 text-primary">We can&apos;t find the page you were looking for.</p>
      </div>
    </div>
  );
}
