import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { locationApi } from "./locationAPI";
import { RootState } from "../../store";

const locationAdapter = createEntityAdapter<LocationSchema>();

const initialState = locationAdapter.getInitialState();

const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(locationApi.endpoints.fetchLocations.matchFulfilled, (state, action) => {
      locationAdapter.setAll(state, action.payload);
    });
  },
});

export const { selectById: selectLocationById, selectAll: selectAllLocations } =
  locationAdapter.getSelectors<RootState>((state) => state.location);

// TODO: replace selectAllLocations with this selector in: RosterNewShiftModal, ClockLocation, ShiftRow,
// TravelEventsModal. For now it just sorts the deleted locations to the end of the list
export const selectAllActiveLocations = createSelector(selectAllLocations, (locations) =>
  locations.filter((location) => !location.deleted),
);

export { locationAdapter, locationSlice };
