import { format } from "date-fns";
import React from "react";

type Props = {
  extraTitle?: string;
  time: Date;
};

const Time = (props: Props) => {
  let { extraTitle, time } = props;
  let title = format(time, "EEE, dd MMM yyyy");
  if (extraTitle) {
    title = `${extraTitle}\n${title}`;
  }
  return <div title={title}>{format(time, "h:mm a")}</div>;
};

export default Time;
