import classNames from "classnames";
import React from "react";

type Props = React.ComponentPropsWithoutRef<"textarea">;

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  let { ...otherProps } = props;
  let className =
    "appearance-none block w-full bg-grey-lighter text-grey-darker border border-primary-light rounded py-3 px-4 focus:outline-none focus:ring";

  return <textarea {...otherProps} className={classNames(props.className, className)} ref={ref} />;
});

TextArea.displayName = "TextArea";
