import { isSameDay } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import { RosteredShiftGroup, selectRosteredShiftEntities } from "store/reducers";

import { RosterShiftCell } from "./roster-shift-cell";
import { OpenNewShiftModalFunction } from "./RosterComponent";
import { useRosterParams } from "./useRosterParams";

type RosterShiftDayProps = {
  date: Date;
  rosteredShiftIds: number[];
  employee: Employee;
  openNewShiftModal: OpenNewShiftModalFunction;
  showArchivedShifts: boolean;
  group: RosteredShiftGroup;
};

export const RosterShiftDay: React.FC<RosterShiftDayProps> = ({
  date,
  rosteredShiftIds,
  employee,
  openNewShiftModal,
  showArchivedShifts,
  group,
}) => {
  const rosteredShiftEntities = useAppSelector(selectRosteredShiftEntities);
  const [{ g: groupBy }] = useRosterParams();

  // TODO(store): `group` should have the rosteredShiftIds rather than filtering on the client
  const rosteredShifts = rosteredShiftIds
    .map((id) => rosteredShiftEntities[id])
    .filter((rs): rs is RosteredShift => rs !== undefined)
    .filter((rs) => {
      if (groupBy === "location") {
        return rs.location.id === group.id;
      }
      if (groupBy === "department") {
        return rs.department.id === group.id;
      }
      return true;
    })
    .filter((rs) => {
      return (showArchivedShifts || !rs.archived) && isSameDay(new Date(rs.start), date);
    });

  return (
    <RosterShiftCell
      rostered_shift={rosteredShifts}
      openNewShiftModal={openNewShiftModal}
      date={date}
      employee={employee}
    />
  );
};
