import { isValid, parse } from "date-fns";

let formats = [
  "h:mm a",
  "h:mma",
  "h mm a",
  "h mma",
  "hmm a",
  "hmma",
  "h a",
  "ha",
  "H:mm",
  "h:mm",
  "H mm",
  "h mm",
  "Hmm",
  "H",
  "hmm",
];

/**
 * Parse user submitted time in many formats
 */
export function parseTime(value: string, base: Date) {
  value = value.trim().replace(/\s\s+/g, " ").replace(".", ":"); // replace multiple spaces with a single space
  for (let f of formats) {
    let parsed = parse(value, f, base);
    if (isValid(parsed)) {
      return parsed;
    }
  }
  return undefined;
}
