import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFetchXeroEmployeeQuery } from "store/reducers";

type Props = {
  employee: Employee;
};

export function LeaveBalances(props: Props) {
  const { employee } = props;

  const { data: employeeData } = useFetchXeroEmployeeQuery(employee.id);

  if (!employeeData) {
    return null;
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th rowSpan={1} colSpan={employeeData.leave_balances.length + 1} className="table-header-name">
            <FontAwesomeIcon icon="hourglass-start" /> Leave Balances
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {employee && <span className="whitespace-nowrap"> ({employee.name})</span>}
          </th>
        </tr>
        <tr>
          <th className="table-header-column-name">Name</th>
          {employeeData.leave_balances.map((leave) => (
            <th key={leave.leave_type_id} className="table-header-column-name">
              {leave.leave_name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="table-body-cell">
            <p className="whitespace-nowrap">{employee.name}</p>
          </td>
          {employeeData.leave_balances.map((leave) => (
            <td key={leave.leave_type_id} className="table-body-cell">
              <p className="whitespace-nowrap">
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {leave ? leave.number_of_units : null}{" "}
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {leave ? leave.type_of_units : ""}
              </p>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
