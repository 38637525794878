import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const approveWhyToMessage = {
  "unended shift": "Shift is still in progress",
  "unended break": "Shift still has a break in progress",
  "overnight shift": "Shift spans different days. Forgot to clock off?",
  "manually created": "Shift was manually created (not created by attendance)",
  "unresolved shift_notes": "This shift has new note(s)",
};

export type TakenBreakObj = {
  taken_break_id: number;
  start: string | null;
  end: string | null;
  duration: number;
};

export type ShiftObj = {
  worked_shift_id: string;

  start: string | null;
  end: string | null;
  taken_breaks: TakenBreakObj[];
  total_break_duration: number;
  total_duration: number;
  total_worked_duration: number;
};

export type WeekDay = {
  day: number;
  date: string;
  shifts: ShiftObj[];
  rostered_shift_ids: number[];
  paid_ordinary_duration: number;
  paid_vocational_duration: number;

  auto_break: number;
  travel_count: number;
  total_break_duration: number;
  total_worked_ordinary_duration: number;
  total_worked_vocational_duration: number;
  travel_event_ids: number[];
};

export type Summary = {
  toil: number;
  toil_accrual: number;
  travel: number;
  worked: {
    total: number;
    ordinary: number;
    vocational: number;
    saturday: number;
  };
};

export type FetchShiftsResponse = {
  to_be_approved: {
    [k: string]: (keyof typeof approveWhyToMessage)[];
  };
  week_days: WeekDay[];
  summary: Summary;

  worked_shifts: WorkedShift[];
  taken_breaks: TakenBreak[];
  rostered_shifts: RosteredShift[];
  travel_events: TravelEvent[];
};

type FetchShiftsBody = {
  employee_id: number;
  start_date: string;
};

export type CreateWorkedShiftBody = {
  id?: string; // uuid on the client side or server can generate one
  employee_id: number;
  start: string;
  end: string;
  location_id?: number | null;
  xero_EarningsRateName?: string | null;
  break_duration?: number | null; // in seconds
};

export type UpdateWorkedShiftBody = Partial<Omit<CreateWorkedShiftBody, "id">> & {
  worked_shift_id: WorkedShift["id"];
  approved?: boolean;
};

type CreateShiftNoteBody = Pick<ShiftNote, "worked_shift_id" | "text"> & Partial<Pick<ShiftNote, "resolved">>;

type UpdateShiftNoteBody = Partial<Pick<ShiftNote, "text" | "resolved">>;

type CreateTravelEventBody = Pick<TravelEvent, "employee_id" | "travel_to_location_id" | "travel_start_time">;

const shiftApi = createApi({
  reducerPath: "shiftApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["WorkedShift"],
  endpoints: (builder) => ({
    fetchWorkedShift: builder.query<FetchShiftsResponse, FetchShiftsBody>({
      query: (body) => ({
        url: "/api/fetch_worked_shift",
        method: "POST",
        body,
      }),
      providesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),

    createWorkedShift: builder.mutation<WorkedShift, CreateWorkedShiftBody>({
      query: (body) => ({
        url: "/api/worked_shift",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),

    updateWorkedShift: builder.mutation<WorkedShift, UpdateWorkedShiftBody>({
      query: (body) => ({
        url: "/api/worked_shift",
        method: "PUT",
        body,
      }),
      invalidatesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),

    deleteWorkedShift: builder.mutation<WorkedShift, { worked_shift_id: WorkedShift["id"]; are_you_sure: boolean }>({
      query: (body) => ({
        url: "/api/worked_shift",
        method: "DELETE",
        body,
      }),
      invalidatesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),

    // Shift Notes
    createShiftNote: builder.mutation<ShiftNote, CreateShiftNoteBody>({
      query: (body) => ({
        url: "/api/shift_note",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),

    updateShiftNote: builder.mutation<
      ShiftNote,
      {
        shiftNoteId: ShiftNote["id"];
        body: UpdateShiftNoteBody;
      }
    >({
      query: ({ shiftNoteId, body }) => ({
        url: `/api/shift_note/${shiftNoteId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),

    // Travel Events
    createTravelEvent: builder.mutation<TravelEvent, CreateTravelEventBody>({
      query: (body) => ({
        url: "/api/travel_event",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),

    updateTravelEvent: builder.mutation<
      TravelEvent,
      {
        travelEventId: TravelEvent["id"];
      }
    >({
      query: ({ travelEventId }) => ({
        url: `/api/travel_event`,
        method: "PUT",
        body: {
          travel_event_id: travelEventId,
          // TODO: add other fields that can be updated
        },
      }),
      invalidatesTags: [
        {
          type: "WorkedShift",
          id: "LIST",
        },
      ],
    }),
  }),
});

export { shiftApi };
export const {
  useFetchWorkedShiftQuery,
  useCreateWorkedShiftMutation,
  useUpdateWorkedShiftMutation,
  useDeleteWorkedShiftMutation,

  useCreateShiftNoteMutation,
  useUpdateShiftNoteMutation,

  useCreateTravelEventMutation,
  useUpdateTravelEventMutation,
} = shiftApi;
