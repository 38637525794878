import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import {
  createLeaveApplication,
  fetchLeaveApplications,
  updateLeaveApplication,
} from "../leaveApplication/leaveApplicationAPI";
import { rosterApi } from "../rosteredShift";

const adapter = createEntityAdapter<LeavePeriod>();

const initialState = adapter.getInitialState();

const leavePeriodSlice = createSlice({
  name: "leavePeriods",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaveApplications.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.leave_periods);
      })
      .addCase(createLeaveApplication.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.leave_periods);
      })
      .addCase(updateLeaveApplication.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.leave_periods);
      });

    builder.addMatcher(rosterApi.endpoints.fetchAll.matchFulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.leave_periods);
    });
  },
});

export const { selectById: selectLeavePeriodById, selectEntities: selectLeavePeriodEntities } = adapter.getSelectors(
  (state: RootState) => state.leavePeriod,
);
export { leavePeriodSlice };
