import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { rosterApi } from "./rosterApi";
import { RootState } from "../../store";
import { shiftApi } from "../shift/shiftApi";

const adapter = createEntityAdapter<RosteredShift>();
const initialState = adapter.getInitialState();

const rosteredShiftSlice = createSlice({
  name: "rosteredShifts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(rosterApi.endpoints.fetchAll.matchFulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.rostered_shifts);
      })
      .addMatcher(rosterApi.endpoints.createRosteredShift.matchFulfilled, (state, action) => {
        adapter.addOne(state, action.payload);
      })
      .addMatcher(rosterApi.endpoints.prefillRow.matchFulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.roster_shifts);
      })
      .addMatcher(rosterApi.endpoints.updateRosteredShift.matchFulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload);
      })
      .addMatcher(rosterApi.endpoints.deleteRosteredShift.matchFulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload);
      })
      .addMatcher(rosterApi.endpoints.deleteMultipleRosteredShifts.matchFulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.rostered_shifts);
      })

      .addMatcher(shiftApi.endpoints.fetchWorkedShift.matchFulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.rostered_shifts);
      });
  },
});

export const rosteredShiftAdapter = adapter;
export { rosteredShiftSlice };
export const {
  selectById: selectRosteredShiftById,
  selectAll: selectAllRosteredShifts,
  selectEntities: selectRosteredShiftEntities,
} = adapter.getSelectors((state: RootState) => state.rosteredShift);

export const selectRosterByEmployeeId = createSelector(
  [selectAllRosteredShifts, (_, employeeId: number) => employeeId],
  (rosteredShifts, employeeId) => rosteredShifts.filter((shift) => shift.employee_id === employeeId),
);
