import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import { useCreateShiftNoteMutation, useUpdateShiftNoteMutation } from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";

import { Button } from "../components/button";
import { Modal } from "../components/modal";
import { TextArea } from "../components/textarea";

type Props = {
  workedShift: WorkedShift;
  notesModalIsOpen: boolean;
  closeNotesModal: any;
};

const NotesModal = (props: Props) => {
  const { workedShift, notesModalIsOpen, closeNotesModal } = props;
  const [newNotes, setNewNotes] = React.useState("");
  const isManager = useAppSelector(selectIsManager);
  const [updateShiftNote] = useUpdateShiftNoteMutation();
  const [createShiftNote] = useCreateShiftNoteMutation();

  async function submitNotes() {
    await createShiftNote({
      worked_shift_id: workedShift.id,
      resolved: false,
      text: newNotes,
    });
    setNewNotes("");
  }

  async function approveNote(note: ShiftNote) {
    await updateShiftNote({
      shiftNoteId: note.id,
      body: {
        resolved: true,
      },
    });
  }

  return (
    <Modal isOpen={notesModalIsOpen} dismissModal={closeNotesModal} title="Worked Shift Notes">
      <div className="w-full rounded-lg bg-white">
        <div className="mb-4 flex flex-row">
          <div className="mr-4">
            <span className="block uppercase tracking-wide text-grey-darker text-xs font-bold">Shift Start:</span>
            {workedShift.start && <span>{format(new Date(workedShift.start), "h:mm a EEE dd MMM yyyy")}</span>}
          </div>
          <div>
            <span className="block uppercase tracking-wide text-grey-darker text-xs font-bold">Shift End:</span>
            {workedShift.end && <span>{format(new Date(workedShift.end), "h:mm a EEE dd MMM yyyy")}</span>}
          </div>
        </div>
        {workedShift.shift_notes.length > 0 && (
          <div className="mb-4">
            <span className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Notes:</span>
            <div>
              {workedShift.shift_notes.map((note) => {
                return (
                  <div key={note.id}>
                    {note.employee.name}: {note.text}
                    {!note.resolved && isManager && (
                      <button
                        onClick={() => approveNote(note)}
                        className="bg-success hover:bg-success-dark text-white font-bold px-1 rounded ml-2 focus:outline-none focus:ring"
                        title="Click once this message is actioned"
                      >
                        Resolve <FontAwesomeIcon icon="check" />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="md:flex mb-2">
          <div className="md:w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Add Notes</label>
            <TextArea
              name="Notes"
              placeholder="Message..."
              value={newNotes}
              onChange={(e) => setNewNotes(e.target.value)}
              className="mb-1"
              autoFocus
            />
            <Button colour="accent" onClick={() => submitNotes()} disabled={newNotes == ""}>
              Submit note
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotesModal;
